import { Injectable } from '@angular/core';
import { AuthService } from '@app/features/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  visible: boolean;
  currentToken: any;
  constructor(private authService: AuthService) {
    this.refreshToken();
    this.visible = this.currentToken != null;
  }

  hide() {
    this.refreshToken();
    this.visible = false;
  }

  show() {
    this.refreshToken();
    this.visible = true;
  }

  toggle() {
    this.refreshToken();
    this.visible = !this.visible;
  }

  refreshToken() {
    this.currentToken = this.authService.getCurrentAccessToken();
  }

  logout() {
    this.authService.logout();
    this.hide();
  }
}
