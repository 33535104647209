import { Component, Input, NgZone, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: 'emio-doc-modal',
  templateUrl:'./emio-doc-modal.html',
  styleUrls: ['./emio-doc-modal.scss'],

})
export class DocModalComponent implements OnInit {
  @Input() link : string = 'undefined';
  private animationsMap: Map<string, AnimationItem> = new Map();


  options: AnimationOptions = {
    path: '/assets/animations/empty.json',
  };
  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {}
}
