import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 |  exponentialStrength:10}}
 *   formats to: 1024
 */
@Pipe({ name: 'timelineStatus' })
export class TimelineStatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'TIMELINE_CREATED':
        return 'Proceso iniciado';
      case 'CLIENT_PROSPECTED':
        return 'Cliente prospectado';
      case 'MEETING_CREATED':
        return 'Reunión agendada';
      case 'MEETING_ACCEPTED':
        return 'Reunión aceptada';
      case 'VISIT_SCHEDULED':
        return 'Visita a proyecto agendada';
      case 'VISIT_ACCEPTED':
        return 'Visita a proyecto aceptada';
      case 'SEARCH_PROJECT':
        return 'En búsqueda de proyecto';
      case 'PROJECT_SUGGESTED':
        return 'Proyecto sugerido';
      case 'PROJECT_VIEWED':
        return 'Proyecto visto';
      case 'MODEL_SELECTED':
        return 'Modelo seleccionado';
      case 'UNIT_SELECTED':
        return 'Unidad seleccionada';
      case 'UNIT_LOCKED':
        return 'Unidad bloqueada';
      case 'VALID_TO_BUY':
        return 'Válido para comprar';
      case 'RESERVATION_CREATED':
        return 'Reserva creada';
      case 'PAYMENT_LINK_SENDED':
        return 'Link de pago enviado';
      case 'RESERVATION_PAYMENT':
        return 'Reserva pagada';
      case 'SIGN_OFFER_SENDED':
        return 'Firma de oferta enviada';
      case 'SIGN_PROMISE_SENDED':
        return 'Firma de promesa enviada';
      case 'SIGN_DEED_SENDED':
        return 'Firma de escritura enviada';
      case 'SIGNED_OFFER':
        return 'Oferta de compra venta firmada';
      case 'SIGNED_PROMISE':
        return 'Promesa firmada';
      case 'SIGNED_DEED':
        return 'Escritura firmada';
      case 'SIGNED_CONSERV':
        return 'Escritura inscrita en el conservador';
      case 'CANCELLED':
        return 'Proceso cancelado';
      case 'ENDED':
        return 'Proceso finalizado';
      case 'LEAVED':
        return 'Proceso abandonado';
      case 'WAITING_CLIENT_DOCUMENTATION':
        return 'Esperando documentación del cliente';
      case 'WAITING_REAL_ESTATE_DOCUMENTATION':
        return 'Esperando documentación de la inmobiliaria';
      case 'WAITING_PRE_APPROVAL':
        return 'Esperando pre-aprobación bancaria';
      default:
        return 'Proceso iniciado';
    }
  }
}
