import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '@app/core/services/notifications.service';
import { AccessToken, ActivateUserInput, CreatePasswordGQL, LoginGQL, User } from '@gql/graphql';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { environment } from 'projects/emio-latest/src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { UserLogin } from './models/user-login';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userRole?: string;

  private accessTokenSubject = new BehaviorSubject<any>({});
  public accessToken: Observable<AccessToken> = this.accessTokenSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  constructor(
    private router: Router,
    private loginGQL: LoginGQL,
    private createPasswordGQL: CreatePasswordGQL,
    private localStorage: LocalStorageService
  ) {}

  login(payload: UserLogin) {
    return this.loginGQL.mutate({ payload }).pipe(
      map(result => {
        const accessToken = result.data?.login as AccessToken;
        this.accessTokenSubject.next(accessToken);
        this.loggedIn.next(true);
        this.localStorage.setCurrentAccessToken(accessToken);
        return accessToken;
      })
    );
  }

  createPassword(payload: ActivateUserInput) {
    return this.createPasswordGQL
      .mutate({ payload })
      .pipe(map(result => result.data?.createPassword as User));
  }

  logout() {
    this.loggedIn.next(false);
    this.localStorage.clearAccessToken();
    this.router.navigate(['/auth/login']);
  }

  getCurrentAccessToken() {
    return this.localStorage.getCurrentAccessToken();
  }

  getRoles() {
    return this.getCurrentAccessToken().user.roles;
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }
}
