import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SideNavigationService } from '../../../services/side-navigation.service';

@Component({
  selector: 'emio-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit {
  constructor(public sideNavigationService: SideNavigationService) {}

  ngOnInit(): void {}
}
