import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unitOrientationPipe',
})
export class UnitOrientationPipe implements PipeTransform {
  public transform(value: any) {
    switch (value) {
      case 'N':
        return 'Norte';
      case 'NO':
        return 'Noroeste';
      case 'S':
        return 'Sur';
      case 'E':
        return 'Este';
      case 'O':
        return 'Oeste';
      default:
        return '';
    }
  }
}
