<nav
  *ngIf="sideNavigationService.showSidebar | async"
  class="flex w-64 h-full bg-slate-800 shadow-xl transform-gpu transition-all ease-out duration-300"
  [ngClass]="{
    'w-64': !(sideNavigationService.isCollapsed | async),
    'w-32': (sideNavigationService.isCollapsed | async)
  }"
>
  <ul class="flex flex-col w-full">
    <li class="flex items-center justify-center h-24 py-2 mx-2">
      <img class="w-16" src="/assets/logo.svg" alt="" />
    </li>
    <emio-side-navigation-section
      *ngFor="let section of sideNavigationService.getMenu()"
      [section]="section"
    >
      <emio-side-navigation-item
        *ngFor="let item of section.items"
        [item]="item"
      ></emio-side-navigation-item>
    </emio-side-navigation-section>
  </ul>
  <div
    class="absolute bottom-0 flex items-center justify-center h-24"
    [ngClass]="{
      'w-64': !(sideNavigationService.isCollapsed | async),
      'w-32': (sideNavigationService.isCollapsed | async)
    }"
  >
    <emio-button
    [icon]="(sideNavigationService.isCollapsed | async) ? 'arrow-right': 'arrow-left'"
    color="white"
    (click)="sideNavigationService.toggleCollapse()"
    type="header"
    style="color:white;"
      ><span
        class="ml-3 transform-gpu transition-all ease-out duration-300"
        [ngClass]="{
          'opacity-0 hidden': (sideNavigationService.isCollapsed | async),
          'opacity-100': !(sideNavigationService.isCollapsed | async)
        }"
        ></span
      ></emio-button
    >
  </div>
</nav>
