import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unitTypePipe',
})
export class UnitTypePipe implements PipeTransform {
  public transform(value: any) {
    switch (value) {
      case 'APARTMENT':
        return 'Departamento';
      case 'PARKING':
        return 'Estacionamiento';
      case 'WAREHOUSE':
        return 'Bodega';
      default:
        return '';
    }
  }
}
