import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlyNamePipe',
})
export class OnlyNamePipe implements PipeTransform {
  public transform(value: string) {
    return value.split(' ')[0];
  }
}
