import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'emio-map-search-address',
  templateUrl: './map-search-address.component.html',
  styleUrls: ['./map-search-address.component.scss'],
})
export class MapSearchAddressComponent implements OnInit {
  title: string = 'AGM project';
  latitude = 51.678418;
  longitude = 7.809007;
  zoom = 15;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };

  constructor() {}

  ngOnInit(): void {
    this.setCurrentLocation();
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    }
  }
}
