import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '@app/features/auth/services/auth.service';
import { SideNavigationService } from '../../../services/side-navigation.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'emio-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent implements OnInit {

  constructor(
    public sideNavigationService: SideNavigationService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  logout() {
    this.authService.logout();
  }
}
