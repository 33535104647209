import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { StepsService } from '@services/project-wizard/steps.service';
import { ProgressHelperService } from './progress-helper.service';
import { ProgressStepComponent } from './progress-step/progress-step.component';
import { Status, UiHelper } from './uiHelper';

@Component({
  selector: 'emio-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent extends UiHelper implements OnInit, AfterContentInit {
  itemLength!: number;

  /*   @Input() public set selectedIndex(value) {
    this.activeIndex = value || 0;
  } */

  @Output() public stateChange = new EventEmitter<{
    activeIndex: number;
    activeStep: ProgressStepComponent;
  }>();

  @ContentChildren(ProgressStepComponent) public steps!: QueryList<ProgressStepComponent>;

  constructor(protected progressHelper: ProgressHelperService, private stepsService: StepsService) {
    super(progressHelper);
  }

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.initProgress(this.progressSteps);
    this.stepsService.indexChanged$.subscribe(index => {
      this.activeIndex = index;
      this.switchStatusNext(index);
      this.switchStatusPrev(index);
      this.setActiveActiveStep(index);
      this.emitStateChange();
    });
  }

  public next() {
    this.increaseStep();
  }

  public prev() {
    this.decreaseStep();
  }

  private increaseStep() {
    if (
      this.activeIndex === this.itemLength - 1 &&
      this.itemProgressList[this.activeIndex].status !== Status.COMPLETED
    ) {
      this.completeLastStep();
    }

    if (this.activeIndex < this.itemLength - 1) {
      this.activeIndex++;
      this.switchStatusNext(this.activeIndex);
      this.setActiveActiveStep(this.activeIndex);
      this.emitStateChange();
    }
  }

  private decreaseStep() {
    if (
      this.activeIndex === this.itemLength - 1 &&
      this.itemProgressList[this.activeIndex].status === Status.COMPLETED
    ) {
      this.undoLastComplete();
    } else {
      if (this.activeIndex > 0) {
        this.activeIndex--;
        this.switchStatusPrev(this.activeIndex);
        this.setActiveActiveStep(this.activeIndex);
        this.emitStateChange();
      }
    }
  }

  private emitStateChange(): void {
    this.stateChange.emit({
      activeIndex: this.activeIndex,
      activeStep: this.activeStep,
    });
  }

  private setActiveActiveStep(index: number): void {
    if (this.stepsExists) {
      this.removeActiveStep();
      this.updateActiveStep(index);
    }
  }

  private updateActiveStep(index) {
    this.progressSteps[index].activeState = index;
  }

  private removeActiveStep() {
    this.progressSteps.map(step => {
      if (step.isActive) {
        step.isActive = false;
      }
    });
  }

  private initStepIndex() {
    this.progressSteps.forEach((step, i) => {
      if (i === 0) {
        step.isActive = true;
      }
      step.stepIndex = i;
    });
  }

  public get activeStep(): ProgressStepComponent {
    return this.progressSteps[this.activeIndex];
  }

  private get stepsExists(): boolean {
    return this.progressSteps && Array.isArray(this.progressSteps);
  }

  private get progressSteps(): ProgressStepComponent[] {
    return this.steps.toArray();
  }

  protected generateProgressArray(items: ProgressStepComponent[]): any[] {
    return items.map(key => {
      return {
        stepIndex: key.stepIndex,
        status: key.stepIndex === this.activeIndex ? Status.IN_PROGRESS : Status.PENDING,
        title: key.stepTitle,
      };
    });
  }

  private initProgress(value: any[]): void {
    this.itemLength = value.length || 0;
    this.itemProgressList = this.generateProgressArray(value);
  }
}
