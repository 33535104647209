import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'emio-drop-zone',
  templateUrl: './drop-zone.component.html',
  styleUrls: ['./drop-zone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropZoneComponent implements OnInit {
  items: File[] = [];

  constructor() {}

  ngOnInit(): void {}

  addFile(event) {
    this.items.push(event.target.files[0]);
  }
}
