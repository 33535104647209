<div>
  <label tuiProgressLabel class="label-wrapper">
    <progress tuiProgressBar [max]="totalGlobalUnits" size="s" class="progress" [value]="totalGlobalUnits"></progress>

    <progress tuiProgressBar [max]="totalGlobalUnits" size="s" class="progress" [value]="brokerUnits"></progress>

    <progress tuiProgressBar [max]="totalGlobalUnits" size="s" class="progress" [value]="soldUnits"></progress>
  </label>
</div>

<div>
  <small>Broker: {{ brokerPercent | number: '1.1-1' }}% /Total: {{ totalPercent | number: '1.1-1' }}%</small>
</div>