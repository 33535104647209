import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import {
  CreateModelGQL,
  CreateModelInput,
  CreateProjectGQL,
  GetModelsGQL,
  GetUnitsGQL,
  GetProjectsGQL,
  Project,
  UnitModel,
  UpdateModelGQL,
  UpdateProjectGQL,
  CreateUnitGQL,
  UpdateUnitGQL,
  Unit,
  ListUnitsInput,
  CreateUnitInput,
  UpdateUnitInput,
  UploadStockGQL,
  GetProjectGQL,
  ProjectStats,
  AddViewerGQL,
  RemoveViewerGQL,
  AddViewerInput,
  RemoveViewerInput,
  ViewerAddedGQL,
  ViewerRemovedGQL,
  User,
  PaymentStatusChangedGQL,
  DeleteUnitModelGQL,
  Payment,
  ProjectChartStats,
  ModelChartStats,
  UnitsByFloorChartStats,
  GetProjectDocumentsGQL,
  UpdateProjectInput,
} from '@gql/graphql';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { DocumentTypes } from '@app/features/timeline/enums/document-type.enum';
import { GetUnitByIdGQL } from '../../../../gql/graphql';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(
    private projectsGQL: GetProjectsGQL,
    private getProjectGQL: GetProjectGQL,
    private getDocumentsGQL: GetProjectDocumentsGQL,
    private createProjectGQL: CreateProjectGQL,
    private updateProjectGQL: UpdateProjectGQL,
    private modelsGQL: GetModelsGQL,
    private createModelGQL: CreateModelGQL,
    private updateModelGQL: UpdateModelGQL,
    private deleteModelGQL: DeleteUnitModelGQL,
    private unitsGQL: GetUnitsGQL,
    private getUnitByIdGQL: GetUnitByIdGQL,
    private createUnitGQL: CreateUnitGQL,
    private updateUnitGQL: UpdateUnitGQL,
    private uploadStockGQL: UploadStockGQL,
    private addViewerGQL: AddViewerGQL,
    private removeViewerGQL: RemoveViewerGQL,
    private viewerAddedGQL: ViewerAddedGQL,
    private viewerRemovedGQL: ViewerRemovedGQL,
    private paymentStatusChangedGQL: PaymentStatusChangedGQL,
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) { }

  getByRealEstateCompany(realEstateId: string): Observable<Project[]> {
    return this.projectsGQL
      .watch({ filters: { company: realEstateId } })
      .valueChanges.pipe(map(current => current.data.projects as Project[]));
  }

  getProjectsForBroker(brokerId: string): Observable<Project[]> {
    return this.projectsGQL
      .watch({ filters: { isPublished: true } })
      .valueChanges.pipe(map(current => current.data.projects as Project[]));
  }

  getProjectById(projectId: string): Observable<Project> {
    return this.getProjectGQL
      .watch({ id: projectId })
      .valueChanges.pipe(map(current => current.data.project as Project));
  }

  getDocumentsFor(projectId: string): Observable<Project> {
    return this.http
      .get(environment.baseUrl + 'api/projects/' + projectId)
      .pipe(map(response => response as Project));
  }

  getProjectStats(projectId?: string): Observable<ProjectStats> {
    return this.http
      .get(environment.baseUrl + 'api/units/stats/' + projectId)
      .pipe(map(response => response[0]));
  }

  createProject(project: any): Observable<Project> {
    return this.createProjectGQL
      .mutate({ project })
      .pipe(map(result => result.data?.createProject as Project));
  }

  updateProject(project: UpdateProjectInput): Observable<Project> {
    //TODO VALIDATORS AND PARSER TO FILES AND DOCUMENTS
    return this.updateProjectGQL
      .mutate({ project })
      .pipe(map(result => result.data?.updateProject as Project));
  }

  publishProject(projectId: string) {
    return this.updateProject({ _id: projectId, isPublished: true });
  }

  updateBrochure(id: string, brochureId: string): Observable<Project> {
    return this.updateProject({ brochure: brochureId, _id: id });
  }

  updateCoverImage(id: string, coverId: string): Observable<Project> {
    return this.updateProject({ cover: coverId, _id: id });
  }

  uploadStock(payload: any): Observable<Unit[]> {
    return this.uploadStockGQL
      .mutate({ payload })
      .pipe(map(result => result.data?.uploadStock as Unit[]));
  }
  getModels(): Observable<UnitModel[]> {
    return this.modelsGQL
      .watch({ filters: {} })
      .valueChanges.pipe(map(current => current.data.unitModels as any[]));
  }

  createModel(model: CreateModelInput): Observable<UnitModel> {
    return this.createModelGQL.mutate({ model }).pipe(
      map(result => {
        this.projectsGQL.watch({ filters: { _id: model.project } }).refetch();
        return result.data?.createUnitModel as UnitModel;
      })
    );
  }

  updateModel(model: any): Observable<UnitModel> {
    return this.updateModelGQL
      .mutate({ model })
      .pipe(map(result => result.data?.updateUnitModel as UnitModel));
  }

  deleteModel(model: any): Observable<UnitModel> {
    return this.deleteModelGQL.mutate({ id: model._id }).pipe(
      map(result => {
        this.projectsGQL.watch({ filters: { _id: model.project } }).refetch();
        return result.data?.deleteUnitModel as UnitModel;
      })
    );
  }

  getUnits(filters: ListUnitsInput): Observable<Unit[]> {
    return this.unitsGQL
      .watch({ filters })
      .valueChanges.pipe(map(current => current.data.units as Unit[]));
  }

  createUnit(unit: CreateUnitInput): Observable<Unit> {
    return this.createUnitGQL.mutate({ unit }).pipe(
      map(result => {
        this.unitsGQL.watch().refetch();
        return result.data?.createUnit as Unit;
      })
    );
  }

  updateUnit(unit: UpdateUnitInput): Observable<Unit> {
    return this.updateUnitGQL.mutate({ unit }).pipe(map(result => result.data?.updateUnit as Unit));
  }

  addViewer(payload: AddViewerInput): Observable<Project> {
    return this.addViewerGQL
      .mutate({ payload })
      .pipe(map(result => result.data?.addViewer as Project));
  }

  removeViewer(payload: RemoveViewerInput): Observable<Project> {
    return this.removeViewerGQL
      .mutate({ payload })
      .pipe(map(result => result.data?.removeViewer as Project));
  }

  getProjectChartStats(projectId: string): Observable<ProjectChartStats[]> {
    return this.http.get<ProjectChartStats[]>(
      `${environment.baseUrl}api/units/stats/project/${projectId}`
    );
  }

  getModelChartStats(projectId: string): Observable<ModelChartStats[]> {
    return this.http.get<ModelChartStats[]>(
      `${environment.baseUrl}api/units/stats/project/${projectId}/model`
    );
  }

  getUnitById(_id: string): Observable<Unit> {
    return this.getUnitByIdGQL
      .watch({ _id })
      .valueChanges.pipe(map(current => current.data.unit as Unit));
  }

  getUnitsByFloorChartStats(
    projectId: string,
    type: string = 'APARTMENT'
  ): Observable<UnitsByFloorChartStats[]> {
    return this.http.get<UnitsByFloorChartStats[]>(
      `${environment.baseUrl}api/units/stats/project/${projectId}/floor/${type}`
    );
  }

  signDocument(documentType: DocumentTypes, timelineId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}api/timelines/sign/${timelineId}/${documentType}`
    );
  }

  pdfDocument(documentType: DocumentTypes, timelineId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}api/timelines/pdf/${timelineId}/${documentType}`
    );
  }

  onViewerAdded(projectId: string): Observable<User[]> {
    return this.viewerAddedGQL
      .subscribe({ projectId })
      .pipe(map(result => result.data?.viewerAdded.currentViewers as User[]));
  }

  onViewerRemoved(projectId: string): Observable<User[]> {
    return this.viewerRemovedGQL
      .subscribe({ projectId })
      .pipe(map(result => result.data?.viewerRemoved.currentViewers as User[]));
  }

  onPaymentStatusChanged(paymentToken: string): Observable<Payment> {
    return this.paymentStatusChangedGQL
      .subscribe({ paymentToken })
      .pipe(map(result => result.data?.paymentStatusChanged as Payment));
  }
}
