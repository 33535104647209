import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AuthService } from './features/auth/services/auth.service';

import { routeAnimations, ROUTE_ANIMATIONS_ELEMENTS } from './core/core.module';
import { Title } from '@angular/platform-browser';
import { filter, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NavigationEvent } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model';
import { Gtag } from 'angular-gtag';
import { SideNavigationService } from './core/services/side-navigation.service';
// tslint:disable-next-line:ban-types
declare let gtag: Function;
@Component({
  selector: 'emio-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations],
})
export class AppComponent implements OnInit {
  expanded: boolean = false;
  showSideNav: boolean = true;
  currentToken = this.authService.getCurrentAccessToken();
  constructor(
    private authService: AuthService,
    public sidebar: SideNavigationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    gtag: Gtag
  ) {}

  ngOnInit() {
    const appTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          gtag('event', 'page_view', {
            page_title: this.titleService.getTitle(),
            page_path: event.urlAfterRedirects,
            send_to: 'G-QDGDSKL3C3',
          });
        }),
        map(event => {
          let child = this.activatedRoute.firstChild;
          this.currentToken = this.authService.getCurrentAccessToken();
          while (child?.firstChild) {
            child = child?.firstChild;
          }
          if (child?.snapshot.data['title']) {
            return child?.snapshot.data['title'];
          }
          return appTitle;
        })
      )
      .subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }
}
