import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  UrlTree,
  Route,
  UrlSegment,
  ActivatedRoute,
} from '@angular/router';
import { Role } from '@app/core/enums/role.enum';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentAccessToken = this.authService.getCurrentAccessToken();

    if (currentAccessToken != null) {
      const userRoles: string[] = this.authService.getRoles();
      const roles = next.data.roles;
      const previousUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

      if (roles && !this.compareRoles(roles, userRoles)) {
        previousUrl == '/'
          ? this.router.navigate(['/auth/login'])
          : this.router.navigate(previousUrl);
        //this.router.navigate(['/auth/login']);
        return false;
      }
      return true;
    } else {
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  compareRoles(roles: string[], userRoles: string[]) {
    return userRoles.every(role => roles.includes(role)) || userRoles.includes(Role.GOD);
  }
}
