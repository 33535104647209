import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './components/button/button.component';
import { PanelComponent } from './components/panel/panel.component';
import { RouterModule } from '@angular/router';
import { SideNavigationComponent } from './components/side-navigation/side-navigation.component';
import { SideNavigationItemComponent } from './components/side-navigation/components/side-navigation-item/side-navigation-item.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SideNavigationSectionComponent } from './components/side-navigation/components/side-navigation-section/side-navigation-section.component';
import { InputComponent } from './components/input/input.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { HeaderComponent } from './components/header/header.component';
import { ImageComponent } from './components/image/image.component';
import { DocumentUploaderComponent } from './components/document-uploader/document-uploader.component';
import { ProgressStepDirective } from './components/progress/progress-step.directive';
import { ProgressStepComponent } from './components/progress/progress-step/progress-step.component';
import { ProgressComponent } from './components/progress/progress.component';
import { AutocompleteMultiselectComponent } from './components/autocomplete-multiselect/autocomplete-multiselect.component';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { EmioProgressBar } from './components/emio-progress-bar/emio-progress-bar.component';
import { TuiProgressModule } from '@taiga-ui/kit';
import { TuiHintModule } from '@taiga-ui/core';

@NgModule({
  declarations: [
    ButtonComponent,
    InputComponent,
    ImageComponent,
    PanelComponent,
    SideNavigationComponent,
    SideNavigationItemComponent,
    SideNavigationSectionComponent,
    NotificationsComponent,
    HeaderComponent,
    DocumentUploaderComponent,
    ProgressComponent,
    ProgressStepComponent,
    ProgressStepDirective,
    AutocompleteMultiselectComponent,
    EmioProgressBar,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    MatIconModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatOptionModule,
    TuiHintModule,
    TuiProgressModule
  ],
  exports: [
    ButtonComponent,
    InputComponent,
    ImageComponent,
    PanelComponent,
    SideNavigationComponent,
    NotificationsComponent,
    HeaderComponent,
    DocumentUploaderComponent,
    ProgressComponent,
    ProgressStepComponent,
    ProgressStepDirective,
    AutocompleteMultiselectComponent,
    EmioProgressBar,
    TuiProgressModule,
  ],
})
export class UiModule {}
