import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clientUFMaxPipe',
})
export class ClientUFMaxPipe implements PipeTransform {
  public transform(value: any) {
    return Math.ceil((Number(value) / 500000) * 1000);
  }
}
