import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { NotificationsService } from '@app/core/services/notifications.service';
import { File as CloudFile } from '@gql/graphql';
import { FileStorageService } from '@services/documents/firebase-storage.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'emio-multiple-images',
  templateUrl: './multiple-images.component.html',
  styleUrls: ['./multiple-images.component.scss'],
})
export class MultipleImagesComponent implements OnInit, OnChanges {
  @Input('files')
  files?: CloudFile[] | null = [];

  @Input('file-limit')
  fileLimit: number = 12;

  @Input('title')
  title: string = 'imagen';

  @Output() filesChange = new EventEmitter<CloudFile[]>();
  fileList: NzUploadFile[] = [];
  previewImage: string | undefined = '';
  previewVisible = false;
  loading = false;

  @Output() onFileUpload = new EventEmitter<NzUploadFile[]>();

  ACTION_URL: string = `https://api.emio.cl/api/files/upload`;
  constructor(
    private uploadService: FileStorageService,
    private ref: ChangeDetectorRef,
    private notifications: NotificationsService
  ) {}

  ngOnInit() {
    if (this.files) {
      this.files.forEach(file => {
        this.fileList.push({
          uid: file._id,
          name: file.url,
          status: 'done',
          url: file.url,
          thumbUrl: file.url,
          response: file,
        });
      });
    }
  }

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    console.log('handling preview', file);
    if (!file.url && !file.preview && !file.response.url) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview || file.response.url;
    this.previewVisible = true;
  };

  ngOnChanges(changes: SimpleChanges): void {
    let files = changes['files'];

    if (!files.firstChange) {
      this.fileList = [];
      this.files?.forEach(file => {
        this.fileList.push({
          uid: file._id,
          name: file.url,
          status: 'done',
          url: file.url,
          thumbUrl: file.url,
          response: file,
        });
      });
    }
  }

  handleChange(info: { file: NzUploadFile; fileList: NzUploadFile[] }): void {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    this.fileList = fileList;
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        // Get this url from response in real world.

        this.notifications.success('Archivo subido correctamente.');
        getBase64(info.file!.originFileObj!).then(img => {
          this.previewImage = img || info.file.response.url;
          this.loading = false;
        });
        break;
      case 'error':
        this.notifications.error('Error en la subida del archivo. Reintente.');
        this.loading = false;
        break;
      case 'removed':
        this.notifications.success('Imagen eliminada con éxito!');
        this.loading = false;
        break;
    }
  }

  handleUpload = (item: any) => {
    const formData = new FormData();
    formData.append(item.name, item.file as any);
    return this.uploadService.uploadFile(formData).subscribe(
      res => {
        item.onSuccess(res);
        console.log('success', res._id);
        this.files?.push(res);
        if (this.files) {
          this.filesChange.emit(this.files);
        }
      },
      err => {
        item.onError(err, item.file);
      }
    );
  };
}
const getBase64 = (file: File): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
