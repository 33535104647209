<!--
    icons for the available, reserved, selected buttons,
    for the legend items
-->
<svg class="hidden" viewBox="0 0 100 100">
  <symbol id="available" viewBox="0 0 100 100">
    <g fill="currentColor">
      <circle cx="50" cy="50" r="50"></circle>
      <circle cx="50" cy="50" r="10" fill="#fff"></circle>
    </g>
  </symbol>
  <symbol id="reserved" viewBox="0 0 100 100">
    <defs>
      <clipPath id="clip">
        <circle cx="50" cy="50" r="50"></circle>
      </clipPath>
    </defs>
    <g fill="currentColor">
      <circle cx="50" cy="50" r="50"></circle>
      <g clip-path="url(#clip)" fill="#000" opacity="0.12">
        <circle cx="50" cy="42" r="15"></circle>
        <circle cx="50" cy="110" r="40"></circle>
      </g>
    </g>
  </symbol>
  <symbol id="not_owned" viewBox="0 0 100 100">
    <defs>
      <clipPath id="clip">
        <circle cx="50" cy="50" r="50"></circle>
      </clipPath>
    </defs>
    <g fill="#990000" opacity="0.3">
      <circle cx="50" cy="50" r="50"></circle>
    </g>
  </symbol>
</svg>

<emio-panel title="Mapa por piso">
  <div [ngClass]="routeAnimationsElements" id="detail" class="space-y-0" #detail>
    <div
      class="group bg-white items-center cursor-pointer m-4 p-4 rounded-xl absolute subpixel-antialiased"
      *ngIf="unitSelected"
    >
      <img
        [src]="unitSelected.image"
        [alt]="unitSelected.name"
        class="w-full p-2 h-56 object-cover group-hover:scale-105 transform-gpu ease-out duration-300 border-slate-200 border rounded-lg shadow-md"
      />

      <div
        class="relative px-3 -mt-12 group-hover:-translate-y-1 transition-all transform-gpu ease-out duration-300"
      >
        <div
          class="bg-white bg-opacity-40 p-6 rounded-lg group-hover:scale-x-105 transition-all transform-gpu ease-out duration-300"
        >
          <h4
            class="my-2 text-xl font-bold capitalize leading-tight truncate border-b border-slate-200"
          >
            {{ unitSelected.name }}
          </h4>
          <div
            class="top-8 -right-4 group-hover:scale-105 group-hover:shadow-xl transition-all transform-gpu ease-out duration-300 shadow-md text-white px-4 py-2 rounded-lg z-50 bg-yellow-500 text-base"
          >
            <fa-icon [icon]="['fas', 'coins']"></fa-icon>
            <strong> UF {{ unitSelected.sellPrice | thousandsPipe }}</strong>
          </div>
          <div class="mt-3 w-60">
            <div class="flex items-baseline text-center">
              <div class="mx-auto my-1 text-slate-600 text-center uppercase text-xs">
                <div class="py-2 space-y-1">
                  <p>
                    área util:<b>{{ getVariant(unitSelected)?.usefulArea }} mt²</b>
                  </p>
                  <p>
                    área terraza:<b>{{ getVariant(unitSelected)?.terraceArea }} mt²</b>
                  </p>
                  <p *ngIf="getVariant(unitSelected)?.gardenArea">
                    área jardin:<b>{{ getVariant(unitSelected)?.gardenArea }} mt²</b>
                  </p>
                  <p>
                    área total:<b>{{ getVariant(unitSelected)?.totalArea }} mt²</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div
              class="d-flex justify-content-between align-items-start mb-2 border-b border-t border-slate-200"
            >
              <div class="p-2">
                <fa-icon [icon]="['fas', 'bed']"></fa-icon>
                <strong> {{ unitSelected.model?.bedrooms }}</strong>
              </div>
              <div class="p-2">
                <fa-icon [icon]="['fas', 'bath']"></fa-icon>
                <strong> {{ unitSelected.model?.bathrooms }}</strong>
              </div>
              <div class="p-2">
                <fa-icon [icon]="['fas', 'compass']"></fa-icon>
                <strong> {{ getVariant(unitSelected)?.orientation }}</strong>
              </div>
            </div>
          </div>
          <div class="mt-2 justify-end">
            <emio-button (click)="hideUnit()">Volver</emio-button>
            <emio-button
              *ngIf="unitSelected._id && unitSelected.available"
              (click)="goDetail(unitSelected._id)"
              >Reservar</emio-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="building">
    <!-- seats included in the script as buttons nesting the svg icons for available/reserved/selected seats -->
    <div
      class="units"
      style="grid-template-columns: repeat(10, 20px)"
      *ngFor="let floor of floors$ | async as floors"
    >
      <span class="floor-number">P{{ floor.floor }}</span>
      <button type="button" *ngFor="let unit of floor.units">
        <svg
          [ngClass]="{ available: unit.available, reserved: !unit.available }"
          width="24"
          height="24"
          (click)="showUnit(unit)"
        >
          <use href="#available" *ngIf="unit.available"></use>
          <use href="#reserved" *ngIf="!unit.available"></use>
          <use href="#not_owned" *ngIf="unit.status === 'NOT_OWNED'"></use>
        </svg>
      </button>
    </div>
    <p class="mt-4">Leyenda</p>
  </div>

  <!-- show the legend items side by side, prefaced by the icon symbolizing them -->
  <div class="legend justify-content-center">
    <p>
      <span class="floor-number" style="width: 20px; margin-right: 5px; color: #fff">PX</span>
      <span>PISO</span>
    </p>
    <p>
      <svg class="available" width="24" height="24">
        <use href="#available"></use>
      </svg>
      <span> DISPONIBLE </span>
    </p>
    <p>
      <svg class="reserved" width="24" height="24">
        <use href="#reserved"></use>
      </svg>
      <span> RESERVADO </span>
    </p>
    <!--
        <p>
          <svg class="selected" width="20" height="20">
            <use href="#selected"></use>
          </svg>
          <span> Selected </span>
        </p> -->
  </div>
</emio-panel>
