import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: `emio-progress-bar`,
  templateUrl: './emio-progress-bar.component.html',
  styleUrls: ['./emio-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmioProgressBar {


  @Input('sold') sold!: number;
  @Input('unsold') unsold!: number;
  @Input('total') total!: number;

  //max: number;
  //soldPercent: number;
  //brokerPercent: number;

  //Total
  //soldBroker
  //soldTotal

  //this.soldPercent = this.soldPercent.toFixed()

  get soldUnits(){
    return this.sold
  }

  get brokerUnits(){
    return this.sold + this.unsold
  }

  get totalGlobalUnits(){
    return this.total;
  }

  get brokerPercent(){
    return (this.sold / (this.total)) * 100;
  }

  get totalPercent(){
    return ((this.total - this.unsold)/(this.total)) * 100
  }


  constructor(){
    //this.max = this.sold + this.unsold + this.total

    //this.brokerPercent = (this.sold + this.unsold)/(this.sold + this.unsold + this.total)

    //this.soldPercent = this.sold / (this.sold + this.unsold + this.total)
  }
 }
