import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sellerMeetingCountdownPipe',
})
export class SellerMeetingCountdownPipe implements PipeTransform {
  public transform(value: any) {

    let timeString = value.daysToDday + ' dia(s), ' + value.minutesToDday + ' minutos y ' + value.secondsToDday;

    switch (value.status) {
      case 'BEFORE_MEETING':

        //return 'Reunión de vendedor con';
        return 'La reunión empezará dentro de:';
      case 'AT_MEETING':
        //return 'Visita con el broker';
        return 'La reunión terminará en:';
      case 'AFTER_MEETING':
        //return 'Visita inmobiliaria';
        return 'La reunión terminó hace:';
      default:
        return 'aaa';
    }
  }
}
