import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentTypePipe',
})
export class PaymentTypePipe implements PipeTransform {
  public transform(value: any) {
    switch (value) {
      case 'VD':
        return 'Venta Débito';
      case 'VN':
        return 'Venta Normal';
      case 'VC':
        return 'Venta en cuotas';
      case 'SI':
        return '3 cuotas sin interés';
      case 'S2':
        return '2 cuotas sin interés';
      case 'NC':
        return 'N Cuotas sin interés';
      case 'VP':
        return 'Venta Prepago';
      default:
        return '';
    }
  }
}
