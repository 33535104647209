import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
  SimpleChanges,
  AfterViewInit,
  OnDestroy,
  OnChanges,
} from '@angular/core';

import * as FunnelGraph from 'funnel-graph-js';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'emio-funnel-panel',
  templateUrl: './funnel-panel.component.html',
  styleUrls: ['./funnel-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelPanelComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
  @Input()
  data: any;

  graph: any;

  resizeObservable$ = new Observable<Event>();

  subscription: Subscription = new Subscription();
  title = 'Funnel de ventas';

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    this.createGraph();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    if (this.graph) {
      this.graph.updateWidth();
    }
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.subscription.add(
      this.resizeObservable$.subscribe(evt => {
        if (this.graph) {
          this.graph.updateWidth();
        }
      })
    );
  }

  createGraph(): void {
    if (!this.graph) {
      this.graph = new FunnelGraph({
        container: '.funnel-component',
        gradientDirection: 'horizontal',
        data: this.data,
        displayPercent: true,
        direction: 'horizontal',
      });
      this.graph.draw();
      this.graph.updateWidth();
    } else {
      this.graph.updateData({ ...this.data });
      this.graph.updateWidth();
    }
  }

  ngAfterViewInit(): void {
    this.createGraph();
  }
}
