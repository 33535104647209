import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { File } from '@gql/graphql';
import { Observable } from 'rxjs';
import { finalize, first, map, mergeMap, take, switchMap } from 'rxjs/operators';
import { DocumentsService } from './documents.service';
import { DocumentStorage } from './models/document.model';
import { FileUpload } from './models/file-upload.model';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root',
})
export class FileStorageService {
  constructor(private documentsService: DocumentsService, private http: HttpClient) {}

  public uploadFile(formData: FormData): Observable<File> {
    return this.http.post<File>(`https://api.emio.cl/api/files/upload`, formData);
  }

  /*   public getFileReference(filename: string) {
    return this.storage.ref(filename);
  } */
}
