import {
  trigger,
  state,
  style,
  transition,
  animate,
  group,
  query,
  stagger,
} from '@angular/animations';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '@app/core/services/notifications.service';
import { AuthService } from '@app/features/auth/services/auth.service';
import { ProjectService } from '@app/features/project/services/project.service';
import { PaymentCartItem, Project, Unit } from '@gql/graphql';
import { UtilService } from '@services/util/util.service';
import { AnimationItem } from 'lottie-web';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AnimationOptions } from 'ngx-lottie';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PaymentService } from '../../services/payment.service';

@Component({
  selector: 'emio-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [
      state('in', style({ transform: '*', opacity: 1 })),
      transition('* <=> void', [
        style({ transform: '*', opacity: 1 }),
        animate('300ms ease', style({ transform: 'scaleY(0%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class ShoppingCartComponent implements OnInit {
  cart$: Observable<PaymentCartItem[]> = this.paymentService.currentCart$;
  isEmpty$: Observable<boolean> = this.cart$.pipe(map(items => !(items.length > 0)));
  subscriptions: Subscription[] = [];
  currentUF: number = 0;

  project!: Project;

  paymentStatus$ = new Observable<any>();
  timelineId?: string;
  private animationsMap: Map<string, AnimationItem> = new Map();

  options: AnimationOptions = {
    path: '/assets/animations/empty.json',
  };

  deleteOptions: AnimationOptions = {
    path: '/assets/animations/delete.json',
  };
  constructor(
    public paymentService: PaymentService,
    public projectService: ProjectService,
    private notifications: NotificationsService,
    public authService: AuthService,
    private utils: UtilService,
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    private ngZone: NgZone,
    private router: Router
  ) {}

  animationCreated(
    name: string,
    animationItem: AnimationItem,
    autoStart: boolean = true,
    loop: boolean = true
  ): void {
    animationItem.autoplay = autoStart;
    animationItem.loop = loop;
    this.animationsMap.set(name, animationItem);
  }

  stop(name: string) {
    this.ngZone.runOutsideAngular(() => {
      this.animationsMap.get(name)?.goToAndStop(this.animationsMap.get(name)!.totalFrames - 1);
    });
  }

  start(name: string) {
    this.ngZone.runOutsideAngular(() => {
      this.animationsMap.get(name)?.play();
    });
  }

  mouseEnter(name: string) {
    const anim = this.animationsMap.get(name);
    if (anim) {
      this.ngZone.runOutsideAngular(() => {
        anim.setDirection(1);
        anim.play();
      });
    }
  }

  mouseLeave(name: string) {
    const anim = this.animationsMap.get(name);
    if (anim) {
      this.ngZone.runOutsideAngular(() => {
        anim.setDirection(-1);
        anim.play();
      });
    }
  }

  ngOnInit() {
    this.subscriptions.push(this.utils.UF().subscribe(ufValue => (this.currentUF = ufValue)));
    let initialState: any = this.modalService.config.initialState;
    this.timelineId = initialState.timelineId;
    this.project = initialState.project;
  }

  getCLP(value: number) {
    return Math.ceil(value * this.currentUF);
  }

  getApartment(item: PaymentCartItem): Unit {
    return item?.units?.filter(unit => unit.type === 'APARTMENT')[0];
  }

  getParking(item: PaymentCartItem): Unit {
    return item?.units?.filter(unit => unit.type === 'PARKING')[0];
  }

  getWarehouse(item: PaymentCartItem): Unit {
    return item?.units?.filter(unit => unit.type === 'WAREHOUSE')[0];
  }

  removeItem(item: PaymentCartItem): void {
    this.paymentService.removeFromCart(item);
  }
  getBonusPrice(item: PaymentCartItem): number {
    const price = this.getApartment(item).sellPrice;
    if (item.paymentBonusTerm && item.paymentBonusTerm.percent) {
      return Math.ceil((price / (100 - item.paymentBonusTerm.percent)) * 100);
    } else {
      return price;
    }
  }

  onPaymentCreated(event) {
    this.notifications.showLoading('esperando a que complete el pago...');
    this.paymentStatus$ = this.projectService.onPaymentStatusChanged(event.token).pipe(
      tap(async paymentStatus => {
        if (paymentStatus.transaction.details[0].status === 'AUTHORIZED') {
          this.paymentService.resetCart();
          this.notifications.hideLoading();
          this.paymentService.hideShoppingCart();
          this.notifications.success(`Pago finalizado con éxito por ${paymentStatus.amount}!`);
          await this.router.navigate(['/timeline/' + this.timelineId + '/detail']);
        }
      })
    );
  }
}
