import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clientCivilStatusPipe',
})
export class ClientCivilStatusPipe implements PipeTransform {
  public transform(value: any) {
    switch (value) {
      case 'SINGLE':
        return 'Soltero';
      case 'MARRIED':
        return 'Casado';
      case 'WIDOWER':
        return 'Viudo';
      case 'DIVORCED':
        return 'Divorciado';
      case 'SEPARATED':
        return 'Separado';
      case 'COHABITING':
        return 'Conviviente';
      default:
        return '';
    }
  }
}
