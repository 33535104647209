import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { TuiDialogModule, TuiRootModule, TUI_SANITIZER } from '@taiga-ui/core';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { SnotifyModule, ToastDefaults, SnotifyService } from 'ng-snotify';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { momentAdapterFactory } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import player from 'lottie-web';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { EditableModule } from '@ngneat/edit-in-place';
import { AuthGuard } from './features/auth/guards/auth.guard';
import { SidenavComponent } from './shared/components/sidenav/sidenav.component';
import { InvoiceComponent } from './shared/components/invoice/invoice.component';
import { AuthService } from './features/auth/services/auth.service';
import { SidenavService } from './shared/components/sidenav/sidenav.service';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { es_ES } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { PaymentService } from './features/payment/services/payment.service';
import { LottieModule } from 'ngx-lottie';
import { TUI_LANGUAGE, TUI_SPANISH_LANGUAGE } from '@taiga-ui/i18n';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'; // a plugin!
import * as $ from 'jquery';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NzIconModule } from 'ng-zorro-antd/icon';
import {
  AccountBookFill,
  AlertFill,
  AlertOutline,
  PlusOutline,
  PictureTwoTone,
  FileTwoTone,
  DownloadOutline,
  DeleteOutline,
} from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';
import { of } from 'rxjs';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { TuiPdfViewerModule } from '@taiga-ui/kit';
const icons: IconDefinition[] = [
  AccountBookFill,
  AlertOutline,
  AlertFill,
  PlusOutline,
  PictureTwoTone,
  FileTwoTone,
  DownloadOutline,
  DeleteOutline,
];
FullCalendarModule.registerPlugins([dayGridPlugin, interactionPlugin, timeGridPlugin]);

registerLocaleData(es);

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}
const socketConfig: SocketIoConfig = {
  url: 'https://api.emio.cl/events',
  options: { transports: ['websocket'] },
};
@NgModule({
  declarations: [AppComponent, SidenavComponent, InvoiceComponent],
  imports: [
    // angular
    BrowserAnimationsModule,
    BrowserModule,

    // core
    CoreModule,

    // app
    AppRoutingModule,
    SnotifyModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    NgbModule,
    FontAwesomeModule,
    EditableModule,
    FormsModule,
    ReactiveFormsModule,

    PolymorpheusModule,
    HttpClientModule,
    NzBreadCrumbModule,
    NzDrawerModule,
    LottieModule.forRoot({ player: playerFactory }),
    SocketIoModule.forRoot(socketConfig),
    FullCalendarModule,
    NgxDocViewerModule,
    TuiRootModule,
    TuiDialogModule,
    TuiPdfViewerModule,
    NzIconModule.forRoot(icons),
  ],
  providers: [
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
    AuthGuard,
    AuthService,
    SidenavService,
    { provide: NZ_I18N, useValue: es_ES },
    PaymentService,
    { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
    { provide: TUI_LANGUAGE, useValue: of(TUI_SPANISH_LANGUAGE) },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
