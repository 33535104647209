import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'emio-counter-field',
  templateUrl: './counter-field.component.html',
  styleUrls: ['./counter-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CounterFieldComponent),
      multi: true,
    },
  ],
})
export class CounterFieldComponent implements ControlValueAccessor {
  value: number;

  isDisabled: boolean | undefined;
  onChange = (_: any) => {};
  onTouch = () => {};

  constructor() {
    this.value = 1;
  }

  writeValue(newValue: number): void {
    this.value = newValue || 0;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  decrement() {
    if (this.value==0){
      this.value=this.value;
    }
    else{
      this.value -= 1;
      this._onChange();
    }
  }
  increment() {
    this.value += 1;
    this._onChange();
  }

  private _onChange(): void {
    this.onChange(this.value);
  }
}
