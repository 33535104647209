import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameAvatarPipe',
})
export class NameAvatarPipe implements PipeTransform {
  public transform(value: string) {
    let result: string = '';

    value.split(' ').forEach(word => {
      result += word.charAt(0).toUpperCase();
    });
    return result;
  }
}
