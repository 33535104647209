import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Role } from './core/enums/role.enum';
import { AuthGuard } from './features/auth/guards/auth.guard';
import { DropZoneComponent } from './shared/components/drop-zone/drop-zone.component';
import { InvoiceComponent } from './shared/components/invoice/invoice.component';

const routes: Routes = [
  //{ path: '', redirectTo: '/broker/dashboard', pathMatch: 'full' },
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  {
    path: 'real-estate',
    loadChildren: () =>
      import('./features/real-estate/real-estate.module').then(m => m.RealEstateModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'broker',
    loadChildren: () => import('./features/broker/broker.module').then(m => m.BrokerModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'seller',
    loadChildren: () => import('./features/seller/seller.module').then(m => m.SellerModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'scrumboard',
    loadChildren: () =>
      import('./features/scrumboard/scrumboard.module').then(m => m.ScrumboardModule),
  },

  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/admin/admin.module').then(m => m.AdminModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'project',
    loadChildren: () => import('./features/project/project.module').then(m => m.ProjectModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'timeline',
    loadChildren: () => import('./features/timeline/timeline.module').then(m => m.TimelineModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'clients',
    loadChildren: () => import('./features/client/client.module').then(m => m.ClientModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'payment',
    loadChildren: () => import('./features/payment/payment.module').then(m => m.PaymentModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'profile',
    loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'invoice',
    component: InvoiceComponent,
  },
  {
    path: 'drop',
    component: DropZoneComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
