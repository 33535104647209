import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'emio-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  @Input()
  controlName!: string;

  @Input()
  errorMessage?: string;

  @Input()
  form?:FormGroup;

  getControl(value:string): any{
    return this.form!.get(value)
  }




  constructor() {}

  ngOnInit(): void {}
}
