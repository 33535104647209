<nav class="main main-side" [ngClass]="{ expanded: expanded }" *ngIf="nav.visible">
  <button class="toggle" (click)="expanded = !expanded">
    <img
      src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxMiAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC4yNzggMC4yOTIwMTJDMTAuNjcyIC0wLjA5Nzk4ODMgMTEuMzEgLTAuMDk3OTg4MyAxMS43MDUgMC4yOTIwMTJDMTIuMDk4IDAuNjgyMDEyIDEyLjA5OCAxLjMyMjAxIDExLjcwNSAxLjcxMjAxTDMuNDIxOTkgOC45OTIwMUwxMS43MDUgMTYuMjgyQzEyLjA5OCAxNi42NzIgMTIuMDk4IDE3LjMwMiAxMS43MDUgMTcuNzAyQzExLjMxIDE4LjA5MiAxMC42NzIgMTguMDkyIDEwLjI3OCAxNy43MDJMMS4yNzA5OSA5Ljc2MjAxQzEuMDYwOTkgOS41NTIwMSAwLjk3MDk5IDkuMjcyMDEgMC45ODQ5OSA4Ljk5MjAxQzAuOTcwOTkgOC43MjIwMSAxLjA2MDk5IDguNDQyMDEgMS4yNzA5OSA4LjIzMjAxTDEwLjI3OCAwLjI5MjAxMlYwLjI5MjAxMloiIGZpbGw9IiMyRDVFQTYiLz4KPC9zdmc+Cg=="
    />
  </button>
  <div class="main__brand">
    <figure>
      <img
        src="/assets/logo.svg"
        [hidden]="currentToken && currentToken.company?.logo?.url"
        alt=""
      />
      <img
        class="rounded-circle"
        [src]="currentToken.company?.logo.url"
        *ngIf="currentToken?.company?.logo.url"
        alt=""
      />
    </figure>
    <b>EMIO</b>
  </div>
  <div class="main__nav">
    <ul class="nav" *ngIf="isRealEstateAdmin()">
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/real-estate/dashboard']" routerLinkActive="is-active">
          <ng-lottie
            width="70px"
            height="70px"
            [options]="dashboardOptions"
            (mouseenter)="mouseEnter('dashboard')"
            (mouseleave)="mouseLeave('dashboard')"
            (animationCreated)="animationCreated('dashboard', $event, false, false)"
          ></ng-lottie>
          <!-- <img src="assets/icons/dashboard_color.svg" width="40" alt="" /> -->
          <b>Dashboard</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/project/list']" routerLinkActive="is-active">
          <ng-lottie
            width="70px"
            height="70px"
            [options]="projectsOptions"
            (mouseenter)="mouseEnter('projects')"
            (mouseleave)="mouseLeave('projects')"
            (animationCreated)="animationCreated('projects', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/building_color.svg" width="40" alt="" /> -->

          <b>Proyectos</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/seller/sales-board']" routerLinkActive="is-active">
          <ng-lottie
            width="70px"
            height="70px"
            [options]="processOptions"
            (mouseenter)="mouseEnter('process')"
            (mouseleave)="mouseLeave('process')"
            (animationCreated)="animationCreated('process', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/timelines_color.svg" width="40" alt="" /> -->
          <b>Procesos</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/clients']" routerLinkActive="is-active">
          <ng-lottie
            width="70px"
            height="70px"
            [options]="contactsOptions"
            (mouseenter)="mouseEnter('contacts')"
            (mouseleave)="mouseLeave('contacts')"
            (animationCreated)="animationCreated('contacts', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/clients_color.svg" width="40" alt="" /> -->
          <b>Contactos</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/real-estate/payments']" routerLinkActive="is-active">
          <ng-lottie
            width="60px"
            height="60px"
            [options]="paymentsOptions"
            (mouseenter)="mouseEnter('payments')"
            (mouseleave)="mouseLeave('payments')"
            (animationCreated)="animationCreated('payments', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/payments_color.svg" width="40" alt="" /> -->
          <b>Pagos</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/profile']" routerLinkActive="is-active">
          <ng-lottie
            width="60px"
            height="60px"
            [options]="configOptions"
            (mouseenter)="mouseEnter('config')"
            (mouseleave)="mouseLeave('config')"
            (animationCreated)="animationCreated('config', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/profile_color.svg" width="40" alt="" /> -->
          <b>Perfil</b>
        </a>
      </li>
    </ul>

    <ul class="nav" *ngIf="isBrokerAdmin()">
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/broker/dashboard']" routerLinkActive="is-active">
          <ng-lottie
            width="70px"
            height="70px"
            [options]="dashboardOptions"
            (mouseenter)="mouseEnter('dashboard')"
            (mouseleave)="mouseLeave('dashboard')"
            (animationCreated)="animationCreated('dashboard', $event, false, false)"
          ></ng-lottie>
          <!-- <img src="assets/icons/dashboard_color.svg" width="40" alt="" /> -->
          <b>Dashboard</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/project/list']" routerLinkActive="is-active">
          <ng-lottie
            width="70px"
            height="70px"
            [options]="projectsOptions"
            (mouseenter)="mouseEnter('projects')"
            (mouseleave)="mouseLeave('projects')"
            (animationCreated)="animationCreated('projects', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/building_color.svg" width="40" alt="" /> -->

          <b>Proyectos</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/seller/sales-board']" routerLinkActive="is-active">
          <ng-lottie
            width="70px"
            height="70px"
            [options]="processOptions"
            (mouseenter)="mouseEnter('process')"
            (mouseleave)="mouseLeave('process')"
            (animationCreated)="animationCreated('process', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/timelines_color.svg" width="40" alt="" /> -->
          <b>Procesos</b>
        </a>
      </li>
      <li class="nav__item">
        <a
          class="nav__link"
          [routerLink]="['/clients']"
          routerLinkActive="is-active"
          title="Clientes"
        >
          <ng-lottie
            width="70px"
            height="70px"
            [options]="contactsOptions"
            (mouseenter)="mouseEnter('contacts')"
            (mouseleave)="mouseLeave('contacts')"
            (animationCreated)="animationCreated('contacts', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/clients_color.svg" width="40" alt="" /> -->
          <b>Contactos</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/broker/payments']" routerLinkActive="is-active">
          <ng-lottie
            width="60px"
            height="60px"
            [options]="paymentsOptions"
            (mouseenter)="mouseEnter('payments')"
            (mouseleave)="mouseLeave('payments')"
            (animationCreated)="animationCreated('payments', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/payments_color.svg" width="40" alt="" /> -->
          <b>Pagos</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/profile']" routerLinkActive="is-active">
          <ng-lottie
            width="60px"
            height="60px"
            [options]="configOptions"
            (mouseenter)="mouseEnter('config')"
            (mouseleave)="mouseLeave('config')"
            (animationCreated)="animationCreated('config', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/profile_color.svg" width="40" alt="" /> -->
          <b>Perfil</b>
        </a>
      </li>
    </ul>

    <ul class="nav" *ngIf="isBrokerSeller()">
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/seller/dashboard']" routerLinkActive="is-active">
          <ng-lottie
            width="70px"
            height="70px"
            [options]="dashboardOptions"
            (mouseenter)="mouseEnter('dashboard')"
            (mouseleave)="mouseLeave('dashboard')"
            (animationCreated)="animationCreated('dashboard', $event, false, false)"
          ></ng-lottie>
          <!-- <img src="assets/icons/dashboard_color.svg" width="40" alt="" /> -->
          <b>Dashboard</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/project/list']" routerLinkActive="is-active">
          <ng-lottie
            width="70px"
            height="70px"
            [options]="projectsOptions"
            (mouseenter)="mouseEnter('projects')"
            (mouseleave)="mouseLeave('projects')"
            (animationCreated)="animationCreated('projects', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/building_color.svg" width="40" alt="" /> -->

          <b>Proyectos</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/seller/calendar']" routerLinkActive="is-active">
          <img src="assets/icons/calendar_color.svg" width="40" alt="" />
          <b>Calendario</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/seller/sales-board']" routerLinkActive="is-active">
          <ng-lottie
            width="70px"
            height="70px"
            [options]="processOptions"
            (mouseenter)="mouseEnter('process')"
            (mouseleave)="mouseLeave('process')"
            (animationCreated)="animationCreated('process', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/timelines_color.svg" width="40" alt="" /> -->
          <b>Procesos</b>
        </a>
      </li>
      <li class="nav__item">
        <a
          class="nav__link"
          [routerLink]="['/clients']"
          routerLinkActive="is-active"
          title="Clientes"
        >
          <ng-lottie
            width="70px"
            height="70px"
            [options]="contactsOptions"
            (mouseenter)="mouseEnter('contacts')"
            (mouseleave)="mouseLeave('contacts')"
            (animationCreated)="animationCreated('contacts', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/clients_color.svg" width="40" alt="" /> -->
          <b>Contactos</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/profile']" routerLinkActive="is-active">
          <ng-lottie
            width="60px"
            height="60px"
            [options]="configOptions"
            (mouseenter)="mouseEnter('config')"
            (mouseleave)="mouseLeave('config')"
            (animationCreated)="animationCreated('config', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/profile_color.svg" width="40" alt="" /> -->
          <b>Perfil</b>
        </a>
      </li>
    </ul>
    <ul class="nav" *ngIf="isRealEstateSeller()">
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/seller/dashboard']" routerLinkActive="is-active">
          <ng-lottie
            width="70px"
            height="70px"
            [options]="dashboardOptions"
            (mouseenter)="mouseEnter('dashboard')"
            (mouseleave)="mouseLeave('dashboard')"
            (animationCreated)="animationCreated('dashboard', $event, false, false)"
          ></ng-lottie>
          <!-- <img src="assets/icons/dashboard_color.svg" width="40" alt="" /> -->
          <b>Dashboard</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/project/list']" routerLinkActive="is-active">
          <ng-lottie
            width="70px"
            height="70px"
            [options]="projectsOptions"
            (mouseenter)="mouseEnter('projects')"
            (mouseleave)="mouseLeave('projects')"
            (animationCreated)="animationCreated('projects', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/building_color.svg" width="40" alt="" /> -->

          <b>Proyectos</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/seller/calendar']" routerLinkActive="is-active">
          <ng-lottie
            width="60px"
            height="60px"
            [options]="calendarOptions"
            (mouseenter)="mouseEnter('calendar')"
            (mouseleave)="mouseLeave('calendar')"
            (animationCreated)="animationCreated('calendar', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/timelines_color.svg" width="40" alt="" /> -->
          <b>Procesos</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/seller/sales-board']" routerLinkActive="is-active">
          <ng-lottie
            width="70px"
            height="70px"
            [options]="processOptions"
            (mouseenter)="mouseEnter('process')"
            (mouseleave)="mouseLeave('process')"
            (animationCreated)="animationCreated('process', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/timelines_color.svg" width="40" alt="" /> -->
          <b>Procesos</b>
        </a>
      </li>
      <li class="nav__item">
        <a
          class="nav__link"
          [routerLink]="['/clients']"
          routerLinkActive="is-active"
          title="Clientes"
        >
          <ng-lottie
            width="70px"
            height="70px"
            [options]="contactsOptions"
            (mouseenter)="mouseEnter('contacts')"
            (mouseleave)="mouseLeave('contacts')"
            (animationCreated)="animationCreated('contacts', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/clients_color.svg" width="40" alt="" /> -->
          <b>Contactos</b>
        </a>
      </li>
      <li class="nav__item">
        <a class="nav__link" [routerLink]="['/profile']" routerLinkActive="is-active">
          <ng-lottie
            width="60px"
            height="60px"
            [options]="configOptions"
            (mouseenter)="mouseEnter('config')"
            (mouseleave)="mouseLeave('config')"
            (animationCreated)="animationCreated('config', $event, false, false)"
          ></ng-lottie>
          <!--           <img src="assets/icons/profile_color.svg" width="40" alt="" /> -->
          <b>Perfil</b>
        </a>
      </li>
    </ul>
  </div>
  <div class="main__user mt-auto mb-0" style="font-family: 'Montserrat', sans-serif">
    <a href="javascript:void(0);" (click)="logout()">
      <ng-lottie
        width="60px"
        height="60px"
        [options]="logoutOptions"
        (mouseenter)="mouseEnter('logout')"
        (mouseleave)="mouseLeave('logout')"
        (animationCreated)="animationCreated('logout', $event, false, false)"
      ></ng-lottie>
      <b>Cerrar sesión</b>
    </a>
  </div>
</nav>
