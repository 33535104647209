import { Injectable } from '@angular/core';
import { AddViewerInput, RemoveViewerInput, Timeline, Unit, User } from '@gql/graphql';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { ProjectEvents } from '../enums/project-events.enum';
import { TimelineEvents } from '../enums/timeline-events.enum';
import { UnitEvents } from '../enums/unit-events.enum';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private socket: Socket) {}

  joinViewer(data: AddViewerInput) {
    this.socket.emit(ProjectEvents.ADD_VIEWER, data);
  }

  removeViewer(data: RemoveViewerInput) {
    this.socket.emit(ProjectEvents.VIEWER_LEFT, data);
  }

  watchProject(projectId: string) {
    this.socket.emit(ProjectEvents.VIEWERS_WATCH, { project: projectId });
    return this.socket.fromEvent<User[]>(`${ProjectEvents.VIEWER_UPDATED}/${projectId}`);
  }

  getViewer() {
    return this.socket.fromEvent<User[]>(ProjectEvents.VIEWER_UPDATED);
  }

  onUnitLocked() {
    return this.socket.fromEvent<Unit>(UnitEvents.LOCKED);
  }

  onTimelineUpdated(timelineId: string): Observable<Timeline> {
    return this.socket.fromEvent<Timeline>(`${TimelineEvents.UPDATED}/${timelineId}`);
  }
}
