<button
  *ngIf="type === 'normal'"
  [disabled]="isDisabled"
  (onClick)="(click)"
  class="  bg-{{ color }}-500 border-{{
    color
  }}-600 text-blue-50 hover:scale-95 transform-gpu border hover:border-{{
    color
  }}-500 border hover:color-white border-opacity-0 hover:border-opacity-100 text-sm mx-2 py-3 px-4 ease-out rounded-lg hover:bg-white hover:text-slate-500 hover:shadow-lg transition-all duration-300 cursor-pointer"
>
  <fa-icon class="mx-2" *ngIf="icon && iconSide === 'left'" [icon]="['fas', icon]"> </fa-icon>
  <ng-content></ng-content>
  <fa-icon class="mx-2" *ngIf="icon && iconSide === 'right'" [icon]="['fas', icon]"> </fa-icon>
</button>

<button
  *ngIf="type === 'header'"
  class=" text-{{
    color
  }}-50 hover:scale-105 mx-2 p-2 rounded-full transform-gpu border-0 hover:bg-{{
    color
  }}-500 hover:shadow-lg transition-all duration-300 cursor-pointer"
  type="button"
>
  <fa-icon class="mx-2" [icon]="['fas', icon]"> </fa-icon>
  <div
    *ngIf="badge !== ''"
    class="absolute top-0 right-0 px-1.5 py-0.5 bg-{{ badgeColor }}-500 rounded-full text-xs"
  >
    {{ badge }}
  </div>
</button>
<button
  *ngIf="type === 'rounded'"
  class="  bg-{{ color }}-500 border-{{
    color
  }}-600 text-blue-50 hover:scale-105 m-2 p-2 rounded-full transform-gpu border-0 hover:bg-{{
    color
  }}-500 hover:shadow-lg transition-all duration-300 cursor-pointer"
  type="button"
>
  <fa-icon class="mx-2" [icon]="['fas', icon]"> </fa-icon>
  <div
    *ngIf="badge !== ''"
    class="absolute top-0 right-0 px-1.5 py-0.5 bg-{{ badgeColor }}-500 rounded-full text-xs"
  >
    {{ badge }}
  </div>
</button>
