import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectStagesPipe',
})
export class ProjectStagesPipe implements PipeTransform {
  public transform(value: any) {
    switch (value) {
      case 'WHITE':
        return 'En blanco';
      case 'GREEN':
        return 'En verde';
      case 'INMEDIATE_SALE':
        return 'Entrega inmediata';
      default:
        return '';
    }
  }
}
