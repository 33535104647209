import { Input } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'emio-videocall',
  templateUrl: './videocall.component.html',
  styleUrls: ['./videocall.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideocallComponent implements OnInit, AfterViewInit {
  domain: string = 'meet.jit.si'; // For self hosted use your domain
  options: any;
  api: any;
  user: any;

  @Input()
  meetId!: string;

  @Input()
  userName!: string;

  // For Custom Controls
  isAudioMuted = false;
  isVideoMuted = false;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.options = {
      roomName: this.meetId,
      width: 480,
      height: 360,
      configOverwrite: { prejoinPageEnabled: true },
      interfaceConfigOverwrite: {
        // overwrite interface properties
      },
      parentNode: document.querySelector('#jitsi-iframe'),
      userInfo: {
        displayName: this.userName,
      },
    };

    this.api = new JitsiMeetExternalAPI(this.domain, this.options);

    // Event handlers
    this.api.addEventListeners({
      readyToClose: this.handleClose,
      participantLeft: this.handleParticipantLeft,
      participantJoined: this.handleParticipantJoined,
      videoConferenceJoined: this.handleVideoConferenceJoined,
      videoConferenceLeft: this.handleVideoConferenceLeft,
      audioMuteStatusChanged: this.handleMuteStatus,
      videoMuteStatusChanged: this.handleVideoStatus,
    });
  }
  handleClose = () => {
    console.log('handleClose');
  };

  handleParticipantLeft = async participant => {
    const data = await this.getParticipants();
  };

  handleParticipantJoined = async participant => {
    const data = await this.getParticipants();
  };

  handleVideoConferenceJoined = async participant => {
    const data = await this.getParticipants();
  };

  handleVideoConferenceLeft = () => {
    //this.router.navigate(['/thank-you']);
  };

  handleMuteStatus = audio => {
  };

  handleVideoStatus = video => {
  };

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  }
  executeCommand(command: string) {
    this.api.executeCommand(command);
    if (command == 'hangup') {
      this.router.navigate(['/thank-you']);
      return;
    }

    if (command == 'toggleAudio') {
      this.isAudioMuted = !this.isAudioMuted;
    }

    if (command == 'toggleVideo') {
      this.isVideoMuted = !this.isVideoMuted;
    }
  }
}
