import { Pipe, PipeTransform } from '@angular/core';
import countries from '@shared/services/data/countries.json';

@Pipe({
  name: 'nationalityPipe',
})
export class ClientNationalityPipe implements PipeTransform {
  public transform(value: any) {
    const currentCountry = countries.filter(country => country.alpha3Code == value)[0];
    return currentCountry.translations.es || currentCountry.name;
  }
}
