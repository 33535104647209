import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationsService } from '@app/core/services/notifications.service';
import { environment } from '@env/environment';
import {
  GetPaymentGQL,
  Payment,
  PaymentCartItem,
  Unit,
  GetUnitsGQL,
  Project,
  GetProjectGQL,
} from '@gql/graphql';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { GetProjectsGQL } from '../../../../gql/graphql';
import { ShoppingCartComponent } from '../components/shopping-cart/shopping-cart.component';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private currentUnitsSource = new BehaviorSubject<PaymentCartItem[]>([]);
  private currentUnits: PaymentCartItem[];
  public currentCart$ = this.currentUnitsSource.asObservable();

  modalRef!: BsModalRef;
  constructor(
    private getPaymentGQL: GetPaymentGQL,
    private localStorage: LocalStorageService,
    private getUnitsGQL: GetUnitsGQL,
    private getProjectGQL: GetProjectGQL,
    private notifications: NotificationsService,
    private modalService: BsModalService,
    private http: HttpClient
  ) {
    this.currentUnits = this.localStorage.getCart();
    this.currentUnitsSource.next(this.currentUnits);
  }

  addToCart(item: PaymentCartItem) {
    if (this.currentUnits.indexOf(item) === -1) {
      this.currentUnits.push(item);
      this.localStorage.setCart(this.currentUnits);
      this.currentUnitsSource.next(this.currentUnits);
    } else {
      this.notifications.error('Ya existe el item en la cart');
    }
  }
  showShoppingCart(project: Project, timelineId: string) {
    const initialState = { timelineId: timelineId, project: project };
    this.modalRef = this.modalService.show(ShoppingCartComponent, {
      id: 2,
      class: 'modal-lg modal-dialog-centered',
      backdrop: 'static',
      initialState,
    });
    this.modalRef.content.project = project;
  }

  hideShoppingCart() {
    this.modalRef.hide();
  }

  removeFromCart(item: PaymentCartItem) {
    if (this.currentUnits.indexOf(item) > -1) {
      this.currentUnits.splice(this.currentUnits.indexOf(item), 1);
      this.localStorage.setCart(this.currentUnits);
      this.currentUnitsSource.next(this.currentUnits);
    } else {
      this.notifications.error('no existe el item en la cart');
    }
  }
  filterFromCart(projectId: string) {
    return this.getProjectGQL.watch({ id: projectId }).valueChanges.pipe(
      map(result => result.data.project.units),
      withLatestFrom(this.currentCart$),
      map(([units, cartItems]) => {
        let unitsInCart = cartItems
          .map(item => item.units as Unit[])
          .reduce((acc, val) => acc.concat(val), [])
          .map(item => item._id);
        return units.filter(unit => {
          return !unitsInCart.includes(unit._id);
        });
      })
    );
  }

  resetCart() {
    this.currentUnits = this.localStorage.resetCart();
    this.currentUnitsSource.next(this.currentUnits);
  }

  get(paymentId: string): Observable<Payment> {
    return this.getPaymentGQL
      .watch({ id: paymentId })
      .valueChanges.pipe(map(current => current.data.payment as Payment));
  }

  getSelledUnitsFromProject(projectId: string): Observable<Unit[]> {
    return this.getUnitsGQL
      .watch({ filters: { project: projectId, available: false, type: 'APARTMENT' } })
      .valueChanges.pipe(map(current => current.data.units as Unit[]));
  }

  doPay(timelineId: string, project: Project): Observable<any> {
    let amount = 0;
    let payloadItems: any[] = [];
    this.currentUnits.forEach(item => {
      amount += item.reservationAmount;
    });
    return this.http.post<any>(environment.baseUrl + 'api/payments', {
      amount: amount,
      project: project._id,
      items: this.currentUnits,
      timeline: timelineId,
      storeCode: 597055555536, //project.commercialInfo?.commerceCode,
    });
  }
}
