import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core/core.module';
import { ProjectService } from '@app/features/project/services/project.service';
import { Unit, UnitChart, UnitsByFloorChartStats } from '@gql/graphql';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ScrollToPlugin } from 'gsap/src/all';
import { gsap } from 'gsap';
import { Router } from '@angular/router';
gsap.registerPlugin(ScrollToPlugin);

@Component({
  selector: 'emio-units-map',
  templateUrl: './units-map.component.html',
  styleUrls: ['./units-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitsMapComponent implements OnInit, AfterViewInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  @Input()
  projectId!: string;
  max: number = 0;
  isOpen = false;
  unitSelected: UnitChart | undefined;
  floors$ = new Observable<UnitsByFloorChartStats[]>();

  openDetailTL = gsap.timeline();

  @ViewChild('detail', { static: false }) detailRef!: ElementRef;
  detail!: ElementRef<any>;

  constructor(private projectService: ProjectService, private router: Router) {}

  ngOnInit(): void {
    this.floors$ = this.projectService.getUnitsByFloorChartStats(this.projectId).pipe(
      tap(floors => {
        let max = 0;
        floors.forEach(floor => {
          if (max < floor.total) {
            max = floor.total;
          }
        });
        this.max = max;
      }),
      map(floors => {
        floors.forEach(floor => {
          if (floor.total < this.max) {
            for (let i: number = 0; i < this.max - floor.total; i++) {
              floor.units.push({ status: 'NOT_OWNED', available: false });
            }
          }
        });
        return floors;
      })
    );
  }
  ngAfterViewInit(): void {
    this.detail = this.detailRef.nativeElement;
    this.openDetailTL
      .addLabel('openDetail')
      .set(this.detail, { zIndex: -1, alpha: 0, x: 0, visibility: 0 }, 'openDetail')
      .to(
        this.detail,
        {
          autoAlpha: 1,
          x: -360,
          zIndex: -1,
          ease: 'expo.in',
          duration: 0.7,
        },
        'openDetail'
      )
      .pause(0);
  }
  showUnit(unit: UnitChart) {
    this.unitSelected = unit;
    this.isOpen = true;
    let p = document.getElementById('detail');
    p!.style.display = 'block';
    this.openDetailTL.reverse().delay(1).play(0);
  }

  hideUnit() {
    this.isOpen = false;

    this.openDetailTL.reverse().delay(2).play(0);
    let p = document.getElementById('detail');
    p!.style.display = 'none';
  }

  goDetail(unitId: string) {
    this.router.navigateByUrl(
      `/project/detail/(project-viewport:${this.projectId}/reservation/${unitId})`
    );
  }

  getVariant(unit: UnitChart) {
    return unit.model?.variants?.find(variant => variant.code === unit.variantCode);
  }
}
