import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clientPurchaseUsePipe',
})
export class ClientPurchaseUsePipe implements PipeTransform {
  public transform(value: any) {
    switch (value) {
      case 'INVESTMENT':
        return 'Inversión';
      case 'LIVING_PLACE':
        return 'Vivienda';
      default:
        return '';
    }
  }
}
