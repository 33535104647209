import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { CounterFieldComponent } from './components/counter-field/counter-field.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ThousandsPipe } from './pipes/thousands.pipe';
import { TagifyModule } from 'ngx-tagify';
import { MapSearchAddressComponent } from './components/map-search-address/map-search-address.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { DynamicScriptLoaderService } from '@services/dynamic-script-loader/dynamic-script-loader.service';
import { FunnelPanelComponent } from './components/funnel-panel/funnel-panel.component';
import { CalendarModule } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { ScheduleCalendarComponent } from './components/schedule-calendar/schedule-calendar.component';
import { EmioFullCalendarComponent } from './components/fullcalendar-emio/emioFullCalendarComponent';
import { KpiTileComponent } from './components/kpi-tile/kpi-tile.component';
import { VideocallComponent } from './components/videocall/videocall.component';
import { UnitsMapComponent } from './components/units-map/units-map.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CountUpModule } from 'ngx-countup';
import { NgxPopperModule } from 'ngx-popper';
import { MomentModule } from 'ngx-moment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ImageLoaderComponent } from './components/image-loader/image-loader.component';
import { BottomsheetComponent } from './components/bottomsheet/bottomsheet.component';
import { EditableModule } from '@ngneat/edit-in-place';
import { GalleryModule } from 'ng-gallery';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { ClientKindPipe } from './pipes/client/client-kind.pipe';
import { ClientPurchaseUsePipe } from './pipes/client/client-purchase-use.pipe';
import { ClientCivilStatusPipe } from './pipes/client/client-civil-status.pipe';
import { BanksPipe } from './pipes/banks.pipe';
import { ClientNationalityPipe } from './pipes/client/client-nationality.pipe';
import { ProjectStagesPipe } from './pipes/project/project-stages.pipe';
import { PaymentTypePipe } from './pipes/payment/payment-type.pipe';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { EditorModule } from '@tinymce/tinymce-angular';
import { UnitTypePipe } from './pipes/unit/unit-type.pipe';
import { UnitStatusPipe } from './pipes/unit/unit-status.pipe';
import { NgxEchartsModule } from 'ngx-echarts';
import { UiModule } from '@app/core/ui/ui.module';
import { NameAvatarPipe } from './pipes/client/name-avatar.pipe';
import { UnitOrientationPipe } from './pipes/unit/unit-orientation.pipe';
import { AccessControlDirective } from './directives/access-control.directive';
import { OnlyNamePipe } from './pipes/client/only-name.pipe';
import { EmptyComponent } from './components/empty/empty.component';
import { ClientUFMaxPipe } from './pipes/client/client-uf-max.pipe';
import { DropZoneComponent } from './components/drop-zone/drop-zone.component';
import { ErrorComponent } from './components/error-component/error.component';
import { TimelineStatusPipe } from './pipes/timeline/timeline-status.pipe';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import { CollaboratorRolesPipe } from './pipes/users/collaborator-roles.pipe';
import { DocModalComponent } from './components/emio-doc-modal/emio-doc-modal';

import {
  TuiAccordionModule,
  TuiDataListWrapperModule,
  TuiFieldErrorPipeModule,
  TuiInputCountModule,
  TuiInputDateModule,
  TuiInputFilesModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiInputPhoneInternationalModule,
  TuiInputTagModule,
  TuiMultiSelectModule,
  TuiPdfViewerModule,
  TuiSelectModule,
  TuiAvatarModule,
  TuiTextAreaModule,
  TuiCheckboxLabeledModule,
  TuiProgressModule,
  TuiInputSliderModule,
  TuiRadioListModule,
} from '@taiga-ui/kit';
import { SellerEventsPipe } from './pipes/seller/seller-Events.pipe';
import { SellerEventsTypePipe } from './pipes/seller/seller-EventsType.pipe';
import { SellerMeetingCountdownPipe } from './pipes/seller/seller-Meetings-countdown.pipe';
import { CartApartmentPipe } from './pipes/payment/cart-apartment.pipe';
import { TuiTableModule } from '@taiga-ui/addon-table';
import {
  TuiGroupModule,
  TuiErrorModule,
  TuiDataListModule,
  TuiLabelModule,
  TuiTextfieldControllerModule,
  TuiHintBoxModule,
  TuiHintModule,
  TuiButtonModule,
  TuiSvgModule,
} from '@taiga-ui/core';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { FileManagerComponent } from './components/file-manager/file-manager.component';
import { MultipleImagesComponent } from './components/file-manager/components/multiple-images/multiple-images.component';
import { SingleImageComponent } from './components/file-manager/components/single-image/single-image.component';
import { PDFUploaderComponent } from './components/file-manager/components/pdf-uploader/pdf-uploader.component';

// Note we need a separate function as it's required
// by the AOT compiler.

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
]);

export function playerFactory() {
  return player;
}

export function momentAdapterFactory() {
  return adapterFactory(moment);
}
@NgModule({
  declarations: [
    /* Components */
    FileUploadComponent,
    CounterFieldComponent,
    MapSearchAddressComponent,
    FunnelPanelComponent,
    ScheduleCalendarComponent,
    EmioFullCalendarComponent,
    KpiTileComponent,
    VideocallComponent,
    UnitsMapComponent,
    ImageLoaderComponent,
    BottomsheetComponent,
    EmptyComponent,
    ErrorComponent,
    DocModalComponent,
    FileManagerComponent,
    MultipleImagesComponent,
    SingleImageComponent,
    PDFUploaderComponent,

    /* Pipes */
    ThousandsPipe,
    ClientKindPipe,
    ClientPurchaseUsePipe,
    ClientCivilStatusPipe,
    BanksPipe,
    ClientNationalityPipe,
    ProjectStagesPipe,
    PaymentTypePipe,
    UnitTypePipe,
    UnitStatusPipe,
    UnitOrientationPipe,
    NameAvatarPipe,
    OnlyNamePipe,
    ClientUFMaxPipe,
    TimelineStatusPipe,
    CollaboratorRolesPipe,
    SellerEventsPipe,
    SellerMeetingCountdownPipe,
    SellerEventsTypePipe,
    CartApartmentPipe,

    AccessControlDirective,
    DropZoneComponent,
  ],
  imports: [
    //vendor
    FullCalendarModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    ModalModule.forRoot(),
    TagifyModule.forRoot(),
    GoogleMapsModule,
    GooglePlaceModule,
    CalendarModule,
    NgbModule,
    DragDropModule,
    CountUpModule,
    NgxPopperModule.forRoot(),
    MomentModule,
    FontAwesomeModule,
    LottieModule.forRoot({ player: playerFactory }),
    EditableModule,
    GalleryModule,
    NzBadgeModule,
    NzTagModule,
    NzIconModule,
    NzAvatarModule,
    NzStatisticModule,
    NzGridModule,
    NzBreadCrumbModule,
    ImageCropperModule,
    NzUploadModule,
    NzModalModule,
    NgxDocViewerModule,
    EditorModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    UiModule,

    TuiInputFilesModule,
    TuiAccordionModule,
    TuiTableModule,
    TuiGroupModule,
    TuiErrorModule,
    TuiFieldErrorPipeModule,
    TuiSelectModule,
    TuiMultiSelectModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    TuiLabelModule,
    TuiTextfieldControllerModule,
    TuiInputModule,
    TuiHintBoxModule,
    TuiHintModule,
    TuiInputNumberModule,
    TuiInputCountModule,
    TuiButtonModule,
    TuiInputTagModule,
    TuiInputPhoneInternationalModule,
    TuiInputDateModule,
    TuiAvatarModule,
    TuiTextAreaModule,
    TuiSvgModule,
    TuiCheckboxLabeledModule,
    TuiProgressModule,
    TuiInputSliderModule,
    TuiRadioListModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadComponent,
    CounterFieldComponent,
    FunnelPanelComponent,
    MapSearchAddressComponent,
    ScheduleCalendarComponent,
    EmioFullCalendarComponent,
    KpiTileComponent,
    UnitsMapComponent,
    VideocallComponent,
    HttpClientModule,
    ModalModule,
    TagifyModule,
    GoogleMapsModule,
    GooglePlaceModule,
    CalendarModule,
    NgbModule,
    DragDropModule,
    CountUpModule,
    NgxPopperModule,
    MomentModule,
    FontAwesomeModule,
    LottieModule,
    EditableModule,
    GalleryModule,
    ImageLoaderComponent,
    BottomsheetComponent,
    EmptyComponent,
    ErrorComponent,
    DocModalComponent,
    FileManagerComponent,
    MultipleImagesComponent,
    SingleImageComponent,
    PDFUploaderComponent,

    /* Pipes */
    ThousandsPipe,
    ClientKindPipe,
    ClientPurchaseUsePipe,
    ClientCivilStatusPipe,
    BanksPipe,
    ClientNationalityPipe,
    ProjectStagesPipe,
    PaymentTypePipe,
    UnitTypePipe,
    UnitStatusPipe,
    UnitOrientationPipe,
    NameAvatarPipe,
    OnlyNamePipe,
    ClientUFMaxPipe,
    TimelineStatusPipe,
    CollaboratorRolesPipe,
    SellerEventsPipe,
    SellerMeetingCountdownPipe,
    SellerEventsTypePipe,
    CartApartmentPipe,

    NzBadgeModule,
    NzTagModule,
    NzIconModule,
    NzAvatarModule,
    NzStatisticModule,
    NzGridModule,
    NzBreadCrumbModule,
    ImageCropperModule,
    NzUploadModule,
    NzModalModule,
    NgxDocViewerModule,
    EditorModule,
    NgxEchartsModule,
    UiModule,
    TuiInputFilesModule,
    TuiAccordionModule,
    TuiTableModule,
    TuiGroupModule,
    TuiErrorModule,
    TuiFieldErrorPipeModule,
    TuiSelectModule,
    TuiMultiSelectModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    TuiLabelModule,
    TuiTextfieldControllerModule,
    TuiInputModule,
    TuiHintBoxModule,
    TuiHintModule,
    TuiInputNumberModule,
    TuiInputCountModule,
    TuiButtonModule,
    TuiInputTagModule,
    TuiInputPhoneInternationalModule,
    TuiInputDateModule,
    TuiAvatarModule,
    TuiTextAreaModule,
    TuiSvgModule,
    TuiCheckboxLabeledModule,
    TuiProgressModule,
    TuiInputSliderModule,
    TuiRadioListModule,

    AccessControlDirective,
    DropZoneComponent,
  ],
  providers: [DynamicScriptLoaderService],
})
export class SharedModule { }
