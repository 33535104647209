import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sellerEventsPipe',
})
export class SellerEventsPipe implements PipeTransform {
  public transform(value: any) {
    switch (value) {
      case 'SELLER_MEET':
        //return 'Reunión de vendedor con';
        return 'Reunión de prospección con';
      case 'BROKER_VISIT':
        //return 'Visita con el broker';
        return 'Visita a proecto con';
      case 'REAL_ESTATE_VISIT':
        //return 'Visita inmobiliaria';
        return 'Visita a proecto con';
      default:
        return '';
    }
  }
}
