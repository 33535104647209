<tui-root>
  <emio-notifications></emio-notifications>
  <main role="main" class="flex min-h-screen">
    <div class="flex flex-grow overflow-hidden">
      <div class="flex flex-auto">
        <emio-side-navigation></emio-side-navigation>
        <div class="flex flex-col w-full">
          <emio-header></emio-header>
          <div class="flex overflow-x-hidden overflow-y-auto h-full">
            <section
              class="w-full h-full"
              [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig?.data?.title"
            >
              <router-outlet #o="outlet"></router-outlet>
            </section>
          </div>
        </div>
      </div>
    </div>
  </main>
  <ng-container ngProjectAs="tuiOverContent">
    <!-- Content over app content -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverDialogs">
    <!-- Content over dialogs -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverAlerts">
    <!-- Content over notifications -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverPortals">
    <!-- Content over dropdowns -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverHints">
    <!-- Content over hints -->
  </ng-container>
  <!--
<div
  class="min-h-screen bg-no-repeat bg-cover bg-center"
  style="
    background-image: url('https://images.unsplash.com/photo-1486520299386-6d106b22014b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80');
  "
>
  <div class="flex justify-end">
    <div class="bg-white min-h-screen w-1/2 flex justify-center items-center">
      <div>
        <form>
          <div>
            <span class="text-sm text-slate-900">Welcome back</span>
            <h1 class="text-2xl font-bold">Login to your account</h1>
          </div>
          <div class="mt-5">
            <label class="block text-md mb-2" for="password">Password</label>
            <input
              class="px-4 w-full border-2 py-2 rounded-md text-sm outline-none"
              type="password"
              name="password"
              placeholder="password"
            />
          </div>
          <div class="my-3">
            <label class="block text-md mb-2" for="email">Email</label>
            <input
              class="px-4 w-full border-2 py-2 rounded-md text-sm outline-none"
              type="email"
              name="password"
              placeholder="email"
            />
          </div>
          <div class="flex justify-between">
            <div>
              <input class="cursor-pointer" type="radio" name="rememberme" />
              <span class="text-sm">Remember Me</span>
            </div>
            <span class="text-sm text-blue-700 hover:underline cursor-pointer"
              >Forgot password?</span
            >
          </div>
          <div class="">
            <button
              class="mt-4 mb-3 w-full bg-emerald-500 hover:bg-emerald-400 text-white py-2 rounded-md transition duration-100"
            >
              Login now
            </button>
            <div
              class="flex space-x-2 justify-center items-end bg-slate-700 hover:bg-slate-600 text-white py-2 rounded-md transition duration-100"
            >
              <img class="h-5 cursor-pointer" src="https://i.imgur.com/arC60SB.png" alt="" />
              <button>Or sign-in with google</button>
            </div>
          </div>
        </form>
        <p class="mt-8">
          Dont have an account?
          <span class="cursor-pointer text-sm text-blue-600"> Join free today</span>
        </p>
      </div>
    </div>
  </div>
</div> -->
</tui-root>
