import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'emio-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelComponent implements OnInit {
  @Input()
  title: string = '';

  @Input()
  actions?: any[];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }
}
