import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'banksPipe',
})
export class BanksPipe implements PipeTransform {
  public transform(value: string) {
    switch (value) {
      case 'BCHILE':
        return 'Banco de Chile';
      case 'SANTANDER':
        return 'Banco Santander';
      case 'ESTADO':
        return 'Banco del Estado';
      case 'SCOTIABANK':
        return 'Scotiabank Chile';
      case 'BCI':
        return 'Banco de Crédito e Inversiones (BCI)';
      case 'BICE':
        return 'Banco Bice';
      case 'HSBC':
        return 'HSBC Bank';
      case 'ITAU':
        return 'Itaú - Corpbanca';
      case 'SECURITY':
        return 'Banco Security';
      case 'FALABELLA':
        return 'Banco Falabella';
      case 'RIPLEY':
        return 'Banco Ripley';
      case 'CONSORCIO':
        return 'Banco Consorcio';
      case 'SCOTIAZUL':
        return 'Scotiabank Azul (ex BBVA)';
      case 'BTG':
        return 'Banco BTG Pactual Chile';
      default:
        return '';
    }
  }
}
