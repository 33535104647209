import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unitStatusPipe',
})
export class UnitStatusPipe implements PipeTransform {
  public transform(value: any) {
    switch (value) {
      case 'AVAILABLE':
        return 'Disponible';
      case 'RESERVED':
        return 'Reservado';
      case 'SIGNED_PROMISE':
        return 'Promesa firmada';
      case 'SIGNED_DEED':
        return 'Escritura firmada';
      case 'SIGNED_CONSERVATOR':
        return 'Conservador de Bienes Raíces';
      case 'RELEASED':
        return 'Liberada';
      default:
        return '';
    }
  }
}
