<div class="row text-center">
  <div class="col-12 mb-4">
    <!-- TODO FIXED AND DELETED FIELD WHERE NEW DATA IS ON CONTAINER-->
    <h3>{{ viewDate | calendarDate: view + 'ViewTitle':'es' | uppercase }}</h3>
  </div>
  <div class="col-md-6 mb-4">
    <div class="btn-group">
      <div
        class="btn bg-azure mx-1 text-slate-50"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()"
      >
        &#10510;
      </div>
      <div class="btn bg-geyser mx-1" mwlCalendarToday [(viewDate)]="viewDate">Hoy</div>
      <div

        class="btn bg-azure mx-1 text-slate-50"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()"
      >
        &#10511;
      </div>
    </div>
  </div>
  <div class="col-md-6 mb-4">
    <div class="btn-group">
      <div
        class="btn bg-azure mx-1 text-slate-50"
        (click)="setView(CalendarView.Month)"
        [class.active]="view === CalendarView.Month"
      >
        Mes
      </div>
      <div
        class="btn bg-azure mx-1 text-slate-50"
        (click)="setView(CalendarView.Week)"
        [class.active]="view === CalendarView.Week"
      >
        Semana
      </div>
      <div
        class="btn bg-azure mx-1 text-slate-50"
        (click)="setView(CalendarView.Day)"
        [class.active]="view === CalendarView.Day"
      >
        Días
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div [ngSwitch]="view" class="col-md-12 text-center">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [locale]="locale"
      [weekStartsOn]="weekStartsOn"
      [weekendDays]="weekendDays"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventTimesChanged)="eventTimesChanged($event)"
      [cellTemplate]="customCellTemplate"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [locale]="locale"
      [weekStartsOn]="weekStartsOn"
      [weekendDays]="weekendDays"
      [events]="events"
      [refresh]="refresh"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [locale]="locale"
      [events]="events"
      [refresh]="refresh"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-day-view>
  </div>
</div>
<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top">
    <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
    <span class="cal-day-number">{{ day.date | calendarDate: 'monthViewDayNumber':locale }}</span>
  </div>
  <small style="margin: 5px" *ngIf="day.badgeTotal > 1"
    ><b>{{ day.events.length }} reuniones</b></small
  >
  <small style="margin: 5px" *ngIf="day.badgeTotal == 1"
    ><b>{{ day.events.length }} reunión</b></small
  >
  <small style="margin: 5px" *ngIf="day.badgeTotal == 0">Sin reuniones</small>
</ng-template>
