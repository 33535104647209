import { Pipe, PipeTransform } from '@angular/core';
import { PaymentCartItem, Unit } from '@gql/graphql';

@Pipe({
  name: 'cartApartmentPipe',
})
export class CartApartmentPipe implements PipeTransform {
  transform(item: PaymentCartItem, args?: any): Unit {
    return item?.units?.filter(unit => unit.type === 'APARTMENT')[0];
  }
}
