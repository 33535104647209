import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormArray, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationsService } from '@app/core/services/notifications.service';
import { FileUploadComponent } from '@app/shared/components/file-upload/file-upload.component';
import { File as CloudFile } from '@gql/graphql';
import { FileStorageService } from '@services/documents/firebase-storage.service';
import { TuiPdfViewerService, TuiPdfViewerOptions } from '@taiga-ui/kit';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'emio-pdf-uploader',
  templateUrl: './pdf-uploader.component.html',
  styleUrls: ['./pdf-uploader.component.scss'],
})
export class PDFUploaderComponent implements OnInit, OnChanges {
  @Input()
  file?: CloudFile | null;

  @Output() fileChange = new EventEmitter<CloudFile>();
  fileList: NzUploadFile[] = [];
  previewPDF?: string;
  previewVisible = false;
  loading = false;

  @Output() onFileUpload = new EventEmitter<NzUploadFile[]>();

  ACTION_URL: string = `https://api.emio.cl/api/files/upload`;
  constructor(
    private uploadService: FileStorageService,
    private ref: ChangeDetectorRef,
    private notifications: NotificationsService,
    @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
    @Inject(TuiPdfViewerService) private readonly pdfService: TuiPdfViewerService
  ) {}

  ngOnInit() {
    if (this.file) {
      this.fileList = [
        {
          uid: this.file._id,
          name: this.file.url,
          status: 'done',
          url: this.file.url,
          thumbUrl: this.file.url,
          response: this.file,
        },
      ];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let file = changes['file'];

    if (!file.firstChange) {
      this.fileList = [
        {
          uid: file.currentValue._id,
          name: file.currentValue.url,
          status: 'done',
          url: file.currentValue.url,
          thumbUrl: file.currentValue.url,
          response: file.currentValue,
        },
      ];
      console.log('CURRENT FILES', this.fileList);
    }
  }

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    this.pdfService
      .open(this.sanitizer.bypassSecurityTrustResourceUrl(file.response.url), {
        label: 'Previsualizar documento',
      })
      .subscribe();
  };
  handlePreviewFile = (file: NzUploadFile): Observable<string> =>
    new Observable((observer: Observer<string>) => {
      if (!file.response) {
        observer.complete();
        return;
      }
      this.previewPDF = file.response.url;
      observer.next(file.response.url);
      observer.complete();
    });

  handleChange(info: { file: NzUploadFile; fileList }): void {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    this.fileList = fileList;
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        this.notifications.success('Archivo subido correctamente.');
        getBase64(info.file!.originFileObj!).then(img => {
          this.previewPDF = img;
          this.loading = false;
        });
        break;
      case 'error':
        this.notifications.error('Error en la subida del archivo. Reintente.');
        this.loading = false;
        break;
      case 'removed':
        this.notifications.success('Archivo eliminado correctamente.');
        console.log(info);
        break;
    }
  }

  handleUpload = (item: any) => {
    const formData = new FormData();
    formData.append(item.name, item.file as any);
    return this.uploadService.uploadFile(formData).subscribe(
      res => {
        item.onSuccess(res);
        console.log('success', res._id);
        this.file = res;
        this.fileChange.emit(res);
      },
      err => {
        item.onError(err, item.file);
      }
    );
  };
}
const getBase64 = (file: File): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const getBase64FromUrl = async (url): Promise<any> => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};
