import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SideNavigationService } from '../../../services/side-navigation.service';

@Component({
  selector: 'emio-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(public sidebar: SideNavigationService) {}

  ngOnInit(): void {}

  showNotifications() {
    this.sidebar.showNotifications();
  }

  logout() {
    this.sidebar.logout();
  }
}
