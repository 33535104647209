<header
  *ngIf="sidebar.showSidebar | async"
  class="flex w-full justify-end items-center bg-gradient-to-r from-slate-600 via-slate-600 to-slate-800 p-3"
>
  <div class="flex items-center text-white text-base">
    <!--     <emio-button
      (click)="showNotifications()"
      type="header"
      title="Notificaciones"
      badge="2"
      badgeColor="red"
      color="gray"
      icon="bell"
    ></emio-button> -->
    <emio-button
      type="header"
      (click)="logout()"
      title="Cerrar sesión"
      icon="sign-out-alt"
      color="gray"
    ></emio-button>
  </div>
</header>
