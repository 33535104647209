import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
} from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import esLocale from '@fullcalendar/core/locales/es';
import { es } from 'date-fns/locale';
import { title } from 'process';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ClientSearchComponent } from '@app/features/client/components/client-search/client-search.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SellerService } from './../../../features/seller/services/seller.service';
import { Calendar, FullCalendarComponent } from '@fullcalendar/angular';
import { ScheduleEvent } from '../../../../gql/graphql';
import { OnInit } from '@angular/core';
import $ from 'jquery';
interface dataCalendar {
  start: string;
  end: string | undefined;
  status: boolean;
}

@Component({
  selector: 'emio-full-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['emioFullCalendarComponent.scss'],
  templateUrl: 'emioFullCalendarComponent.html',
})
export class EmioFullCalendarComponent implements OnInit {

  @Input('selectedDay') selectedDay: string = new Date().toISOString().split('T')[0];
  @ViewChild('fullCalendar') calendarComponent?: FullCalendarComponent;

  @Output()
  onNewEvent: EventEmitter<any> = new EventEmitter();

  @Input()
  events: ScheduleEvent[] | null = []

  calendarApi?: Calendar;

  modalRef!: BsModalRef;
  eventsCalendar: any[] = [
    /* {
      //start: '2022-08-21',
      start: '2022-10-19',
      description: 'a',
      title: 'Evento2',
      allday: false,
      extendedProps: {
        department: 'random',
        kind: '',
        status: '',
        available: '',
        project: '',
        client: '',
      },
    }, */
  ];
  constructor(
    public overlay: Overlay,
    private modalService: BsModalService,
    private sellerService: SellerService
  ) {

  }
  data: dataCalendar = {
    start: '',
    end: '',
    status: false,
  };
  overlayRef?: OverlayRef;
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      start: '',
      center: 'title',
      end: '',
    },
    //initialDate: this.selectedDay,
    initialView: 'timeGridWeek',
    locale: esLocale,
    weekends: true,
    droppable: true,
    editable: true,
    selectable: true,
    select: this.createEvent.bind(this),
    events: this.eventsCalendar,
  };

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

    //Calendar main column
    $('tr th').css('background-color', '#0000CD')
    $('.fc-scrollgrid-shrink-cushion').css('color', 'white')

    //Calendar main rows
    $('tr td .fc-timegrid-slot-label').css('background-color', '#1E90FF')
    $('tr td .fc-timegrid-axis').css('background-color', '#1E90FF')
    $('.fc-scrollgrid-sync-inner a').css('color', 'white')

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.selectedDay) {
      this.changeDate(changes.selectedDay.currentValue)
    }

    if (changes.events) {

      this.calendarApi = this.calendarComponent?.getApi();
      this.calendarApi?.removeAllEvents()

      this.eventsCalendar = (changes.events.currentValue as ScheduleEvent[])?.map((event) => {

        let eventColor = event.kind == 'SELLER_MEET' ? 'green' : 'blue';

        console.log(event)
        let eventToInsert = {
          start: event.startsAt,
          end: event.endsAt,
          description: event.description,
          title: event.description,
          allday: false,
          color: eventColor,
          //textColor: 'black',
          extendedProps: {
            url: `seller/meet/${event.code}`
          }
        }

        this.calendarApi?.addEvent(eventToInsert);
      })

    }
  }

  changeDate(newDate: string): void {
    this.calendarApi = this.calendarComponent?.getApi();
    this.calendarApi?.gotoDate(newDate);
  }

  addEvento() {
    console.log('event Test 1')
  }

  // THIS SEND CLICK DATE DATA TO A MODAL -> UPDATE-CREATE
  createEvent(arg) {
    let date = {
      startStr: arg.startStr,
      endStr: arg.endStr,
      allday: false,
    };

    this.onNewEvent.emit(date);
  }



}
