import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clientKindPipe',
})
export class ClientKindPipe implements PipeTransform {
  public transform(value: any) {
    switch (value) {
      case 'INDEPENDENT':
        return 'Independiente';
      case 'DEPENDENT':
        return 'Dependiente';
      case 'BUSINESS_PARTNER':
        return 'Socio de empresa';
      default:
        return '';
    }
  }
}
