import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sellerEventsTypePipe',
})
export class SellerEventsTypePipe implements PipeTransform {
  public transform(value: any) {
    switch (value) {
      case 'SELLER_MEET':
        return 'Reunión vendedor';
      case 'BROKER_VISIT':
        return 'Visita broker';
      case 'REAL_ESTATE_VISIT':
        return 'Visita inmobiliaria';
      default:
        return '';
    }
  }
}
