import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetClientsGQL, Client, CreateScheduleEventGQL, CreateScheduleEventInput, GetScheduleEventsGQL, ListScheduleEventsInput, ScheduleEvent, GetTimelinesBySellerGQL, GetScheduleEventsQuery, GetScheduleEventsQueryVariables } from '@gql/graphql';
import { map, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SellerProgressProcesses, GetSellerProgressProcessesGQL, GetScheduleEventByCodeGQL } from '../../../../gql/graphql';
import { QueryRef } from 'apollo-angular';
interface DataCalendar {
  status: boolean;
  start: string,
  end: string;
}
@Injectable({
  providedIn: 'root',
})


export class SellerService {

  constructor(
    private createScheduleEventGQL: CreateScheduleEventGQL,
    private getScheduleEventsGQL: GetScheduleEventsGQL,
    private http: HttpClient,
    private getTimelinesBySellerGQL: GetTimelinesBySellerGQL,
    private GetClientsGQL: GetClientsGQL,
    private getSellerProgressProcessesGQL: GetSellerProgressProcessesGQL,
    private getScheduleEventByCodeGQL: GetScheduleEventByCodeGQL
  ) { }
  DataCal: DataCalendar = {
    status: false,
    start: '',
    end: ''
  };

  private getScheduleRef?: QueryRef<GetScheduleEventsQuery, GetScheduleEventsQueryVariables>

  getTimelinesbySeller(sellerId: string) {
    return this.getTimelinesBySellerGQL.watch({ sellerId }).valueChanges.pipe(map(result => result.data.timelinesBySeller));
  }


  getClients($sellerId: string) {
    return this.GetClientsGQL.watch({ filters: { assignedTo: $sellerId } }).valueChanges.pipe(map(current => current.data.clients as Client[]));
  }


  getStatus() {
    return this.DataCal.status;
  }


  setDataCal(arg) {
    this.DataCal.end = arg.endDate;
    this.DataCal.start = arg.startDate;
    this.DataCal.status = true;
  }


  getData() {
    return this.DataCal;
  }


  getSellerProgressProcesses(sellerId: string): Observable<SellerProgressProcesses[]> {
    return this.getSellerProgressProcessesGQL
      .watch({ sellerId })
      .valueChanges.pipe(map(result => result.data.sellerProgressProcesses as SellerProgressProcesses[]));
  }

  getSheculeEventByCode(code: string): Observable<ScheduleEvent> {

    let test = this.getScheduleEventByCodeGQL
    .watch({code: code})
    .valueChanges.pipe(map(result => result.data.scheduleEventByCode as ScheduleEvent));

    return this.getScheduleEventByCodeGQL
      .watch({code: code})
      .valueChanges.pipe(map(result => result.data.scheduleEventByCode as ScheduleEvent));
  }

  getScheduleEvents(filters: ListScheduleEventsInput): Observable<ScheduleEvent[]> {

    this.getScheduleRef = this.getScheduleEventsGQL.watch();
    this.getScheduleRef.setVariables({filters});

    return this.getScheduleRef.valueChanges.pipe(
      map(result => result.data.scheduleEvents as ScheduleEvent[])
    );

    /* return this.getScheduleEventsGQL.watch({ filters }).valueChanges.pipe(
      map(result => result.data.scheduleEvents as ScheduleEvent[])
    ); */
  }


  createNewEvent(payload: CreateScheduleEventInput): Observable<ScheduleEvent> {
    return this.createScheduleEventGQL.mutate({ payload }).pipe(
      map(result => {
        this.getScheduleRef?.refetch();
        return result.data?.createScheduleEvent as ScheduleEvent;
      })
    );
  }

}
