import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Console } from 'console';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  cachedUF$?: Observable<number>;

  constructor(private http: HttpClient) {}

  UF(): Observable<number> {
    if (!this.cachedUF$) {
      this.cachedUF$ = this.http
        .get(
          'https://api.cmfchile.cl/api-sbifv3/recursos_api/uf?apikey=159dcaf6f8782cf924a5e54a389f968d1bd3335d&formato=json'
        )
        .pipe(
          map((response: any) => {
            const data = response.UFs[0].Valor.split(',');
            let thousand = data[0].replace('.', '');
            let decimal = data[1];
            let UFGENERAL = thousand + '.' + decimal;
            return Math.ceil(parseFloat(UFGENERAL));
          }),
          shareReplay(1)
        );
    }
    return this.cachedUF$;
  }

  getStageName(stage: string): string {
    switch (stage) {
      case 'WHITE':
        return 'En blanco';

      case 'GREEN':
        return 'En verde';

      case 'INMEDIATE_SALE':
        return 'Entrega inmediata';

      default:
        return '';
    }
  }

  GetIdFromDocument(document: any) {
    if (document != null && typeof document != 'string') {
      return document._id;
    } else return null;
  }

  mapConfig() {
    return [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#bdbdbd',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dadada',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#c9c9c9',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
    ];
  }
}
