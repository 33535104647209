import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'emio-kpi-tile',
  templateUrl: './kpi-tile.component.html',
  styleUrls: ['./kpi-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiTileComponent implements OnInit {
  @Input()
  value!: number;

  @Input()
  unit?: string;

  @Input()
  percent?: number;

  constructor() {}

  ngOnInit(): void {}
}
