<div class="flex flex-auto shadow rounded-xl bg-white p-4 m-4">
  <div class="flex-grow p-2">
    <div class="flex flex-col">
      <div class="py-2">
        <h1 class="title">{{ title }}</h1>
      </div>
      <div class="justify-items-stretch mt-1 w-full">
        <ng-content></ng-content>
      </div>
      <div class="py-2 mt-2">
        <div class="col-12 text-right" *ngIf="actions">
          <emio-button
            *ngFor="let action of actions"
            (click)="navigateTo(action.link)"
            [icon]="action.icon"
          >
            {{ action.name }}
          </emio-button>
        </div>
      </div>
    </div>
  </div>
</div>
