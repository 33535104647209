<div class="modal-header">
  <h1 class="title">Carro de compras</h1>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row no-gutters" [hidden]="isEmpty$ | async">
    <div class="col-12 col-md-12 px-2">
      <table class="table w-100">
        <thead>
          <tr>
            <th class="text-left" colspan="2">Unidad</th>
            <th class="text-right">Estacionamiento</th>
            <th class="text-right">Bodega</th>
            <th class="text-right">Precio Propiedad</th>
            <th class="text-right">Precio Reserva</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of cart$ | async; let i = index"
            [@simpleFadeAnimation]="'in'"
            class="cart-item"
          >
            <td width="80">
              <img
                class="img-list"
                *ngIf="getApartment(item).model?.image?.url"
                [src]="getApartment(item).model?.image?.url"
                alt=""
              />
            </td>
            <td>
              <h6>Piso {{ getApartment(item).floor }}</h6>
              <h3>{{ getApartment(item).name }}</h3>
              <small *ngIf="getParking(item)">Estacionamiento {{ getParking(item).name }}</small>
              <br />
              <small *ngIf="getWarehouse(item)">Bodega {{ getWarehouse(item).name }}</small>
            </td>
            <td class="text-right">
              <div *ngIf="getParking(item)">
                <h3>
                  <sup><small>UF</small></sup
                  ><strong>{{ getParking(item).sellPrice }}</strong>
                </h3>
                <small>$ {{ getCLP(getParking(item).sellPrice) | thousandsPipe }}</small>
              </div>
            </td>
            <td class="text-right">
              <div *ngIf="getWarehouse(item)">
                <h3>
                  <sup><small>UF</small></sup
                  ><strong>{{ getWarehouse(item).sellPrice }}</strong>
                </h3>
                <small>$ {{ getCLP(getWarehouse(item).sellPrice) | thousandsPipe }}</small>
              </div>
            </td>
            <td class="text-right">
              <h3>
                <sup><small>UF</small></sup
                ><strong>{{ getBonusPrice(item) }}</strong>
              </h3>
              <small>$ {{ getCLP(getBonusPrice(item)) | thousandsPipe }}</small>
            </td>
            <td class="text-right" *ngIf="project">
              <h2 class="text-azure" *ngIf="false">
                <strong
                  ><sup><small>UF</small></sup> 16</strong
                >
              </h2>
              <small *ngIf="project.commercialInfo"
                ><sup>$</sup>
                {{
                  (project.commercialInfo.reservationFee
                    ? project.commercialInfo.reservationFee
                    : 0
                  ) | thousandsPipe
                }}</small
              >
            </td>
            <td>
              <a>
                <ng-lottie
                  (mouseenter)="mouseEnter('delete' + i)"
                  (mouseleave)="mouseLeave('delete' + i)"
                  width="50px"
                  [options]="deleteOptions"
                  (click)="removeItem(item)"
                  (animationCreated)="animationCreated('delete' + i, $event, false, false)"
                ></ng-lottie
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12 col-md-4 bg-geyser-light offset-md-8">
      <h4 class="title mb-5">Total reserva:</h4>
      <div class="summary-shipping mb-5" *ngIf="project && project.commercialInfo">
        <h2 class="text-azure" *ngIf="cart$ | async as units">
          $
          <b
            [countUp]="
              project.commercialInfo.reservationFee
                ? project.commercialInfo.reservationFee * units.length
                : 0
            "
            [options]="{ duration: 1, decimal: ',', separator: '.' }"
            >0</b
          >
        </h2>
      </div>
      <emio-payment-button
        [project]="project"
        [timeline]="timelineId"
        [paymentStatus]="paymentStatus$"
        (onPaymentCreated)="onPaymentCreated($event)"
      ></emio-payment-button>
      <div class="col-12 col-md-12" *ngIf="paymentStatus$ | async as paymentStatus">
        <div *ngIf="paymentStatus.transaction.details[0].status === 'AUTHORIZED'">
          <span>PAGO EXITOSO</span>
        </div>
        <span *ngIf="paymentStatus.transaction.details[0].status === 'PAYMENT_FAILED'"
          >PAGO FALLIDO</span
        >
      </div>
    </div>
  </div>
  <div class="row justify-content-center" *ngIf="isEmpty$ | async">
    <ng-lottie
      width="500px"
      [options]="options"
      (animationCreated)="animationCreated('empty', $event)"
    ></ng-lottie>
    <h4 class="title mt-4">Aún no agregas unidades a tu carrito.</h4>
  </div>
</div>
