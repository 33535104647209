import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};



export type AccessToken = {
  __typename?: 'AccessToken';
  access_token: Scalars['String'];
  user: User;
  company: Company;
};

export type ActivateUserInput = {
  verificationToken: Scalars['String'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
};

export type AddAdminInput = {
  admin?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
};

export type AddCollaboratorInput = {
  collaborator?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
};

export type AddHistoryInput = {
  timeline?: Maybe<Scalars['String']>;
  history?: Maybe<HistoryInput>;
};

export type AddModelInput = {
  model?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
};

export type AddSuggestedProjectInput = {
  timeline?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
};

export type AddViewerInput = {
  viewer?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
};

export type Amenity = {
  __typename?: 'Amenity';
  name: Scalars['String'];
  icon: Scalars['String'];
};

export type Billing = {
  __typename?: 'Billing';
  socialReason?: Maybe<Scalars['String']>;
  billingRut?: Maybe<Scalars['String']>;
  billingEmail?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  economicActivity?: Maybe<Scalars['String']>;
};

export type BrokerAddProjectInput = {
  brokerId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
};

export type BrokerCompany = ICompany & {
  __typename?: 'BrokerCompany';
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<File>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime: Scalars['Boolean'];
  kind: Scalars['String'];
  admin?: Maybe<User>;
  collaborators?: Maybe<Array<User>>;
  projects?: Maybe<Array<Project>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type BrokerFiltersInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
  projects?: Maybe<Array<Scalars['String']>>;
  _id?: Maybe<Scalars['String']>;
};

export type BrokerProjectSales = {
  __typename?: 'BrokerProjectSales';
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  projectCompanyName?: Maybe<Scalars['String']>;
  projectCoverUrl?: Maybe<Scalars['String']>;
  totalBrokerSales?: Maybe<Scalars['Float']>;
  unsoldProjectUnits?: Maybe<Scalars['Float']>;
  totalProjectSales?: Maybe<Scalars['Float']>;
  totalProjectUnits?: Maybe<Scalars['Float']>;
  sellers?: Maybe<Array<ListSellers>>;
};

export type BrokerSellerPayments = {
  __typename?: 'BrokerSellerPayments';
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  projectCoverURL?: Maybe<Scalars['String']>;
  projectCompanyName?: Maybe<Scalars['String']>;
  promisePaymentPercent?: Maybe<Scalars['Float']>;
  deedPaymentPercent?: Maybe<Scalars['Float']>;
  brokerFee?: Maybe<Scalars['Float']>;
  totalProjectSales?: Maybe<Scalars['Float']>;
  sellers?: Maybe<Array<ListSellersUnits>>;
};

export type Client = {
  __typename?: 'Client';
  _id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  rut: Scalars['String'];
  phone: Scalars['String'];
  nationality: Scalars['String'];
  address: Scalars['String'];
  profession: Scalars['String'];
  civilStatus: Scalars['String'];
  interests?: Maybe<Array<Interest>>;
  purchaseUse: Scalars['String'];
  kind: Scalars['String'];
  liquidIncome: Scalars['Float'];
  currentAccount: Scalars['String'];
  bank: Scalars['String'];
  monthlySavingsCapacity: Scalars['Float'];
  outstandingDebts: Scalars['Float'];
  comments: Scalars['String'];
  origin?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  frontIdCard?: Maybe<File>;
  backIdCard?: Maybe<File>;
  socialSecurityQuotes: Array<File>;
  liquidations: Array<File>;
  validToBuy: Scalars['Boolean'];
  owner: User;
  assignedTo?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type CommercialInfo = {
  __typename?: 'CommercialInfo';
  commerceCode?: Maybe<Scalars['String']>;
  reservationFee?: Maybe<Scalars['Float']>;
  realEstateFee?: Maybe<Scalars['Float']>;
  brokerFee?: Maybe<Scalars['Float']>;
  promisePaymentPercent?: Maybe<Scalars['Float']>;
  deedPaymentPercent?: Maybe<Scalars['Float']>;
  paymentBonusTerms?: Maybe<Array<PaymentTermsBonus>>;
  paymentPieTerms?: Maybe<Array<PaymentTermsPie>>;
  reservationPolicy?: Maybe<Scalars['String']>;
};

export type CommercialInformationInput = {
  commerceCode?: Maybe<Scalars['String']>;
  reservationFee?: Maybe<Scalars['Float']>;
  realEstateFee?: Maybe<Scalars['Float']>;
  brokerFee?: Maybe<Scalars['Float']>;
  promisePaymentPercent?: Maybe<Scalars['Float']>;
  deedPaymentPercent?: Maybe<Scalars['Float']>;
  paymentBonusTerms?: Maybe<Array<PaymentTermBonusInput>>;
  paymentPieTerms?: Maybe<Array<PaymentTermPieInput>>;
  reservationPolicy?: Maybe<Scalars['String']>;
};

export type Company = ICompany & {
  __typename?: 'Company';
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<File>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime: Scalars['Boolean'];
  kind: Scalars['String'];
  admin?: Maybe<User>;
  collaborators?: Maybe<Array<User>>;
  projects?: Maybe<Array<Project>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type Contact = {
  __typename?: 'Contact';
  name: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type CreateAmenityInput = {
  name: Scalars['String'];
  icon: Scalars['String'];
};

export type CreateBillingInput = {
  socialReason?: Maybe<Scalars['String']>;
  billingRut?: Maybe<Scalars['String']>;
  billingEmail?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  economicActivity?: Maybe<Scalars['String']>;
};

export type CreateBrokerInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
  projects?: Maybe<Array<Scalars['String']>>;
};

export type CreateClientInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  civilStatus?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<InterestInput>>;
  purchaseUse?: Maybe<Scalars['String']>;
  frontIdCard?: Maybe<Scalars['String']>;
  backIdCard?: Maybe<Scalars['String']>;
  socialSecurityQuotes?: Maybe<Array<Scalars['String']>>;
  liquidations?: Maybe<Array<Scalars['String']>>;
  validToBuy?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Scalars['String']>;
  liquidIncome?: Maybe<Scalars['Float']>;
  currentAccount?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  monthlySavingsCapacity?: Maybe<Scalars['Float']>;
  outstandingDebts?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  owner: Scalars['String'];
  assignedTo?: Maybe<Scalars['String']>;
};

export type CreateCompanyInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
  projects?: Maybe<Array<Scalars['String']>>;
};

export type CreateContactInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type CreateDocumentationInput = {
  reservationTemplate?: Maybe<Scalars['String']>;
  promiseTemplate?: Maybe<Scalars['String']>;
  deedTemplate?: Maybe<Scalars['String']>;
  offerTemplate?: Maybe<Scalars['String']>;
};

export type CreateFileInput = {
  url?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateLegalInput = {
  companySocialReason?: Maybe<Scalars['String']>;
  companyRut?: Maybe<Scalars['String']>;
  representativeName?: Maybe<Scalars['String']>;
  representativeRut?: Maybe<Scalars['String']>;
  representativeEmail?: Maybe<Scalars['String']>;
  representativeAddress?: Maybe<Scalars['String']>;
  representativeAddressOther?: Maybe<Scalars['String']>;
};

export type CreateLocationInput = {
  type: Scalars['String'];
  coordinates: Array<Scalars['Float']>;
};

export type CreateModelInput = {
  name?: Maybe<Scalars['String']>;
  typology?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  variants?: Maybe<Array<VariantInput>>;
  bathrooms?: Maybe<Scalars['Float']>;
  bedrooms?: Maybe<Scalars['Float']>;
  hasGarden?: Maybe<Scalars['Boolean']>;
  isStudio?: Maybe<Scalars['Boolean']>;
};

export type CreatePaymentInput = {
  buyOrder?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  storeCode?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  clientConfirmation?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  project?: Maybe<Scalars['String']>;
  timeline?: Maybe<Scalars['String']>;
  items?: Maybe<Array<PaymentCartItemInput>>;
  transaction?: Maybe<Scalars['JSONObject']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeferred?: Maybe<Scalars['Boolean']>;
};

export type CreateProjectFeaturesInput = {
  bathrooms?: Maybe<MinMaxInput>;
  bedrooms?: Maybe<MinMaxInput>;
  usefulSurface?: Maybe<MinMaxInput>;
  totalSurface?: Maybe<MinMaxInput>;
};

export type CreateProjectInput = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  location?: Maybe<CreateLocationInput>;
  features?: Maybe<CreateProjectFeaturesInput>;
  amenities?: Maybe<Array<CreateAmenityInput>>;
  releaseDate?: Maybe<Scalars['DateTime']>;
  builderCompany?: Maybe<Scalars['String']>;
  unitsNumber?: Maybe<Scalars['Float']>;
  floorsNumber?: Maybe<Scalars['Float']>;
  documentation?: Maybe<CreateDocumentationInput>;
  technicalSpecifications?: Maybe<Scalars['String']>;
  projectApproval?: Maybe<Scalars['String']>;
  buildingPermit?: Maybe<Scalars['String']>;
  deedContact?: Maybe<CreateContactInput>;
  bankContact?: Maybe<CreateContactInput>;
  billing?: Maybe<CreateBillingInput>;
  legal?: Maybe<CreateLegalInput>;
  virtualTour?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  brochure?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['String']>;
  media?: Maybe<Array<Scalars['String']>>;
  company?: Maybe<Scalars['String']>;
  units?: Maybe<Array<Scalars['String']>>;
  models?: Maybe<Array<Scalars['String']>>;
  currentViewers?: Maybe<Array<Scalars['String']>>;
  commercialInfo?: Maybe<CommercialInformationInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
};

export type CreateRealEstateInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
  projects?: Maybe<Array<Scalars['String']>>;
};

export type CreateScheduleEventInput = {
  kind?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  available?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  client?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateTimelineInput = {
  isActive?: Maybe<Scalars['Boolean']>;
  client?: Maybe<Scalars['String']>;
  units?: Maybe<Array<Scalars['String']>>;
  company?: Maybe<Scalars['String']>;
  broker?: Maybe<Scalars['String']>;
  payment?: Maybe<Scalars['String']>;
  history?: Maybe<Array<HistoryInput>>;
  status?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  suggestedProjects?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateUnitInput = {
  name: Scalars['String'];
  sellPrice: Scalars['Float'];
  priceHistory?: Maybe<Array<PriceHistoryInput>>;
  available: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  floor: Scalars['Float'];
  variantCode?: Maybe<Scalars['Float']>;
  units?: Maybe<Array<Scalars['String']>>;
  client?: Maybe<Scalars['String']>;
  project: Scalars['String'];
  company: Scalars['String'];
  model?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateUserInput = {
  name: Scalars['String'];
  rut: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
};


export type Documentation = {
  __typename?: 'Documentation';
  reservationTemplate?: Maybe<Scalars['String']>;
  offerTemplate?: Maybe<Scalars['String']>;
  promiseTemplate?: Maybe<Scalars['String']>;
  deedTemplate?: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  _id: Scalars['ID'];
  url: Scalars['String'];
  key: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type FilterClientsInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  civilStatus?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<InterestInput>>;
  purchaseUse?: Maybe<Scalars['String']>;
  frontIdCard?: Maybe<Scalars['String']>;
  backIdCard?: Maybe<Scalars['String']>;
  socialSecurityQuotes?: Maybe<Array<Scalars['String']>>;
  liquidations?: Maybe<Array<Scalars['String']>>;
  validToBuy?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Scalars['String']>;
  liquidIncome?: Maybe<Scalars['Float']>;
  currentAccount?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  monthlySavingsCapacity?: Maybe<Scalars['Float']>;
  outstandingDebts?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type FilterModelsInput = {
  name?: Maybe<Scalars['String']>;
  typology?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  variants?: Maybe<Array<VariantInput>>;
  bathrooms?: Maybe<Scalars['Float']>;
  bedrooms?: Maybe<Scalars['Float']>;
  hasGarden?: Maybe<Scalars['Boolean']>;
  isStudio?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['String']>;
};

export type FloorMap = {
  __typename?: 'FloorMap';
  _id?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Float']>;
  floorsNumber?: Maybe<Scalars['Float']>;
  unitsNumber?: Maybe<Scalars['Float']>;
  units?: Maybe<Array<Unit>>;
  available?: Maybe<Scalars['Float']>;
  reserved?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type History = {
  __typename?: 'History';
  what?: Maybe<Scalars['String']>;
  when?: Maybe<Scalars['DateTime']>;
  extra?: Maybe<Scalars['JSONObject']>;
};

export type HistoryInput = {
  what?: Maybe<Scalars['String']>;
  when?: Maybe<Scalars['DateTime']>;
  extra?: Maybe<Scalars['JSONObject']>;
};

export type ICompany = {
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<File>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime: Scalars['Boolean'];
  kind: Scalars['String'];
  admin?: Maybe<User>;
  collaborators?: Maybe<Array<User>>;
  projects?: Maybe<Array<Project>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type Interest = {
  __typename?: 'Interest';
  name: Scalars['String'];
  location: Location;
};

export type InterestInput = {
  name?: Maybe<Scalars['String']>;
  location?: Maybe<CreateLocationInput>;
};


export type Legal = {
  __typename?: 'Legal';
  companySocialReason?: Maybe<Scalars['String']>;
  companyRut?: Maybe<Scalars['String']>;
  representativeName?: Maybe<Scalars['String']>;
  representativeRut?: Maybe<Scalars['String']>;
  representativeEmail?: Maybe<Scalars['String']>;
  representativeAddress?: Maybe<Scalars['String']>;
  representativeAddressOther?: Maybe<Scalars['String']>;
};

export type ListCompaniesInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
  projects?: Maybe<Array<Scalars['String']>>;
  _id?: Maybe<Scalars['String']>;
};

export type ListFilesInput = {
  _id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ListPaymentsInput = {
  _id?: Maybe<Scalars['String']>;
  buyOrder?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  storeCode?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  clientConfirmation?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  project?: Maybe<Scalars['String']>;
  timeline?: Maybe<Scalars['String']>;
  items?: Maybe<Array<PaymentCartItemInput>>;
  transaction?: Maybe<Scalars['JSONObject']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeferred?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ListProjectsInput = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  location?: Maybe<CreateLocationInput>;
  features?: Maybe<CreateProjectFeaturesInput>;
  amenities?: Maybe<Array<CreateAmenityInput>>;
  releaseDate?: Maybe<Scalars['DateTime']>;
  builderCompany?: Maybe<Scalars['String']>;
  unitsNumber?: Maybe<Scalars['Float']>;
  floorsNumber?: Maybe<Scalars['Float']>;
  documentation?: Maybe<CreateDocumentationInput>;
  technicalSpecifications?: Maybe<Scalars['String']>;
  projectApproval?: Maybe<Scalars['String']>;
  buildingPermit?: Maybe<Scalars['String']>;
  deedContact?: Maybe<CreateContactInput>;
  bankContact?: Maybe<CreateContactInput>;
  billing?: Maybe<CreateBillingInput>;
  legal?: Maybe<CreateLegalInput>;
  virtualTour?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  brochure?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['String']>;
  media?: Maybe<Array<Scalars['String']>>;
  company?: Maybe<Scalars['String']>;
  units?: Maybe<Array<Scalars['String']>>;
  models?: Maybe<Array<Scalars['String']>>;
  currentViewers?: Maybe<Array<Scalars['String']>>;
  commercialInfo?: Maybe<CommercialInformationInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['String']>;
};

export type ListScheduleEventsInput = {
  kind?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  available?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  client?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  _id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type ListSellers = {
  __typename?: 'ListSellers';
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
};

export type ListSellersUnits = {
  __typename?: 'ListSellersUnits';
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  soldUnitsByStatus?: Maybe<Array<SoldUnitsByStatus>>;
  sellerTotalSales?: Maybe<Scalars['Float']>;
};

export type ListTimelinesInput = {
  isActive?: Maybe<Scalars['Boolean']>;
  client?: Maybe<Scalars['String']>;
  units?: Maybe<Array<Scalars['String']>>;
  company?: Maybe<Scalars['String']>;
  broker?: Maybe<Scalars['String']>;
  payment?: Maybe<Scalars['String']>;
  history?: Maybe<Array<HistoryInput>>;
  status?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  suggestedProjects?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  _id?: Maybe<Scalars['String']>;
};

export type ListUnitsInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sellPrice?: Maybe<Scalars['Float']>;
  priceHistory?: Maybe<Array<PriceHistoryInput>>;
  available?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Float']>;
  variantCode?: Maybe<Scalars['Float']>;
  units?: Maybe<Array<Scalars['String']>>;
  client?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ListUserInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Array<Scalars['String']>>;
  avatar?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type Location = {
  __typename?: 'Location';
  type: Scalars['String'];
  coordinates: Array<Scalars['Float']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MinMax = {
  __typename?: 'MinMax';
  min: Scalars['Float'];
  max: Scalars['Float'];
};

export type MinMaxInput = {
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export type ModelChartStats = {
  __typename?: 'ModelChartStats';
  total?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser: User;
  updateUser: User;
  deleteUser: User;
  createCompany: Company;
  updateCompany: Company;
  addAdmin: Company;
  addCollaborator: Company;
  removeCollaborator: Company;
  deleteCompany: Company;
  createRealEstateCompany: RealEstateCompany;
  updateRealEstateCompany: RealEstateCompany;
  deleteRealEstateCompany: RealEstateCompany;
  createBrokerCompany: BrokerCompany;
  updateBrokerCompany: BrokerCompany;
  deleteBrokerCompany: BrokerCompany;
  addProject: BrokerCompany;
  removeProject: BrokerCompany;
  createProject: Project;
  updateProject: Project;
  deleteProject: Project;
  addModel: Project;
  removeModel: Project;
  changeCommisions: Project;
  addViewer: Project;
  removeViewer: Project;
  createUnit: Unit;
  updateUnit: Unit;
  uploadStock: Array<Unit>;
  deleteUnit: Unit;
  createUnitModel: UnitModel;
  updateUnitModel: UnitModel;
  deleteUnitModel: UnitModel;
  createClient: Client;
  updateClient: Client;
  deleteClient: Client;
  login: AccessToken;
  createPassword: User;
  createTimeline: Timeline;
  updateTimeline: Timeline;
  addTimelineHistory: Timeline;
  deleteTimeline: Timeline;
  addSuggestedProject: Timeline;
  removeSuggestedProject: Timeline;
  createPayment: Payment;
  updatePayment: Payment;
  deletePayment: Payment;
  createScheduleEvent: ScheduleEvent;
  updateScheduleEvent: ScheduleEvent;
  deleteScheduleEvent: ScheduleEvent;
  createFile: File;
  updateFile: File;
  deleteFile: File;
};


export type MutationCreateUserArgs = {
  payload: CreateUserInput;
};


export type MutationUpdateUserArgs = {
  payload: UpdateUserInput;
};


export type MutationDeleteUserArgs = {
  _id: Scalars['String'];
};


export type MutationCreateCompanyArgs = {
  payload: CreateCompanyInput;
};


export type MutationUpdateCompanyArgs = {
  payload: UpdateCompanyInput;
};


export type MutationAddAdminArgs = {
  payload: AddAdminInput;
};


export type MutationAddCollaboratorArgs = {
  payload: AddCollaboratorInput;
};


export type MutationRemoveCollaboratorArgs = {
  payload: RemoveCollaboratorInput;
};


export type MutationDeleteCompanyArgs = {
  _id: Scalars['String'];
};


export type MutationCreateRealEstateCompanyArgs = {
  payload: CreateRealEstateInput;
};


export type MutationUpdateRealEstateCompanyArgs = {
  payload: UpdateRealEstateInput;
};


export type MutationDeleteRealEstateCompanyArgs = {
  _id: Scalars['String'];
};


export type MutationCreateBrokerCompanyArgs = {
  payload: CreateBrokerInput;
};


export type MutationUpdateBrokerCompanyArgs = {
  payload: UpdateBrokerInput;
};


export type MutationDeleteBrokerCompanyArgs = {
  _id: Scalars['String'];
};


export type MutationAddProjectArgs = {
  payload: BrokerAddProjectInput;
};


export type MutationRemoveProjectArgs = {
  payload: BrokerAddProjectInput;
};


export type MutationCreateProjectArgs = {
  payload: CreateProjectInput;
};


export type MutationUpdateProjectArgs = {
  payload: UpdateProjectInput;
};


export type MutationDeleteProjectArgs = {
  _id: Scalars['String'];
};


export type MutationAddModelArgs = {
  payload: AddModelInput;
};


export type MutationRemoveModelArgs = {
  payload: RemoveModelInput;
};


export type MutationChangeCommisionsArgs = {
  payload: UpdateCommisionsInput;
};


export type MutationAddViewerArgs = {
  payload: AddViewerInput;
};


export type MutationRemoveViewerArgs = {
  payload: RemoveViewerInput;
};


export type MutationCreateUnitArgs = {
  payload: CreateUnitInput;
};


export type MutationUpdateUnitArgs = {
  payload: UpdateUnitInput;
};


export type MutationUploadStockArgs = {
  payload: UploadStockInput;
};


export type MutationDeleteUnitArgs = {
  _id: Scalars['String'];
};


export type MutationCreateUnitModelArgs = {
  payload: CreateModelInput;
};


export type MutationUpdateUnitModelArgs = {
  payload: UpdateModelInput;
};


export type MutationDeleteUnitModelArgs = {
  _id: Scalars['String'];
};


export type MutationCreateClientArgs = {
  payload: CreateClientInput;
};


export type MutationUpdateClientArgs = {
  payload: UpdateClientInput;
};


export type MutationDeleteClientArgs = {
  _id: Scalars['String'];
};


export type MutationLoginArgs = {
  payload: LoginInput;
};


export type MutationCreatePasswordArgs = {
  payload: ActivateUserInput;
};


export type MutationCreateTimelineArgs = {
  payload: CreateTimelineInput;
};


export type MutationUpdateTimelineArgs = {
  payload: UpdateTimelineInput;
};


export type MutationAddTimelineHistoryArgs = {
  payload: AddHistoryInput;
};


export type MutationDeleteTimelineArgs = {
  _id: Scalars['String'];
};


export type MutationAddSuggestedProjectArgs = {
  payload: AddSuggestedProjectInput;
};


export type MutationRemoveSuggestedProjectArgs = {
  payload: RemoveSuggestedProjectInput;
};


export type MutationCreatePaymentArgs = {
  payload: CreatePaymentInput;
};


export type MutationUpdatePaymentArgs = {
  payload: UpdatePaymentInput;
};


export type MutationDeletePaymentArgs = {
  _id: Scalars['String'];
};


export type MutationCreateScheduleEventArgs = {
  payload: CreateScheduleEventInput;
};


export type MutationUpdateScheduleEventArgs = {
  payload: UpdateScheduleEventInput;
};


export type MutationDeleteScheduleEventArgs = {
  _id: Scalars['String'];
};


export type MutationCreateFileArgs = {
  payload: CreateFileInput;
};


export type MutationUpdateFileArgs = {
  payload: UpdateFileInput;
};


export type MutationDeleteFileArgs = {
  _id: Scalars['String'];
};

export type Payment = {
  __typename?: 'Payment';
  _id: Scalars['ID'];
  buyOrder: Scalars['String'];
  sessionId: Scalars['String'];
  amount: Scalars['Float'];
  status?: Maybe<Scalars['String']>;
  storeCode: Scalars['String'];
  timeline?: Maybe<Timeline>;
  token: Scalars['String'];
  clientConfirmation: Scalars['Boolean'];
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  project: Project;
  items?: Maybe<Array<PaymentCartItem>>;
  transaction?: Maybe<Scalars['JSONObject']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeferred: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
};

export type PaymentCartItem = {
  __typename?: 'PaymentCartItem';
  units?: Maybe<Array<Scalars['JSONObject']>>;
  paymentBonusTerm?: Maybe<PaymentTermsBonus>;
  paymentPieTerm?: Maybe<PaymentTermsPie>;
  reservationAmount: Scalars['Float'];
  dayUF?: Maybe<Scalars['Float']>;
};

export type PaymentCartItemInput = {
  units?: Maybe<Array<Scalars['JSONObject']>>;
  paymentBonusTerm?: Maybe<PaymentTermBonusInput>;
  reservationAmount: Scalars['Float'];
  dayUF?: Maybe<Scalars['Float']>;
};

export type PaymentTermBonusInput = {
  percent?: Maybe<Scalars['Float']>;
  rules?: Maybe<PieRulesInput>;
};

export type PaymentTermPieInput = {
  title?: Maybe<Scalars['String']>;
  dues?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['DateTime']>;
  percent?: Maybe<Scalars['Float']>;
  bigDue?: Maybe<Scalars['Boolean']>;
  terms?: Maybe<Scalars['String']>;
  rules?: Maybe<PieRulesInput>;
};

export type PaymentTermsBonus = {
  __typename?: 'PaymentTermsBonus';
  percent?: Maybe<Scalars['Float']>;
  rules?: Maybe<PieRules>;
};

export type PaymentTermsPie = {
  __typename?: 'PaymentTermsPie';
  title?: Maybe<Scalars['String']>;
  dues?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['DateTime']>;
  percent?: Maybe<Scalars['Float']>;
  bigDue?: Maybe<Scalars['Boolean']>;
  terms?: Maybe<Scalars['String']>;
  rules?: Maybe<PieRules>;
};

export type PieRules = {
  __typename?: 'PieRules';
  includeUnitTypes: Array<Scalars['String']>;
  units: Array<Unit>;
  models: Array<UnitModel>;
};

export type PieRulesInput = {
  includeUnitTypes?: Maybe<Array<Scalars['String']>>;
  units?: Maybe<Array<Scalars['String']>>;
  models?: Maybe<Array<Scalars['String']>>;
};

export type PriceHistory = {
  __typename?: 'PriceHistory';
  date: Scalars['DateTime'];
  price: Scalars['Float'];
  changedBy: User;
};

export type PriceHistoryInput = {
  date?: Maybe<Scalars['DateTime']>;
  price?: Maybe<Scalars['Float']>;
  changedBy?: Maybe<Scalars['String']>;
};

export type Process = {
  __typename?: 'Process';
  _id?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Scalars['String']>;
  history?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type Project = {
  __typename?: 'Project';
  _id: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  stage: Scalars['String'];
  address: Scalars['String'];
  location?: Maybe<Location>;
  features?: Maybe<ProjectFeatures>;
  amenities?: Maybe<Array<Amenity>>;
  releaseDate: Scalars['DateTime'];
  builderCompany?: Maybe<Scalars['String']>;
  unitsNumber?: Maybe<Scalars['Float']>;
  floorsNumber?: Maybe<Scalars['Float']>;
  documentation?: Maybe<Documentation>;
  technicalSpecifications?: Maybe<File>;
  projectApproval?: Maybe<File>;
  buildingPermit?: Maybe<File>;
  deedContact?: Maybe<Contact>;
  bankContact?: Maybe<Contact>;
  billing?: Maybe<Billing>;
  legal?: Maybe<Legal>;
  virtualTour?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  brochure?: Maybe<File>;
  cover?: Maybe<File>;
  media?: Maybe<Array<File>>;
  company: RealEstateCompany;
  units: Array<Unit>;
  models: Array<UnitModel>;
  currentViewers: Array<User>;
  commercialInfo?: Maybe<CommercialInfo>;
  stats?: Maybe<ProjectStats>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
};

export type ProjectChartStats = {
  __typename?: 'ProjectChartStats';
  total?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type ProjectFeatures = {
  __typename?: 'ProjectFeatures';
  bathrooms: MinMax;
  bedrooms: MinMax;
  usefulSurface: MinMax;
  totalSurface: MinMax;
};

export type ProjectStats = {
  __typename?: 'ProjectStats';
  _id?: Maybe<Scalars['String']>;
  minLP?: Maybe<Scalars['Float']>;
  maxLP?: Maybe<Scalars['Float']>;
  minSP?: Maybe<Scalars['Float']>;
  maxSP?: Maybe<Scalars['Float']>;
  minBP?: Maybe<Scalars['Float']>;
  maxBP?: Maybe<Scalars['Float']>;
  available?: Maybe<Scalars['Float']>;
  reserved?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  hello: Scalars['String'];
  user: User;
  users: Array<User>;
  company: Company;
  companies: Array<Company>;
  realEstate: RealEstateCompany;
  realEstates: Array<RealEstateCompany>;
  broker: BrokerCompany;
  brokers: Array<BrokerCompany>;
  project: Project;
  projects: Array<Project>;
  projectsForList: Array<Project>;
  getUnitsByFloor: Array<FloorMap>;
  unit: Unit;
  units: Array<Unit>;
  projectStats: ProjectStats;
  realEstateStats: Array<RealEstateStats>;
  projectChartStats: Array<ProjectChartStats>;
  modelChartStats: Array<ModelChartStats>;
  unitsByFloorStats: Array<UnitsByFloorChartStats>;
  unitModel: UnitModel;
  unitModels: Array<UnitModel>;
  client: Client;
  clients: Array<Client>;
  timeline: Timeline;
  timelineByClient?: Maybe<Timeline>;
  timelineByUnit?: Maybe<Timeline>;
  timelinesBySeller?: Maybe<Array<SellerBoard>>;
  brokerFunnel?: Maybe<Array<SellerSalesStatus>>;
  brokerProjectSales?: Maybe<Array<BrokerProjectSales>>;
  sellerProgressProcesses?: Maybe<Array<SellerProgressProcesses>>;
  timelines: Array<Timeline>;
  payment: Payment;
  payments: Array<Payment>;
  brokerSellerPayments: Array<BrokerSellerPayments>;
  scheduleEvent: ScheduleEvent;
  scheduleEventByCode: ScheduleEvent;
  scheduleEvents: Array<ScheduleEvent>;
  file: File;
  files: Array<File>;
};


export type QueryUserArgs = {
  _id: Scalars['String'];
};


export type QueryUsersArgs = {
  filters?: Maybe<ListUserInput>;
};


export type QueryCompanyArgs = {
  _id: Scalars['String'];
};


export type QueryCompaniesArgs = {
  filters?: Maybe<ListCompaniesInput>;
};


export type QueryRealEstateArgs = {
  _id: Scalars['String'];
};


export type QueryRealEstatesArgs = {
  filters?: Maybe<RealEstateFilterInput>;
};


export type QueryBrokerArgs = {
  _id: Scalars['String'];
};


export type QueryBrokersArgs = {
  filters?: Maybe<BrokerFiltersInput>;
};


export type QueryProjectArgs = {
  _id: Scalars['String'];
};


export type QueryProjectsArgs = {
  filters?: Maybe<ListProjectsInput>;
};


export type QueryProjectsForListArgs = {
  filters?: Maybe<ListProjectsInput>;
};


export type QueryGetUnitsByFloorArgs = {
  projectId: Scalars['String'];
};


export type QueryUnitArgs = {
  _id: Scalars['String'];
};


export type QueryUnitsArgs = {
  filters?: Maybe<ListUnitsInput>;
};


export type QueryProjectStatsArgs = {
  _id: Scalars['String'];
};


export type QueryRealEstateStatsArgs = {
  _id: Scalars['String'];
};


export type QueryProjectChartStatsArgs = {
  _id: Scalars['String'];
};


export type QueryModelChartStatsArgs = {
  _id: Scalars['String'];
};


export type QueryUnitsByFloorStatsArgs = {
  type: Scalars['String'];
  _id: Scalars['String'];
};


export type QueryUnitModelArgs = {
  _id: Scalars['String'];
};


export type QueryUnitModelsArgs = {
  filters?: Maybe<FilterModelsInput>;
};


export type QueryClientArgs = {
  _id: Scalars['String'];
};


export type QueryClientsArgs = {
  filters?: Maybe<FilterClientsInput>;
};


export type QueryTimelineArgs = {
  _id: Scalars['String'];
};


export type QueryTimelineByClientArgs = {
  clientId: Scalars['String'];
};


export type QueryTimelineByUnitArgs = {
  unitId: Scalars['String'];
};


export type QueryTimelinesBySellerArgs = {
  sellerId: Scalars['String'];
};


export type QueryBrokerFunnelArgs = {
  brokerId: Scalars['String'];
};


export type QueryBrokerProjectSalesArgs = {
  brokerId: Scalars['String'];
};


export type QuerySellerProgressProcessesArgs = {
  sellerId: Scalars['String'];
};


export type QueryTimelinesArgs = {
  filters?: Maybe<ListTimelinesInput>;
};


export type QueryPaymentArgs = {
  _id: Scalars['String'];
};


export type QueryPaymentsArgs = {
  filters?: Maybe<ListPaymentsInput>;
};


export type QueryBrokerSellerPaymentsArgs = {
  projectId: Scalars['String'];
  companyId: Scalars['String'];
};


export type QueryScheduleEventArgs = {
  _id: Scalars['String'];
};


export type QueryScheduleEventByCodeArgs = {
  code: Scalars['String'];
};


export type QueryScheduleEventsArgs = {
  filters?: Maybe<ListScheduleEventsInput>;
};


export type QueryFileArgs = {
  _id: Scalars['String'];
};


export type QueryFilesArgs = {
  filters?: Maybe<ListFilesInput>;
};

export type RealEstateCompany = ICompany & {
  __typename?: 'RealEstateCompany';
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<File>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime: Scalars['Boolean'];
  kind: Scalars['String'];
  admin?: Maybe<User>;
  collaborators?: Maybe<Array<User>>;
  projects?: Maybe<Array<Project>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type RealEstateFilterInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
  projects?: Maybe<Array<Scalars['String']>>;
  _id?: Maybe<Scalars['String']>;
};

export type RealEstateStats = {
  __typename?: 'RealEstateStats';
  company?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type RemoveCollaboratorInput = {
  collaborator?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
};

export type RemoveModelInput = {
  model?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
};

export type RemoveSuggestedProjectInput = {
  timeline?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
};

export type RemoveViewerInput = {
  viewer?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
};

export type ScheduleEvent = {
  __typename?: 'ScheduleEvent';
  _id: Scalars['ID'];
  code: Scalars['String'];
  kind: Scalars['String'];
  description: Scalars['String'];
  available: Scalars['Boolean'];
  status: Scalars['String'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  client: Client;
  seller: User;
  company: Company;
  project?: Maybe<Project>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type SellerBoard = {
  __typename?: 'SellerBoard';
  funnel?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
  timelines?: Maybe<Array<Process>>;
};

export type SellerProgressProcesses = {
  __typename?: 'SellerProgressProcesses';
  timelineId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  projectCompanyName?: Maybe<Scalars['String']>;
  projectCoverUrl?: Maybe<Scalars['String']>;
  units?: Maybe<Array<Units>>;
  clientName: Scalars['String'];
  clientEmail?: Maybe<Scalars['String']>;
  timelineStatus: Scalars['String'];
  timelineStatusDate?: Maybe<Scalars['DateTime']>;
};

export type SellerSalesStatus = {
  __typename?: 'SellerSalesStatus';
  subLabels?: Maybe<Array<Scalars['String']>>;
  values?: Maybe<Array<Scalars['Float']>>;
  status?: Maybe<Scalars['String']>;
};

export type SoldUnitsByStatus = {
  __typename?: 'SoldUnitsByStatus';
  status?: Maybe<Scalars['String']>;
  salesStatusValueUF?: Maybe<Scalars['Float']>;
  salesStatusValueCLP?: Maybe<Scalars['Float']>;
  statusTotal?: Maybe<Scalars['Float']>;
  units?: Maybe<Array<Unit>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  projectAdded: Project;
  viewerAdded: Project;
  viewerRemoved: Project;
  unitLocked: Unit;
  paymentStatusChanged: Payment;
};


export type SubscriptionViewerAddedArgs = {
  projectId: Scalars['String'];
};


export type SubscriptionViewerRemovedArgs = {
  projectId: Scalars['String'];
};


export type SubscriptionUnitLockedArgs = {
  unitId: Scalars['String'];
};


export type SubscriptionPaymentStatusChangedArgs = {
  paymentToken: Scalars['String'];
};

export type Timeline = {
  __typename?: 'Timeline';
  _id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  client: Client;
  suggestedProjects?: Maybe<Array<Project>>;
  project?: Maybe<Project>;
  units?: Maybe<Array<Unit>>;
  company?: Maybe<Company>;
  payment?: Maybe<Payment>;
  seller: User;
  history?: Maybe<Array<History>>;
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type Unit = {
  __typename?: 'Unit';
  _id: Scalars['ID'];
  name: Scalars['String'];
  sellPrice: Scalars['Float'];
  priceHistory?: Maybe<Array<PriceHistory>>;
  available: Scalars['Boolean'];
  description: Scalars['String'];
  type: Scalars['String'];
  status: Scalars['String'];
  floor: Scalars['Float'];
  variantCode?: Maybe<Scalars['Float']>;
  units: Array<Unit>;
  project: Scalars['String'];
  company: Scalars['String'];
  client: User;
  model?: Maybe<UnitModel>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type UnitChart = {
  __typename?: 'UnitChart';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  available?: Maybe<Scalars['Boolean']>;
  sellPrice?: Maybe<Scalars['Float']>;
  variantCode?: Maybe<Scalars['Float']>;
  model?: Maybe<UnitModel>;
  client?: Maybe<Client>;
  timeline?: Maybe<Timeline>;
  image?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type UnitModel = {
  __typename?: 'UnitModel';
  _id: Scalars['ID'];
  name: Scalars['String'];
  typology: Scalars['String'];
  image: File;
  project: Scalars['String'];
  variants?: Maybe<Array<Variant>>;
  bathrooms: Scalars['Float'];
  bedrooms: Scalars['Float'];
  hasGarden: Scalars['Boolean'];
  isStudio: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type UnitStockInput = {
  description?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Float']>;
  variantCode?: Maybe<Scalars['Float']>;
  sellPrice?: Maybe<Scalars['Float']>;
  priceHistory?: Maybe<Array<PriceHistoryInput>>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Units = {
  __typename?: 'Units';
  unitName?: Maybe<Scalars['String']>;
  unitSellPrice?: Maybe<Scalars['Float']>;
  unitModelImage?: Maybe<Scalars['String']>;
};

export type UnitsByFloorChartStats = {
  __typename?: 'UnitsByFloorChartStats';
  available: Scalars['Float'];
  reserved: Scalars['Float'];
  total: Scalars['Float'];
  floor: Scalars['Float'];
  units: Array<UnitChart>;
};

export type UpdateBrokerInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
  projects?: Maybe<Array<Scalars['String']>>;
  _id: Scalars['String'];
};

export type UpdateClientInput = {
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  civilStatus?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<InterestInput>>;
  purchaseUse?: Maybe<Scalars['String']>;
  frontIdCard?: Maybe<Scalars['String']>;
  backIdCard?: Maybe<Scalars['String']>;
  socialSecurityQuotes?: Maybe<Array<Scalars['String']>>;
  liquidations?: Maybe<Array<Scalars['String']>>;
  validToBuy?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Scalars['String']>;
  liquidIncome?: Maybe<Scalars['Float']>;
  currentAccount?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  monthlySavingsCapacity?: Maybe<Scalars['Float']>;
  outstandingDebts?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateCommisionsInput = {
  projectId?: Maybe<Scalars['String']>;
  realEstateFee?: Maybe<Scalars['Float']>;
  brokerFee?: Maybe<Scalars['Float']>;
};

export type UpdateCompanyInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
  projects?: Maybe<Array<Scalars['String']>>;
  _id: Scalars['String'];
};

export type UpdateFileInput = {
  _id: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateModelInput = {
  name?: Maybe<Scalars['String']>;
  typology?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  variants?: Maybe<Array<VariantInput>>;
  bathrooms?: Maybe<Scalars['Float']>;
  bedrooms?: Maybe<Scalars['Float']>;
  hasGarden?: Maybe<Scalars['Boolean']>;
  isStudio?: Maybe<Scalars['Boolean']>;
  _id: Scalars['String'];
};

export type UpdatePaymentInput = {
  _id: Scalars['String'];
  buyOrder?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  storeCode?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  clientConfirmation?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  project?: Maybe<Scalars['String']>;
  timeline?: Maybe<Scalars['String']>;
  items?: Maybe<Array<PaymentCartItemInput>>;
  transaction?: Maybe<Scalars['JSONObject']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeferred?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateProjectInput = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  location?: Maybe<CreateLocationInput>;
  features?: Maybe<CreateProjectFeaturesInput>;
  amenities?: Maybe<Array<CreateAmenityInput>>;
  releaseDate?: Maybe<Scalars['DateTime']>;
  builderCompany?: Maybe<Scalars['String']>;
  unitsNumber?: Maybe<Scalars['Float']>;
  floorsNumber?: Maybe<Scalars['Float']>;
  documentation?: Maybe<CreateDocumentationInput>;
  technicalSpecifications?: Maybe<Scalars['String']>;
  projectApproval?: Maybe<Scalars['String']>;
  buildingPermit?: Maybe<Scalars['String']>;
  deedContact?: Maybe<CreateContactInput>;
  bankContact?: Maybe<CreateContactInput>;
  billing?: Maybe<CreateBillingInput>;
  legal?: Maybe<CreateLegalInput>;
  virtualTour?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  brochure?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['String']>;
  media?: Maybe<Array<Scalars['String']>>;
  company?: Maybe<Scalars['String']>;
  units?: Maybe<Array<Scalars['String']>>;
  models?: Maybe<Array<Scalars['String']>>;
  currentViewers?: Maybe<Array<Scalars['String']>>;
  commercialInfo?: Maybe<CommercialInformationInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  _id: Scalars['String'];
};

export type UpdateRealEstateInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
  projects?: Maybe<Array<Scalars['String']>>;
  _id: Scalars['String'];
};

export type UpdateScheduleEventInput = {
  kind?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  available?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  client?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  _id: Scalars['String'];
};

export type UpdateTimelineInput = {
  isActive?: Maybe<Scalars['Boolean']>;
  client?: Maybe<Scalars['String']>;
  units?: Maybe<Array<Scalars['String']>>;
  company?: Maybe<Scalars['String']>;
  broker?: Maybe<Scalars['String']>;
  payment?: Maybe<Scalars['String']>;
  history?: Maybe<Array<HistoryInput>>;
  status?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  suggestedProjects?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  _id: Scalars['String'];
};

export type UpdateUnitInput = {
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  sellPrice?: Maybe<Scalars['Float']>;
  priceHistory?: Maybe<Array<PriceHistoryInput>>;
  available?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Float']>;
  variantCode?: Maybe<Scalars['Float']>;
  units?: Maybe<Array<Scalars['String']>>;
  client?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  avatar?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UploadStockInput = {
  projectId: Scalars['String'];
  uploadUnits?: Maybe<Array<UnitStockInput>>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  name: Scalars['String'];
  rut: Scalars['String'];
  email: Scalars['String'];
  isFirstTime: Scalars['Boolean'];
  emailVerified: Scalars['Boolean'];
  verificationToken?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  avatar?: Maybe<File>;
  company?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type Variant = {
  __typename?: 'Variant';
  code: Scalars['Float'];
  orientation: Scalars['String'];
  terraceArea: Scalars['Float'];
  totalArea: Scalars['Float'];
  usefulArea: Scalars['Float'];
  gardenArea: Scalars['Float'];
};

export type VariantInput = {
  code: Scalars['Float'];
  orientation: Scalars['String'];
  terraceArea: Scalars['Float'];
  totalArea: Scalars['Float'];
  usefulArea: Scalars['Float'];
  gardenArea: Scalars['Float'];
};

export type CompaniesForListQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesForListQuery = (
  { __typename?: 'Query' }
  & { companies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'website' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { logo?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'url'>
    )> }
  )> }
);

export type GetCompanyByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCompanyByIdQuery = (
  { __typename?: 'Query' }
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'isFirstTime' | 'kind' | 'rut' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { admin?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'name' | 'rut' | 'email' | 'isFirstTime' | 'emailVerified'>
    )>, collaborators?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'rut' | 'roles' | 'email' | 'phone' | 'position'>
    )>>, projects?: Maybe<Array<(
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'name' | 'builderCompany'>
    )>>, logo?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'url'>
    )> }
  ) }
);

export type GetCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompaniesQuery = (
  { __typename?: 'Query' }
  & { companies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'isFirstTime' | 'kind' | 'rut' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { admin?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'name' | 'rut' | 'email' | 'emailVerified' | 'isFirstTime'>
    )>, collaborators?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'rut' | 'roles' | 'email' | 'phone' | 'position'>
    )>>, logo?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'url'>
    )> }
  )> }
);

export type AddAdminMutationVariables = Exact<{
  data: AddAdminInput;
}>;


export type AddAdminMutation = (
  { __typename?: 'Mutation' }
  & { addAdmin: (
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'email' | 'isFirstTime' | 'kind' | 'createdAt' | 'updatedAt' | 'isDeleted'>
  ) }
);

export type AddCollaboratorMutationVariables = Exact<{
  data: AddCollaboratorInput;
}>;


export type AddCollaboratorMutation = (
  { __typename?: 'Mutation' }
  & { addCollaborator: (
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'isFirstTime' | 'kind' | 'createdAt' | 'updatedAt' | 'isDeleted'>
  ) }
);

export type RemoveCollaboratorMutationVariables = Exact<{
  data: RemoveCollaboratorInput;
}>;


export type RemoveCollaboratorMutation = (
  { __typename?: 'Mutation' }
  & { removeCollaborator: (
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'isFirstTime' | 'kind' | 'createdAt' | 'updatedAt' | 'isDeleted'>
  ) }
);

export type CreateCompanyMutationVariables = Exact<{
  company: CreateCompanyInput;
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createCompany: (
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'isFirstTime' | 'kind' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { logo?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'url'>
    )>, admin?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, '_id'>
    )>, collaborators?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'roles'>
    )>>, projects?: Maybe<Array<(
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'name'>
    )>> }
  ) }
);

export type UpdateCompanyMutationVariables = Exact<{
  company: UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompany: (
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'bank' | 'accountType' | 'accountNumber' | 'emailBank' | 'isFirstTime' | 'kind' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { logo?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'url'>
    )>, collaborators?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'position'>
    )>>, projects?: Maybe<Array<(
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'name'>
    )>> }
  ) }
);

export type CreatePasswordMutationVariables = Exact<{
  payload: ActivateUserInput;
}>;


export type CreatePasswordMutation = (
  { __typename?: 'Mutation' }
  & { createPassword: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'name' | 'email' | 'emailVerified' | 'roles' | 'createdAt' | 'updatedAt' | 'isDeleted'>
  ) }
);

export type LoginMutationVariables = Exact<{
  payload: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AccessToken' }
    & Pick<AccessToken, 'access_token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'email' | 'rut' | 'phone' | 'isFirstTime' | 'roles'>
      & { avatar?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'url'>
      )> }
    ), company: (
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name' | 'kind' | 'isFirstTime'>
      & { logo?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'url'>
      )> }
    ) }
  ) }
);

export type CreateUserMutationVariables = Exact<{
  user: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'name' | 'rut' | 'email' | 'emailVerified' | 'verificationToken' | 'position' | 'phone' | 'roles' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { avatar?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'url'>
    )> }
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  user: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'name' | 'rut' | 'email' | 'emailVerified' | 'isFirstTime' | 'verificationToken' | 'position' | 'phone' | 'roles' | 'company' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { avatar?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'url'>
    )> }
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'name' | 'email' | 'createdAt' | 'updatedAt' | 'isDeleted'>
  ) }
);

export type GetBrokersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBrokersQuery = (
  { __typename?: 'Query' }
  & { brokers: Array<(
    { __typename?: 'BrokerCompany' }
    & Pick<BrokerCompany, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'isFirstTime' | 'kind' | 'rut' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { collaborators?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'rut' | 'roles' | 'email' | 'phone' | 'position' | 'emailVerified'>
    )>>, projects?: Maybe<Array<(
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'name' | 'builderCompany'>
    )>>, logo?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )> }
  )> }
);

export type GetBrokerByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBrokerByIdQuery = (
  { __typename?: 'Query' }
  & { broker: (
    { __typename?: 'BrokerCompany' }
    & Pick<BrokerCompany, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'bank' | 'accountType' | 'accountNumber' | 'emailBank' | 'isFirstTime' | 'kind' | 'rut' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { collaborators?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'rut' | 'roles' | 'email' | 'phone' | 'position' | 'emailVerified'>
    )>>, projects?: Maybe<Array<(
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'name' | 'builderCompany'>
    )>>, logo?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )> }
  ) }
);

export type CreateBrokerMutationVariables = Exact<{
  broker: CreateBrokerInput;
}>;


export type CreateBrokerMutation = (
  { __typename?: 'Mutation' }
  & { createBrokerCompany: (
    { __typename?: 'BrokerCompany' }
    & Pick<BrokerCompany, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'isFirstTime' | 'kind' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { logo?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, collaborators?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'roles' | 'emailVerified'>
    )>> }
  ) }
);

export type UpdateBrokerMutationVariables = Exact<{
  broker: UpdateBrokerInput;
}>;


export type UpdateBrokerMutation = (
  { __typename?: 'Mutation' }
  & { updateBrokerCompany: (
    { __typename?: 'BrokerCompany' }
    & Pick<BrokerCompany, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'bank' | 'accountType' | 'accountNumber' | 'emailBank' | 'isFirstTime' | 'kind' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { logo?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, collaborators?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'position' | 'emailVerified'>
    )>>, projects?: Maybe<Array<(
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'name'>
    )>> }
  ) }
);

export type AddProjectToPortfolioMutationVariables = Exact<{
  payload: BrokerAddProjectInput;
}>;


export type AddProjectToPortfolioMutation = (
  { __typename?: 'Mutation' }
  & { addProject: (
    { __typename?: 'BrokerCompany' }
    & Pick<BrokerCompany, '_id' | 'name'>
  ) }
);

export type RemoveProjectFromPortfolioMutationVariables = Exact<{
  payload: BrokerAddProjectInput;
}>;


export type RemoveProjectFromPortfolioMutation = (
  { __typename?: 'Mutation' }
  & { removeProject: (
    { __typename?: 'BrokerCompany' }
    & Pick<BrokerCompany, '_id' | 'name'>
  ) }
);

export type GetClientsQueryVariables = Exact<{
  filters?: Maybe<FilterClientsInput>;
}>;


export type GetClientsQuery = (
  { __typename?: 'Query' }
  & { clients: Array<(
    { __typename?: 'Client' }
    & Pick<Client, '_id' | 'name' | 'email' | 'rut' | 'phone' | 'nationality' | 'address' | 'profession' | 'civilStatus' | 'purchaseUse' | 'kind' | 'liquidIncome' | 'currentAccount' | 'bank' | 'status' | 'monthlySavingsCapacity' | 'outstandingDebts' | 'comments' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { interests?: Maybe<Array<(
      { __typename?: 'Interest' }
      & Pick<Interest, 'name'>
      & { location: (
        { __typename?: 'Location' }
        & Pick<Location, 'coordinates'>
      ) }
    )>>, assignedTo?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name'>
      & { avatar?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'url'>
      )> }
    )>, frontIdCard?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, backIdCard?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, socialSecurityQuotes: Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, liquidations: Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )> }
  )> }
);

export type GetClientByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetClientByIdQuery = (
  { __typename?: 'Query' }
  & { client: (
    { __typename?: 'Client' }
    & Pick<Client, '_id' | 'name' | 'email' | 'rut' | 'phone' | 'nationality' | 'address' | 'profession' | 'civilStatus' | 'purchaseUse' | 'kind' | 'liquidIncome' | 'currentAccount' | 'bank' | 'status' | 'monthlySavingsCapacity' | 'outstandingDebts' | 'comments' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { interests?: Maybe<Array<(
      { __typename?: 'Interest' }
      & Pick<Interest, 'name'>
      & { location: (
        { __typename?: 'Location' }
        & Pick<Location, 'coordinates'>
      ) }
    )>>, assignedTo?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name'>
      & { avatar?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'url'>
      )> }
    )>, frontIdCard?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, backIdCard?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, socialSecurityQuotes: Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, liquidations: Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )> }
  ) }
);

export type CreateClientMutationVariables = Exact<{
  client: CreateClientInput;
}>;


export type CreateClientMutation = (
  { __typename?: 'Mutation' }
  & { createClient: (
    { __typename?: 'Client' }
    & Pick<Client, '_id' | 'name' | 'email' | 'rut' | 'phone' | 'nationality' | 'address' | 'profession' | 'civilStatus' | 'purchaseUse' | 'kind' | 'liquidIncome' | 'currentAccount' | 'bank' | 'monthlySavingsCapacity' | 'outstandingDebts' | 'comments' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { interests?: Maybe<Array<(
      { __typename?: 'Interest' }
      & Pick<Interest, 'name'>
      & { location: (
        { __typename?: 'Location' }
        & Pick<Location, 'coordinates'>
      ) }
    )>>, frontIdCard?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, backIdCard?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, socialSecurityQuotes: Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, liquidations: Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )> }
  ) }
);

export type UpdateClientMutationVariables = Exact<{
  client: UpdateClientInput;
}>;


export type UpdateClientMutation = (
  { __typename?: 'Mutation' }
  & { updateClient: (
    { __typename?: 'Client' }
    & Pick<Client, '_id' | 'name' | 'email' | 'rut' | 'phone' | 'nationality' | 'address' | 'profession' | 'civilStatus' | 'purchaseUse' | 'kind' | 'liquidIncome' | 'currentAccount' | 'bank' | 'monthlySavingsCapacity' | 'outstandingDebts' | 'comments' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { interests?: Maybe<Array<(
      { __typename?: 'Interest' }
      & Pick<Interest, 'name'>
      & { location: (
        { __typename?: 'Location' }
        & Pick<Location, 'coordinates'>
      ) }
    )>>, frontIdCard?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, backIdCard?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, socialSecurityQuotes: Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, liquidations: Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )> }
  ) }
);

export type GetPaymentQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPaymentQuery = (
  { __typename?: 'Query' }
  & { payment: (
    { __typename?: 'Payment' }
    & Pick<Payment, '_id' | 'buyOrder' | 'sessionId' | 'amount' | 'isDeferred' | 'status' | 'storeCode' | 'token' | 'start' | 'end' | 'transaction'>
    & { timeline?: Maybe<(
      { __typename?: 'Timeline' }
      & { client: (
        { __typename?: 'Client' }
        & Pick<Client, '_id' | 'name' | 'email'>
      ), seller: (
        { __typename?: 'User' }
        & Pick<User, '_id' | 'name' | 'email'>
      ) }
    )>, project: (
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'name' | 'address' | 'stage'>
      & { location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'coordinates'>
      )>, company: (
        { __typename?: 'RealEstateCompany' }
        & Pick<RealEstateCompany, '_id' | 'name'>
      ), cover?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'url'>
      )> }
    ), items?: Maybe<Array<(
      { __typename?: 'PaymentCartItem' }
      & Pick<PaymentCartItem, 'units' | 'reservationAmount' | 'dayUF'>
      & { paymentBonusTerm?: Maybe<(
        { __typename?: 'PaymentTermsBonus' }
        & Pick<PaymentTermsBonus, 'percent'>
      )> }
    )>> }
  ) }
);

export type PaymentStatusChangedSubscriptionVariables = Exact<{
  paymentToken: Scalars['String'];
}>;


export type PaymentStatusChangedSubscription = (
  { __typename?: 'Subscription' }
  & { paymentStatusChanged: (
    { __typename?: 'Payment' }
    & Pick<Payment, '_id' | 'buyOrder' | 'sessionId' | 'amount' | 'status' | 'storeCode' | 'token' | 'start' | 'isDeferred' | 'transaction' | 'createdAt' | 'updatedAt' | 'isDeleted'>
  ) }
);

export type GetBrokerSellerPaymentsQueryVariables = Exact<{
  companyId: Scalars['String'];
  projectId: Scalars['String'];
}>;


export type GetBrokerSellerPaymentsQuery = (
  { __typename?: 'Query' }
  & { brokerSellerPayments: Array<(
    { __typename?: 'BrokerSellerPayments' }
    & Pick<BrokerSellerPayments, 'projectId' | 'projectName' | 'projectCoverURL' | 'projectCompanyName' | 'promisePaymentPercent' | 'deedPaymentPercent' | 'brokerFee' | 'totalProjectSales'>
    & { sellers?: Maybe<Array<(
      { __typename?: 'ListSellersUnits' }
      & Pick<ListSellersUnits, 'name' | 'avatar' | 'sellerTotalSales'>
      & { soldUnitsByStatus?: Maybe<Array<(
        { __typename?: 'SoldUnitsByStatus' }
        & Pick<SoldUnitsByStatus, 'status' | 'salesStatusValueUF' | 'salesStatusValueCLP' | 'statusTotal'>
        & { units?: Maybe<Array<(
          { __typename?: 'Unit' }
          & Pick<Unit, '_id' | 'isDeleted' | 'status' | 'type' | 'available' | 'name' | 'sellPrice' | 'floor'>
        )>> }
      )>> }
    )>> }
  )> }
);

export type GetUserProfileQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type GetUserProfileQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'email' | 'rut' | 'phone'>
    & { avatar?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )> }
  ) }
);

export type GetProjectQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetProjectQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & Pick<Project, '_id' | 'name' | 'stage' | 'address' | 'releaseDate' | 'builderCompany' | 'unitsNumber' | 'floorsNumber' | 'video' | 'virtualTour' | 'createdAt' | 'updatedAt' | 'isPublished' | 'isDeleted'>
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'coordinates'>
    )>, amenities?: Maybe<Array<(
      { __typename?: 'Amenity' }
      & Pick<Amenity, 'name' | 'icon'>
    )>>, deedContact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'name' | 'email' | 'phone'>
    )>, bankContact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'name' | 'email' | 'phone'>
    )>, brochure?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, cover?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, billing?: Maybe<(
      { __typename?: 'Billing' }
      & Pick<Billing, 'billingAddress' | 'billingEmail' | 'billingRut' | 'economicActivity' | 'socialReason'>
    )>, legal?: Maybe<(
      { __typename?: 'Legal' }
      & Pick<Legal, 'companySocialReason' | 'companyRut' | 'representativeName' | 'representativeRut' | 'representativeEmail' | 'representativeAddress' | 'representativeAddressOther'>
    )>, media?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>>, units: Array<(
      { __typename?: 'Unit' }
      & Pick<Unit, '_id' | 'name' | 'sellPrice' | 'variantCode' | 'available' | 'type' | 'floor'>
      & { model?: Maybe<(
        { __typename?: 'UnitModel' }
        & Pick<UnitModel, '_id' | 'name' | 'typology' | 'bedrooms' | 'bathrooms'>
        & { variants?: Maybe<Array<(
          { __typename?: 'Variant' }
          & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
        )>>, image: (
          { __typename?: 'File' }
          & Pick<File, 'url'>
        ) }
      )>, units: Array<(
        { __typename?: 'Unit' }
        & Pick<Unit, '_id' | 'name' | 'type'>
      )> }
    )>, models: Array<(
      { __typename?: 'UnitModel' }
      & Pick<UnitModel, '_id' | 'name' | 'typology' | 'bathrooms' | 'bedrooms' | 'isStudio' | 'hasGarden'>
      & { variants?: Maybe<Array<(
        { __typename?: 'Variant' }
        & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
      )>>, image: (
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      ) }
    )>, company: (
      { __typename?: 'RealEstateCompany' }
      & Pick<RealEstateCompany, '_id' | 'name'>
    ), commercialInfo?: Maybe<(
      { __typename?: 'CommercialInfo' }
      & Pick<CommercialInfo, 'commerceCode' | 'reservationFee' | 'promisePaymentPercent' | 'deedPaymentPercent' | 'realEstateFee' | 'brokerFee' | 'reservationPolicy'>
      & { paymentBonusTerms?: Maybe<Array<(
        { __typename?: 'PaymentTermsBonus' }
        & Pick<PaymentTermsBonus, 'percent'>
      )>>, paymentPieTerms?: Maybe<Array<(
        { __typename?: 'PaymentTermsPie' }
        & Pick<PaymentTermsPie, 'title' | 'dues' | 'endDate' | 'percent' | 'bigDue' | 'terms'>
      )>> }
    )>, technicalSpecifications?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, projectApproval?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, buildingPermit?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, documentation?: Maybe<(
      { __typename?: 'Documentation' }
      & Pick<Documentation, 'reservationTemplate' | 'promiseTemplate' | 'deedTemplate' | 'offerTemplate'>
    )>, currentViewers: Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'position' | 'roles' | 'company'>
    )> }
  ) }
);

export type GetProjectDocumentsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetProjectDocumentsQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & Pick<Project, '_id' | 'name' | 'stage' | 'address' | 'builderCompany' | 'unitsNumber' | 'floorsNumber' | 'createdAt' | 'updatedAt' | 'isPublished' | 'isDeleted'>
    & { company: (
      { __typename?: 'RealEstateCompany' }
      & Pick<RealEstateCompany, '_id' | 'name'>
      & { logo?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )> }
    ), technicalSpecifications?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, projectApproval?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, buildingPermit?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, documentation?: Maybe<(
      { __typename?: 'Documentation' }
      & Pick<Documentation, 'reservationTemplate' | 'promiseTemplate' | 'deedTemplate' | 'offerTemplate'>
    )> }
  ) }
);

export type GetProjectsQueryVariables = Exact<{
  filters: ListProjectsInput;
}>;


export type GetProjectsQuery = (
  { __typename?: 'Query' }
  & { projects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, '_id' | 'name' | 'stage' | 'address' | 'releaseDate' | 'builderCompany' | 'unitsNumber' | 'floorsNumber' | 'video' | 'virtualTour' | 'createdAt' | 'updatedAt' | 'isPublished' | 'isDeleted'>
    & { amenities?: Maybe<Array<(
      { __typename?: 'Amenity' }
      & Pick<Amenity, 'name' | 'icon'>
    )>>, deedContact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'name' | 'email' | 'phone'>
    )>, bankContact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'name' | 'email' | 'phone'>
    )>, brochure?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, cover?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, billing?: Maybe<(
      { __typename?: 'Billing' }
      & Pick<Billing, 'billingAddress' | 'billingEmail' | 'billingRut' | 'economicActivity' | 'socialReason'>
    )>, legal?: Maybe<(
      { __typename?: 'Legal' }
      & Pick<Legal, 'companySocialReason' | 'companyRut' | 'representativeName' | 'representativeRut' | 'representativeEmail' | 'representativeAddress'>
    )>, media?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>>, units: Array<(
      { __typename?: 'Unit' }
      & Pick<Unit, '_id' | 'name' | 'sellPrice' | 'variantCode' | 'available' | 'type' | 'floor'>
      & { model?: Maybe<(
        { __typename?: 'UnitModel' }
        & Pick<UnitModel, '_id' | 'name' | 'bedrooms' | 'bathrooms'>
        & { image: (
          { __typename?: 'File' }
          & Pick<File, 'url'>
        ) }
      )>, units: Array<(
        { __typename?: 'Unit' }
        & Pick<Unit, '_id' | 'name' | 'type'>
      )> }
    )>, models: Array<(
      { __typename?: 'UnitModel' }
      & Pick<UnitModel, '_id' | 'name' | 'typology' | 'bathrooms' | 'bedrooms' | 'isStudio' | 'hasGarden'>
      & { variants?: Maybe<Array<(
        { __typename?: 'Variant' }
        & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
      )>>, image: (
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      ) }
    )>, commercialInfo?: Maybe<(
      { __typename?: 'CommercialInfo' }
      & Pick<CommercialInfo, 'commerceCode' | 'reservationFee' | 'realEstateFee' | 'brokerFee' | 'promisePaymentPercent' | 'deedPaymentPercent' | 'reservationPolicy'>
      & { paymentBonusTerms?: Maybe<Array<(
        { __typename?: 'PaymentTermsBonus' }
        & Pick<PaymentTermsBonus, 'percent'>
      )>>, paymentPieTerms?: Maybe<Array<(
        { __typename?: 'PaymentTermsPie' }
        & Pick<PaymentTermsPie, 'title' | 'dues' | 'endDate' | 'percent' | 'bigDue' | 'terms'>
      )>> }
    )>, company: (
      { __typename?: 'RealEstateCompany' }
      & Pick<RealEstateCompany, '_id' | 'name'>
    ), technicalSpecifications?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, projectApproval?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, buildingPermit?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, documentation?: Maybe<(
      { __typename?: 'Documentation' }
      & Pick<Documentation, 'reservationTemplate' | 'promiseTemplate' | 'deedTemplate' | 'offerTemplate'>
    )>, currentViewers: Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'position' | 'roles' | 'company'>
    )> }
  )> }
);

export type GetModelsQueryVariables = Exact<{
  filters: FilterModelsInput;
}>;


export type GetModelsQuery = (
  { __typename?: 'Query' }
  & { unitModels: Array<(
    { __typename?: 'UnitModel' }
    & Pick<UnitModel, '_id' | 'name' | 'typology' | 'bathrooms' | 'bedrooms' | 'hasGarden' | 'isStudio' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { image: (
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    ), variants?: Maybe<Array<(
      { __typename?: 'Variant' }
      & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
    )>> }
  )> }
);

export type GetUnitByIdQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type GetUnitByIdQuery = (
  { __typename?: 'Query' }
  & { unit: (
    { __typename?: 'Unit' }
    & Pick<Unit, '_id' | 'name' | 'sellPrice' | 'variantCode' | 'project' | 'company' | 'available' | 'type' | 'floor' | 'status' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { model?: Maybe<(
      { __typename?: 'UnitModel' }
      & Pick<UnitModel, '_id' | 'name' | 'typology' | 'bathrooms' | 'bedrooms' | 'hasGarden' | 'isStudio'>
      & { image: (
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      ), variants?: Maybe<Array<(
        { __typename?: 'Variant' }
        & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
      )>> }
    )> }
  ) }
);

export type GetUnitsQueryVariables = Exact<{
  filters?: Maybe<ListUnitsInput>;
}>;


export type GetUnitsQuery = (
  { __typename?: 'Query' }
  & { units: Array<(
    { __typename?: 'Unit' }
    & Pick<Unit, '_id' | 'name' | 'sellPrice' | 'variantCode' | 'available' | 'type' | 'floor' | 'status' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { model?: Maybe<(
      { __typename?: 'UnitModel' }
      & Pick<UnitModel, '_id' | 'name' | 'typology' | 'bathrooms' | 'bedrooms' | 'hasGarden' | 'isStudio'>
      & { image: (
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      ), variants?: Maybe<Array<(
        { __typename?: 'Variant' }
        & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
      )>> }
    )> }
  )> }
);

export type GetUnitsByFloorQueryVariables = Exact<{
  _id: Scalars['String'];
  type: Scalars['String'];
}>;


export type GetUnitsByFloorQuery = (
  { __typename?: 'Query' }
  & { unitsByFloorStats: Array<(
    { __typename?: 'UnitsByFloorChartStats' }
    & Pick<UnitsByFloorChartStats, 'available' | 'reserved' | 'total' | 'floor'>
    & { units: Array<(
      { __typename?: 'UnitChart' }
      & Pick<UnitChart, '_id' | 'name' | 'available' | 'sellPrice' | 'variantCode' | 'image' | 'status'>
      & { model?: Maybe<(
        { __typename?: 'UnitModel' }
        & Pick<UnitModel, '_id' | 'name' | 'typology' | 'project' | 'bathrooms' | 'bedrooms' | 'hasGarden' | 'isStudio'>
        & { variants?: Maybe<Array<(
          { __typename?: 'Variant' }
          & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
        )>> }
      )>, client?: Maybe<(
        { __typename?: 'Client' }
        & Pick<Client, '_id' | 'name' | 'email' | 'rut' | 'phone' | 'status' | 'createdAt' | 'updatedAt' | 'isDeleted'>
      )> }
    )> }
  )> }
);

export type CreateProjectMutationVariables = Exact<{
  project: CreateProjectInput;
}>;


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { createProject: (
    { __typename?: 'Project' }
    & Pick<Project, '_id' | 'name' | 'stage' | 'address' | 'releaseDate' | 'builderCompany' | 'unitsNumber' | 'floorsNumber' | 'video' | 'virtualTour' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { amenities?: Maybe<Array<(
      { __typename?: 'Amenity' }
      & Pick<Amenity, 'name' | 'icon'>
    )>>, deedContact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'name' | 'email' | 'phone'>
    )>, bankContact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'name' | 'email' | 'phone'>
    )>, brochure?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, cover?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, billing?: Maybe<(
      { __typename?: 'Billing' }
      & Pick<Billing, 'billingAddress' | 'billingEmail' | 'billingRut' | 'economicActivity' | 'socialReason'>
    )>, legal?: Maybe<(
      { __typename?: 'Legal' }
      & Pick<Legal, 'companySocialReason' | 'companyRut' | 'representativeName' | 'representativeRut' | 'representativeEmail' | 'representativeAddress'>
    )>, media?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>>, units: Array<(
      { __typename?: 'Unit' }
      & Pick<Unit, '_id' | 'name' | 'sellPrice' | 'variantCode' | 'available' | 'type' | 'floor'>
      & { model?: Maybe<(
        { __typename?: 'UnitModel' }
        & Pick<UnitModel, '_id' | 'name'>
      )>, units: Array<(
        { __typename?: 'Unit' }
        & Pick<Unit, '_id' | 'name' | 'type'>
      )> }
    )> }
  ) }
);

export type UpdateProjectMutationVariables = Exact<{
  project: UpdateProjectInput;
}>;


export type UpdateProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateProject: (
    { __typename?: 'Project' }
    & Pick<Project, '_id' | 'name' | 'stage' | 'address' | 'releaseDate' | 'builderCompany' | 'unitsNumber' | 'floorsNumber' | 'video' | 'virtualTour' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { amenities?: Maybe<Array<(
      { __typename?: 'Amenity' }
      & Pick<Amenity, 'name' | 'icon'>
    )>>, deedContact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'name' | 'email' | 'phone'>
    )>, bankContact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'name' | 'email' | 'phone'>
    )>, brochure?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, cover?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, billing?: Maybe<(
      { __typename?: 'Billing' }
      & Pick<Billing, 'billingAddress' | 'billingEmail' | 'billingRut' | 'economicActivity' | 'socialReason'>
    )>, legal?: Maybe<(
      { __typename?: 'Legal' }
      & Pick<Legal, 'companySocialReason' | 'companyRut' | 'representativeName' | 'representativeRut' | 'representativeEmail' | 'representativeAddress'>
    )>, media?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>>, units: Array<(
      { __typename?: 'Unit' }
      & Pick<Unit, '_id' | 'name' | 'sellPrice' | 'variantCode' | 'available' | 'type' | 'floor'>
      & { model?: Maybe<(
        { __typename?: 'UnitModel' }
        & Pick<UnitModel, '_id' | 'name' | 'typology' | 'bathrooms' | 'bedrooms' | 'isStudio' | 'hasGarden'>
        & { variants?: Maybe<Array<(
          { __typename?: 'Variant' }
          & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
        )>>, image: (
          { __typename?: 'File' }
          & Pick<File, 'url'>
        ) }
      )>, units: Array<(
        { __typename?: 'Unit' }
        & Pick<Unit, '_id' | 'name' | 'type'>
      )> }
    )>, commercialInfo?: Maybe<(
      { __typename?: 'CommercialInfo' }
      & Pick<CommercialInfo, 'commerceCode' | 'reservationFee' | 'realEstateFee' | 'brokerFee' | 'reservationPolicy'>
      & { paymentBonusTerms?: Maybe<Array<(
        { __typename?: 'PaymentTermsBonus' }
        & Pick<PaymentTermsBonus, 'percent'>
        & { rules?: Maybe<(
          { __typename?: 'PieRules' }
          & Pick<PieRules, 'includeUnitTypes'>
          & { units: Array<(
            { __typename?: 'Unit' }
            & Pick<Unit, '_id' | 'name'>
          )>, models: Array<(
            { __typename?: 'UnitModel' }
            & Pick<UnitModel, '_id' | 'name'>
          )> }
        )> }
      )>>, paymentPieTerms?: Maybe<Array<(
        { __typename?: 'PaymentTermsPie' }
        & Pick<PaymentTermsPie, 'title' | 'dues' | 'endDate' | 'percent' | 'bigDue' | 'terms'>
        & { rules?: Maybe<(
          { __typename?: 'PieRules' }
          & Pick<PieRules, 'includeUnitTypes'>
          & { units: Array<(
            { __typename?: 'Unit' }
            & Pick<Unit, '_id' | 'name'>
          )>, models: Array<(
            { __typename?: 'UnitModel' }
            & Pick<UnitModel, '_id' | 'name'>
          )> }
        )> }
      )>> }
    )>, technicalSpecifications?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, projectApproval?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, buildingPermit?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, documentation?: Maybe<(
      { __typename?: 'Documentation' }
      & Pick<Documentation, 'reservationTemplate' | 'promiseTemplate' | 'deedTemplate' | 'offerTemplate'>
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'coordinates'>
    )> }
  ) }
);

export type UploadStockMutationVariables = Exact<{
  payload: UploadStockInput;
}>;


export type UploadStockMutation = (
  { __typename?: 'Mutation' }
  & { uploadStock: Array<(
    { __typename?: 'Unit' }
    & Pick<Unit, '_id' | 'name' | 'sellPrice' | 'variantCode' | 'available' | 'type' | 'floor' | 'createdAt' | 'updatedAt' | 'isDeleted'>
  )> }
);

export type CreateModelMutationVariables = Exact<{
  model: CreateModelInput;
}>;


export type CreateModelMutation = (
  { __typename?: 'Mutation' }
  & { createUnitModel: (
    { __typename?: 'UnitModel' }
    & Pick<UnitModel, '_id' | 'name' | 'typology' | 'bathrooms' | 'bedrooms' | 'hasGarden' | 'isStudio' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { image: (
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    ), variants?: Maybe<Array<(
      { __typename?: 'Variant' }
      & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
    )>> }
  ) }
);

export type UpdateModelMutationVariables = Exact<{
  model: UpdateModelInput;
}>;


export type UpdateModelMutation = (
  { __typename?: 'Mutation' }
  & { updateUnitModel: (
    { __typename?: 'UnitModel' }
    & Pick<UnitModel, '_id' | 'name' | 'typology' | 'bathrooms' | 'bedrooms' | 'hasGarden' | 'isStudio' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { image: (
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    ), variants?: Maybe<Array<(
      { __typename?: 'Variant' }
      & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
    )>> }
  ) }
);

export type DeleteUnitModelMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUnitModelMutation = (
  { __typename?: 'Mutation' }
  & { deleteUnitModel: (
    { __typename?: 'UnitModel' }
    & Pick<UnitModel, '_id' | 'name'>
  ) }
);

export type CreateUnitMutationVariables = Exact<{
  unit: CreateUnitInput;
}>;


export type CreateUnitMutation = (
  { __typename?: 'Mutation' }
  & { createUnit: (
    { __typename?: 'Unit' }
    & Pick<Unit, '_id' | 'name' | 'sellPrice' | 'variantCode' | 'available' | 'type' | 'floor' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { model?: Maybe<(
      { __typename?: 'UnitModel' }
      & Pick<UnitModel, '_id' | 'name' | 'typology' | 'bathrooms' | 'bedrooms' | 'hasGarden' | 'isStudio'>
      & { image: (
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      ), variants?: Maybe<Array<(
        { __typename?: 'Variant' }
        & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
      )>> }
    )> }
  ) }
);

export type UpdateUnitMutationVariables = Exact<{
  unit: UpdateUnitInput;
}>;


export type UpdateUnitMutation = (
  { __typename?: 'Mutation' }
  & { updateUnit: (
    { __typename?: 'Unit' }
    & Pick<Unit, '_id' | 'name' | 'sellPrice' | 'variantCode' | 'available' | 'type' | 'floor' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { model?: Maybe<(
      { __typename?: 'UnitModel' }
      & Pick<UnitModel, '_id' | 'name' | 'typology' | 'bathrooms' | 'bedrooms' | 'hasGarden' | 'isStudio'>
      & { image: (
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      ), variants?: Maybe<Array<(
        { __typename?: 'Variant' }
        & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
      )>> }
    )> }
  ) }
);

export type AddViewerMutationVariables = Exact<{
  payload: AddViewerInput;
}>;


export type AddViewerMutation = (
  { __typename?: 'Mutation' }
  & { addViewer: (
    { __typename?: 'Project' }
    & Pick<Project, '_id' | 'name'>
    & { currentViewers: Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'roles' | 'company'>
    )> }
  ) }
);

export type RemoveViewerMutationVariables = Exact<{
  payload: RemoveViewerInput;
}>;


export type RemoveViewerMutation = (
  { __typename?: 'Mutation' }
  & { removeViewer: (
    { __typename?: 'Project' }
    & Pick<Project, '_id' | 'name'>
    & { currentViewers: Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'roles' | 'company'>
    )> }
  ) }
);

export type ViewerAddedSubscriptionVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type ViewerAddedSubscription = (
  { __typename?: 'Subscription' }
  & { viewerAdded: (
    { __typename?: 'Project' }
    & Pick<Project, '_id' | 'name'>
    & { currentViewers: Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'roles' | 'company'>
    )> }
  ) }
);

export type ViewerRemovedSubscriptionVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type ViewerRemovedSubscription = (
  { __typename?: 'Subscription' }
  & { viewerRemoved: (
    { __typename?: 'Project' }
    & Pick<Project, '_id' | 'name'>
    & { currentViewers: Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'roles' | 'company'>
    )> }
  ) }
);

export type GetRealEstatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRealEstatesQuery = (
  { __typename?: 'Query' }
  & { realEstates: Array<(
    { __typename?: 'RealEstateCompany' }
    & Pick<RealEstateCompany, '_id' | 'name' | 'isFirstTime' | 'kind' | 'rut' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { collaborators?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'rut' | 'roles' | 'email' | 'phone' | 'position' | 'emailVerified'>
    )>>, logo?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )> }
  )> }
);

export type GetRealEstateByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetRealEstateByIdQuery = (
  { __typename?: 'Query' }
  & { realEstate: (
    { __typename?: 'RealEstateCompany' }
    & Pick<RealEstateCompany, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'bank' | 'accountType' | 'accountNumber' | 'emailBank' | 'isFirstTime' | 'kind' | 'rut' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { collaborators?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'rut' | 'roles' | 'email' | 'phone' | 'position' | 'emailVerified'>
    )>>, logo?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )> }
  ) }
);

export type GetRealEstateStatsQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type GetRealEstateStatsQuery = (
  { __typename?: 'Query' }
  & { realEstateStats: Array<(
    { __typename?: 'RealEstateStats' }
    & Pick<RealEstateStats, 'company' | 'total' | 'type'>
  )> }
);

export type CreateRealEstateMutationVariables = Exact<{
  realEstate: CreateRealEstateInput;
}>;


export type CreateRealEstateMutation = (
  { __typename?: 'Mutation' }
  & { createRealEstateCompany: (
    { __typename?: 'RealEstateCompany' }
    & Pick<RealEstateCompany, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'isFirstTime' | 'kind' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { logo?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, collaborators?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'roles' | 'emailVerified'>
    )>>, projects?: Maybe<Array<(
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'name'>
    )>> }
  ) }
);

export type UpdateRealEstateMutationVariables = Exact<{
  realEstate: UpdateRealEstateInput;
}>;


export type UpdateRealEstateMutation = (
  { __typename?: 'Mutation' }
  & { updateRealEstateCompany: (
    { __typename?: 'RealEstateCompany' }
    & Pick<RealEstateCompany, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'bank' | 'accountType' | 'accountNumber' | 'emailBank' | 'isFirstTime' | 'kind' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { logo?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'url'>
    )>, collaborators?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'position' | 'emailVerified'>
    )>> }
  ) }
);

export type GetTimelineIdByUnitQueryVariables = Exact<{
  unitId: Scalars['String'];
}>;


export type GetTimelineIdByUnitQuery = (
  { __typename?: 'Query' }
  & { timelineByUnit?: Maybe<(
    { __typename?: 'Timeline' }
    & Pick<Timeline, '_id'>
  )> }
);

export type GetSellerProgressProcessesQueryVariables = Exact<{
  sellerId: Scalars['String'];
}>;


export type GetSellerProgressProcessesQuery = (
  { __typename?: 'Query' }
  & { sellerProgressProcesses?: Maybe<Array<(
    { __typename?: 'SellerProgressProcesses' }
    & Pick<SellerProgressProcesses, 'timelineId' | 'projectName' | 'projectCompanyName' | 'projectCoverUrl' | 'clientName' | 'clientEmail' | 'timelineStatus' | 'timelineStatusDate'>
    & { units?: Maybe<Array<(
      { __typename?: 'Units' }
      & Pick<Units, 'unitName' | 'unitSellPrice' | 'unitModelImage'>
    )>> }
  )>> }
);

export type GetScheduleEventsQueryVariables = Exact<{
  filters: ListScheduleEventsInput;
}>;


export type GetScheduleEventsQuery = (
  { __typename?: 'Query' }
  & { scheduleEvents: Array<(
    { __typename?: 'ScheduleEvent' }
    & Pick<ScheduleEvent, '_id' | 'code' | 'kind' | 'description' | 'available' | 'status' | 'startsAt' | 'endsAt' | 'createdAt' | 'updatedAt'>
    & { client: (
      { __typename?: 'Client' }
      & Pick<Client, '_id' | 'name'>
    ), company: (
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name'>
    ), project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'name' | 'code'>
    )> }
  )> }
);

export type GetScheduleEventByCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type GetScheduleEventByCodeQuery = (
  { __typename?: 'Query' }
  & { scheduleEventByCode: (
    { __typename?: 'ScheduleEvent' }
    & Pick<ScheduleEvent, '_id' | 'code' | 'kind' | 'description' | 'available' | 'status' | 'startsAt' | 'endsAt' | 'createdAt' | 'updatedAt'>
    & { client: (
      { __typename?: 'Client' }
      & Pick<Client, '_id' | 'name'>
    ), company: (
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name'>
    ), project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'name' | 'code'>
    )> }
  ) }
);

export type CreateScheduleEventMutationVariables = Exact<{
  payload: CreateScheduleEventInput;
}>;


export type CreateScheduleEventMutation = (
  { __typename?: 'Mutation' }
  & { createScheduleEvent: (
    { __typename?: 'ScheduleEvent' }
    & Pick<ScheduleEvent, '_id' | 'code' | 'kind' | 'description' | 'available' | 'status' | 'startsAt' | 'endsAt' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { seller: (
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name'>
    ), company: (
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name'>
    ), client: (
      { __typename?: 'Client' }
      & Pick<Client, '_id' | 'name'>
    ), project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'code' | 'name'>
    )> }
  ) }
);

export type GetTimelineQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTimelineQuery = (
  { __typename?: 'Query' }
  & { timeline: (
    { __typename?: 'Timeline' }
    & Pick<Timeline, '_id' | 'isActive' | 'status' | 'createdAt' | 'updatedAt'>
    & { client: (
      { __typename?: 'Client' }
      & Pick<Client, '_id' | 'name' | 'email' | 'rut' | 'phone' | 'nationality' | 'address' | 'profession' | 'civilStatus' | 'purchaseUse' | 'kind' | 'liquidIncome' | 'currentAccount' | 'bank' | 'monthlySavingsCapacity' | 'outstandingDebts' | 'comments' | 'validToBuy' | 'createdAt' | 'updatedAt' | 'isDeleted'>
      & { interests?: Maybe<Array<(
        { __typename?: 'Interest' }
        & Pick<Interest, 'name'>
        & { location: (
          { __typename?: 'Location' }
          & Pick<Location, 'type' | 'coordinates'>
        ) }
      )>>, frontIdCard?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>, backIdCard?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>, socialSecurityQuotes: Array<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>, liquidations: Array<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )> }
    ), suggestedProjects?: Maybe<Array<(
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'name' | 'stage' | 'address' | 'releaseDate' | 'builderCompany' | 'unitsNumber' | 'floorsNumber' | 'virtualTour' | 'video'>
      & { location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'type' | 'coordinates'>
      )>, amenities?: Maybe<Array<(
        { __typename?: 'Amenity' }
        & Pick<Amenity, 'name' | 'icon'>
      )>>, brochure?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>, cover?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>, media?: Maybe<Array<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>>, company: (
        { __typename?: 'RealEstateCompany' }
        & Pick<RealEstateCompany, '_id' | 'name'>
        & { logo?: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'url'>
        )> }
      ), models: Array<(
        { __typename?: 'UnitModel' }
        & Pick<UnitModel, '_id' | 'name' | 'typology' | 'bathrooms' | 'bedrooms' | 'isStudio' | 'hasGarden'>
        & { variants?: Maybe<Array<(
          { __typename?: 'Variant' }
          & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
        )>> }
      )>, stats?: Maybe<(
        { __typename?: 'ProjectStats' }
        & Pick<ProjectStats, '_id' | 'minLP' | 'maxLP' | 'minSP' | 'maxSP' | 'minBP' | 'maxBP' | 'available' | 'reserved' | 'total'>
      )> }
    )>>, project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'name' | 'stage' | 'address' | 'releaseDate' | 'builderCompany' | 'unitsNumber' | 'floorsNumber' | 'virtualTour' | 'video'>
      & { location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'type' | 'coordinates'>
      )>, amenities?: Maybe<Array<(
        { __typename?: 'Amenity' }
        & Pick<Amenity, 'name' | 'icon'>
      )>>, documentation?: Maybe<(
        { __typename?: 'Documentation' }
        & Pick<Documentation, 'reservationTemplate' | 'promiseTemplate' | 'deedTemplate' | 'offerTemplate'>
      )>, brochure?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>, cover?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>, media?: Maybe<Array<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>>, company: (
        { __typename?: 'RealEstateCompany' }
        & Pick<RealEstateCompany, '_id' | 'name'>
        & { logo?: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'url'>
        )> }
      ), models: Array<(
        { __typename?: 'UnitModel' }
        & Pick<UnitModel, '_id' | 'name' | 'typology' | 'bathrooms' | 'bedrooms' | 'isStudio' | 'hasGarden'>
        & { variants?: Maybe<Array<(
          { __typename?: 'Variant' }
          & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
        )>>, image: (
          { __typename?: 'File' }
          & Pick<File, 'url'>
        ) }
      )> }
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name'>
    )>, seller: (
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name'>
    ), history?: Maybe<Array<(
      { __typename?: 'History' }
      & Pick<History, 'what' | 'when' | 'extra'>
    )>>, payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, '_id' | 'buyOrder' | 'sessionId' | 'amount' | 'status' | 'storeCode' | 'token' | 'start' | 'end' | 'transaction'>
      & { items?: Maybe<Array<(
        { __typename?: 'PaymentCartItem' }
        & Pick<PaymentCartItem, 'units' | 'reservationAmount' | 'dayUF'>
        & { paymentBonusTerm?: Maybe<(
          { __typename?: 'PaymentTermsBonus' }
          & Pick<PaymentTermsBonus, 'percent'>
        )>, paymentPieTerm?: Maybe<(
          { __typename?: 'PaymentTermsPie' }
          & Pick<PaymentTermsPie, 'title' | 'dues' | 'endDate' | 'percent' | 'bigDue' | 'terms'>
        )> }
      )>> }
    )> }
  ) }
);

export type GetTimelineByClientQueryVariables = Exact<{
  clientId: Scalars['String'];
}>;


export type GetTimelineByClientQuery = (
  { __typename?: 'Query' }
  & { timelineByClient?: Maybe<(
    { __typename?: 'Timeline' }
    & Pick<Timeline, '_id' | 'isActive' | 'status' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { client: (
      { __typename?: 'Client' }
      & Pick<Client, '_id' | 'name' | 'email' | 'rut' | 'phone' | 'nationality' | 'address' | 'profession' | 'civilStatus' | 'purchaseUse' | 'kind' | 'liquidIncome' | 'currentAccount' | 'bank' | 'monthlySavingsCapacity' | 'outstandingDebts' | 'comments' | 'validToBuy' | 'createdAt' | 'updatedAt' | 'isDeleted'>
      & { interests?: Maybe<Array<(
        { __typename?: 'Interest' }
        & Pick<Interest, 'name'>
        & { location: (
          { __typename?: 'Location' }
          & Pick<Location, 'type' | 'coordinates'>
        ) }
      )>>, frontIdCard?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>, backIdCard?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>, socialSecurityQuotes: Array<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>, liquidations: Array<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )> }
    ), suggestedProjects?: Maybe<Array<(
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'name' | 'stage' | 'address' | 'releaseDate' | 'builderCompany' | 'unitsNumber' | 'floorsNumber' | 'virtualTour' | 'video'>
      & { models: Array<(
        { __typename?: 'UnitModel' }
        & Pick<UnitModel, '_id' | 'name' | 'typology' | 'bathrooms' | 'bedrooms' | 'isStudio' | 'hasGarden'>
        & { variants?: Maybe<Array<(
          { __typename?: 'Variant' }
          & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
        )>>, image: (
          { __typename?: 'File' }
          & Pick<File, 'url'>
        ) }
      )>, location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'type' | 'coordinates'>
      )>, amenities?: Maybe<Array<(
        { __typename?: 'Amenity' }
        & Pick<Amenity, 'name' | 'icon'>
      )>>, brochure?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>, cover?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>, media?: Maybe<Array<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>>, company: (
        { __typename?: 'RealEstateCompany' }
        & Pick<RealEstateCompany, '_id' | 'name'>
        & { logo?: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'url'>
        )> }
      ), stats?: Maybe<(
        { __typename?: 'ProjectStats' }
        & Pick<ProjectStats, '_id' | 'minLP' | 'maxLP' | 'minSP' | 'maxSP' | 'minBP' | 'maxBP' | 'available' | 'reserved' | 'total'>
      )> }
    )>>, project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'name' | 'stage' | 'address' | 'releaseDate' | 'builderCompany' | 'unitsNumber' | 'floorsNumber' | 'virtualTour' | 'video'>
      & { location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'type' | 'coordinates'>
      )>, amenities?: Maybe<Array<(
        { __typename?: 'Amenity' }
        & Pick<Amenity, 'name' | 'icon'>
      )>>, brochure?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>, cover?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>, media?: Maybe<Array<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'url'>
      )>>, company: (
        { __typename?: 'RealEstateCompany' }
        & Pick<RealEstateCompany, '_id' | 'name'>
        & { logo?: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'url'>
        )> }
      ), models: Array<(
        { __typename?: 'UnitModel' }
        & Pick<UnitModel, '_id' | 'name' | 'typology' | 'bathrooms' | 'bedrooms' | 'isStudio' | 'hasGarden'>
        & { variants?: Maybe<Array<(
          { __typename?: 'Variant' }
          & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
        )>>, image: (
          { __typename?: 'File' }
          & Pick<File, 'url'>
        ) }
      )> }
    )>, units?: Maybe<Array<(
      { __typename?: 'Unit' }
      & Pick<Unit, '_id' | 'name' | 'sellPrice' | 'available' | 'type' | 'floor' | 'variantCode'>
      & { model?: Maybe<(
        { __typename?: 'UnitModel' }
        & Pick<UnitModel, '_id' | 'name' | 'typology' | 'bathrooms' | 'bedrooms' | 'hasGarden' | 'isStudio'>
        & { image: (
          { __typename?: 'File' }
          & Pick<File, 'url'>
        ), variants?: Maybe<Array<(
          { __typename?: 'Variant' }
          & Pick<Variant, 'code' | 'orientation' | 'terraceArea' | 'totalArea' | 'usefulArea' | 'gardenArea'>
        )>> }
      )> }
    )>>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name'>
    )>, seller: (
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name'>
    ), history?: Maybe<Array<(
      { __typename?: 'History' }
      & Pick<History, 'what' | 'when' | 'extra'>
    )>>, payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, '_id' | 'buyOrder' | 'sessionId' | 'amount' | 'status' | 'storeCode' | 'token' | 'start' | 'end' | 'transaction' | 'createdAt' | 'updatedAt' | 'isDeferred' | 'isDeleted'>
      & { items?: Maybe<Array<(
        { __typename?: 'PaymentCartItem' }
        & Pick<PaymentCartItem, 'units' | 'reservationAmount' | 'dayUF'>
        & { paymentBonusTerm?: Maybe<(
          { __typename?: 'PaymentTermsBonus' }
          & Pick<PaymentTermsBonus, 'percent'>
        )>, paymentPieTerm?: Maybe<(
          { __typename?: 'PaymentTermsPie' }
          & Pick<PaymentTermsPie, 'title' | 'dues' | 'endDate' | 'percent' | 'bigDue' | 'terms'>
        )> }
      )>> }
    )> }
  )> }
);

export type GetTimelinesBySellerQueryVariables = Exact<{
  sellerId: Scalars['String'];
}>;


export type GetTimelinesBySellerQuery = (
  { __typename?: 'Query' }
  & { timelinesBySeller?: Maybe<Array<(
    { __typename?: 'SellerBoard' }
    & Pick<SellerBoard, 'funnel' | 'count'>
    & { timelines?: Maybe<Array<(
      { __typename?: 'Process' }
      & Pick<Process, '_id' | 'isDeleted' | 'status' | 'isActive' | 'company' | 'seller' | 'project' | 'createdAt'>
      & { client?: Maybe<(
        { __typename?: 'Client' }
        & Pick<Client, 'name' | 'email' | 'validToBuy' | 'kind' | 'phone'>
      )> }
    )>> }
  )>> }
);

export type GetBrokerFunnelQueryVariables = Exact<{
  brokerId: Scalars['String'];
}>;


export type GetBrokerFunnelQuery = (
  { __typename?: 'Query' }
  & { brokerFunnel?: Maybe<Array<(
    { __typename?: 'SellerSalesStatus' }
    & Pick<SellerSalesStatus, 'subLabels' | 'values' | 'status'>
  )>> }
);

export type GetBrokerProjectSalesQueryVariables = Exact<{
  brokerId: Scalars['String'];
}>;


export type GetBrokerProjectSalesQuery = (
  { __typename?: 'Query' }
  & { brokerProjectSales?: Maybe<Array<(
    { __typename?: 'BrokerProjectSales' }
    & Pick<BrokerProjectSales, 'projectId' | 'projectName' | 'projectCompanyName' | 'projectCoverUrl' | 'totalBrokerSales' | 'unsoldProjectUnits' | 'totalProjectSales' | 'totalProjectUnits'>
    & { sellers?: Maybe<Array<(
      { __typename?: 'ListSellers' }
      & Pick<ListSellers, 'name' | 'avatar'>
    )>> }
  )>> }
);

export type AddSuggestedProjectMutationVariables = Exact<{
  payload: AddSuggestedProjectInput;
}>;


export type AddSuggestedProjectMutation = (
  { __typename?: 'Mutation' }
  & { addSuggestedProject: (
    { __typename?: 'Timeline' }
    & Pick<Timeline, '_id'>
    & { suggestedProjects?: Maybe<Array<(
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'name' | 'stage' | 'address' | 'releaseDate'>
      & { features?: Maybe<(
        { __typename?: 'ProjectFeatures' }
        & { bathrooms: (
          { __typename?: 'MinMax' }
          & Pick<MinMax, 'min' | 'max'>
        ), bedrooms: (
          { __typename?: 'MinMax' }
          & Pick<MinMax, 'min' | 'max'>
        ), usefulSurface: (
          { __typename?: 'MinMax' }
          & Pick<MinMax, 'min' | 'max'>
        ), totalSurface: (
          { __typename?: 'MinMax' }
          & Pick<MinMax, 'min' | 'max'>
        ) }
      )>, company: (
        { __typename?: 'RealEstateCompany' }
        & Pick<RealEstateCompany, '_id' | 'name'>
      ), stats?: Maybe<(
        { __typename?: 'ProjectStats' }
        & Pick<ProjectStats, '_id' | 'minLP' | 'maxLP' | 'minSP' | 'maxSP' | 'minBP' | 'maxBP' | 'available' | 'reserved' | 'total'>
      )> }
    )>> }
  ) }
);

export type RemoveSuggestedProjectMutationVariables = Exact<{
  payload: RemoveSuggestedProjectInput;
}>;


export type RemoveSuggestedProjectMutation = (
  { __typename?: 'Mutation' }
  & { removeSuggestedProject: (
    { __typename?: 'Timeline' }
    & Pick<Timeline, '_id'>
    & { suggestedProjects?: Maybe<Array<(
      { __typename?: 'Project' }
      & Pick<Project, '_id' | 'name' | 'stage' | 'address' | 'releaseDate'>
      & { features?: Maybe<(
        { __typename?: 'ProjectFeatures' }
        & { bathrooms: (
          { __typename?: 'MinMax' }
          & Pick<MinMax, 'min' | 'max'>
        ), bedrooms: (
          { __typename?: 'MinMax' }
          & Pick<MinMax, 'min' | 'max'>
        ), usefulSurface: (
          { __typename?: 'MinMax' }
          & Pick<MinMax, 'min' | 'max'>
        ), totalSurface: (
          { __typename?: 'MinMax' }
          & Pick<MinMax, 'min' | 'max'>
        ) }
      )>, company: (
        { __typename?: 'RealEstateCompany' }
        & Pick<RealEstateCompany, '_id' | 'name'>
      ), stats?: Maybe<(
        { __typename?: 'ProjectStats' }
        & Pick<ProjectStats, '_id' | 'minLP' | 'maxLP' | 'minSP' | 'maxSP' | 'minBP' | 'maxBP' | 'available' | 'reserved' | 'total'>
      )> }
    )>> }
  ) }
);

export type CreateTimelineMutationVariables = Exact<{
  payload: CreateTimelineInput;
}>;


export type CreateTimelineMutation = (
  { __typename?: 'Mutation' }
  & { createTimeline: (
    { __typename?: 'Timeline' }
    & Pick<Timeline, '_id' | 'isActive'>
    & { client: (
      { __typename?: 'Client' }
      & Pick<Client, '_id' | 'name'>
    ), company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name'>
    )>, seller: (
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name'>
    ), history?: Maybe<Array<(
      { __typename?: 'History' }
      & Pick<History, 'what' | 'when' | 'extra'>
    )>> }
  ) }
);

export type AddTimelineHistoryMutationVariables = Exact<{
  payload: AddHistoryInput;
}>;


export type AddTimelineHistoryMutation = (
  { __typename?: 'Mutation' }
  & { addTimelineHistory: (
    { __typename?: 'Timeline' }
    & Pick<Timeline, '_id' | 'isActive'>
    & { history?: Maybe<Array<(
      { __typename?: 'History' }
      & Pick<History, 'what' | 'when' | 'extra'>
    )>> }
  ) }
);

export const CompaniesForListDocument = gql`
    query companiesForList {
  companies(filters: {}) {
    _id
    name
    website
    logo {
      url
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompaniesForListGQL extends Apollo.Query<CompaniesForListQuery, CompaniesForListQueryVariables> {
    document = CompaniesForListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCompanyByIdDocument = gql`
    query getCompanyById($id: String!) {
  company(_id: $id) {
    _id
    name
    email
    phone
    address
    website
    primaryColor
    secondaryColor
    isFirstTime
    kind
    rut
    admin {
      name
      rut
      email
      isFirstTime
      emailVerified
    }
    collaborators {
      _id
      name
      rut
      roles
      email
      phone
      position
    }
    projects {
      _id
      name
      builderCompany
    }
    logo {
      url
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCompanyByIdGQL extends Apollo.Query<GetCompanyByIdQuery, GetCompanyByIdQueryVariables> {
    document = GetCompanyByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCompaniesDocument = gql`
    query getCompanies {
  companies {
    _id
    name
    email
    phone
    address
    website
    primaryColor
    secondaryColor
    isFirstTime
    kind
    rut
    admin {
      name
      rut
      email
      emailVerified
      isFirstTime
    }
    collaborators {
      _id
      name
      rut
      roles
      email
      phone
      position
    }
    logo {
      url
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCompaniesGQL extends Apollo.Query<GetCompaniesQuery, GetCompaniesQueryVariables> {
    document = GetCompaniesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddAdminDocument = gql`
    mutation addAdmin($data: AddAdminInput!) {
  addAdmin(payload: $data) {
    _id
    name
    email
    isFirstTime
    kind
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddAdminGQL extends Apollo.Mutation<AddAdminMutation, AddAdminMutationVariables> {
    document = AddAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddCollaboratorDocument = gql`
    mutation addCollaborator($data: AddCollaboratorInput!) {
  addCollaborator(payload: $data) {
    _id
    name
    email
    phone
    address
    website
    primaryColor
    secondaryColor
    isFirstTime
    kind
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddCollaboratorGQL extends Apollo.Mutation<AddCollaboratorMutation, AddCollaboratorMutationVariables> {
    document = AddCollaboratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveCollaboratorDocument = gql`
    mutation removeCollaborator($data: RemoveCollaboratorInput!) {
  removeCollaborator(payload: $data) {
    _id
    name
    email
    phone
    address
    website
    primaryColor
    secondaryColor
    isFirstTime
    kind
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveCollaboratorGQL extends Apollo.Mutation<RemoveCollaboratorMutation, RemoveCollaboratorMutationVariables> {
    document = RemoveCollaboratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCompanyDocument = gql`
    mutation createCompany($company: CreateCompanyInput!) {
  createCompany(payload: $company) {
    _id
    name
    email
    phone
    address
    website
    logo {
      url
    }
    admin {
      _id
    }
    primaryColor
    secondaryColor
    isFirstTime
    kind
    collaborators {
      _id
      name
      roles
    }
    projects {
      _id
      name
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCompanyGQL extends Apollo.Mutation<CreateCompanyMutation, CreateCompanyMutationVariables> {
    document = CreateCompanyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCompanyDocument = gql`
    mutation updateCompany($company: UpdateCompanyInput!) {
  updateCompany(payload: $company) {
    _id
    name
    email
    phone
    address
    website
    primaryColor
    secondaryColor
    bank
    accountType
    accountNumber
    emailBank
    isFirstTime
    kind
    logo {
      url
    }
    collaborators {
      _id
      name
      position
    }
    projects {
      _id
      name
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCompanyGQL extends Apollo.Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables> {
    document = UpdateCompanyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePasswordDocument = gql`
    mutation createPassword($payload: ActivateUserInput!) {
  createPassword(payload: $payload) {
    _id
    name
    email
    emailVerified
    roles
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePasswordGQL extends Apollo.Mutation<CreatePasswordMutation, CreatePasswordMutationVariables> {
    document = CreatePasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation login($payload: LoginInput!) {
  login(payload: $payload) {
    access_token
    user {
      _id
      name
      email
      rut
      phone
      isFirstTime
      roles
      avatar {
        url
      }
    }
    company {
      _id
      name
      kind
      logo {
        url
      }
      isFirstTime
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation createUser($user: CreateUserInput!) {
  createUser(payload: $user) {
    _id
    name
    rut
    email
    emailVerified
    verificationToken
    position
    phone
    roles
    avatar {
      url
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation updateUser($user: UpdateUserInput!) {
  updateUser(payload: $user) {
    _id
    name
    rut
    email
    emailVerified
    isFirstTime
    verificationToken
    position
    phone
    roles
    avatar {
      url
    }
    company
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserDocument = gql`
    mutation deleteUser($userId: String!) {
  deleteUser(_id: $userId) {
    _id
    name
    email
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables> {
    document = DeleteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBrokersDocument = gql`
    query getBrokers {
  brokers {
    _id
    name
    email
    phone
    address
    website
    primaryColor
    secondaryColor
    isFirstTime
    kind
    rut
    collaborators {
      _id
      name
      rut
      roles
      email
      phone
      position
      emailVerified
    }
    projects {
      _id
      name
      builderCompany
    }
    logo {
      _id
      url
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBrokersGQL extends Apollo.Query<GetBrokersQuery, GetBrokersQueryVariables> {
    document = GetBrokersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBrokerByIdDocument = gql`
    query getBrokerById($id: String!) {
  broker(_id: $id) {
    _id
    name
    email
    phone
    address
    website
    primaryColor
    secondaryColor
    bank
    accountType
    accountNumber
    emailBank
    isFirstTime
    kind
    rut
    collaborators {
      _id
      name
      rut
      roles
      email
      phone
      position
      emailVerified
    }
    projects {
      _id
      name
      builderCompany
    }
    logo {
      _id
      url
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBrokerByIdGQL extends Apollo.Query<GetBrokerByIdQuery, GetBrokerByIdQueryVariables> {
    document = GetBrokerByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBrokerDocument = gql`
    mutation createBroker($broker: CreateBrokerInput!) {
  createBrokerCompany(payload: $broker) {
    _id
    name
    email
    phone
    address
    website
    logo {
      _id
      url
    }
    primaryColor
    secondaryColor
    isFirstTime
    kind
    collaborators {
      _id
      name
      roles
      emailVerified
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBrokerGQL extends Apollo.Mutation<CreateBrokerMutation, CreateBrokerMutationVariables> {
    document = CreateBrokerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBrokerDocument = gql`
    mutation updateBroker($broker: UpdateBrokerInput!) {
  updateBrokerCompany(payload: $broker) {
    _id
    name
    email
    phone
    address
    website
    primaryColor
    secondaryColor
    bank
    accountType
    accountNumber
    emailBank
    isFirstTime
    kind
    logo {
      _id
      url
    }
    collaborators {
      _id
      name
      position
      emailVerified
    }
    projects {
      _id
      name
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBrokerGQL extends Apollo.Mutation<UpdateBrokerMutation, UpdateBrokerMutationVariables> {
    document = UpdateBrokerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddProjectToPortfolioDocument = gql`
    mutation addProjectToPortfolio($payload: BrokerAddProjectInput!) {
  addProject(payload: $payload) {
    _id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddProjectToPortfolioGQL extends Apollo.Mutation<AddProjectToPortfolioMutation, AddProjectToPortfolioMutationVariables> {
    document = AddProjectToPortfolioDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveProjectFromPortfolioDocument = gql`
    mutation removeProjectFromPortfolio($payload: BrokerAddProjectInput!) {
  removeProject(payload: $payload) {
    _id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveProjectFromPortfolioGQL extends Apollo.Mutation<RemoveProjectFromPortfolioMutation, RemoveProjectFromPortfolioMutationVariables> {
    document = RemoveProjectFromPortfolioDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetClientsDocument = gql`
    query getClients($filters: FilterClientsInput) {
  clients(filters: $filters) {
    _id
    name
    email
    rut
    phone
    nationality
    address
    profession
    civilStatus
    interests {
      name
      location {
        coordinates
      }
    }
    assignedTo {
      _id
      name
      avatar {
        url
      }
    }
    frontIdCard {
      _id
      url
    }
    backIdCard {
      _id
      url
    }
    socialSecurityQuotes {
      _id
      url
    }
    liquidations {
      _id
      url
    }
    purchaseUse
    kind
    liquidIncome
    currentAccount
    bank
    status
    monthlySavingsCapacity
    outstandingDebts
    comments
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetClientsGQL extends Apollo.Query<GetClientsQuery, GetClientsQueryVariables> {
    document = GetClientsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetClientByIdDocument = gql`
    query getClientById($id: String!) {
  client(_id: $id) {
    _id
    name
    email
    rut
    phone
    nationality
    address
    profession
    civilStatus
    interests {
      name
      location {
        coordinates
      }
    }
    assignedTo {
      _id
      name
      avatar {
        url
      }
    }
    frontIdCard {
      _id
      url
    }
    backIdCard {
      _id
      url
    }
    socialSecurityQuotes {
      _id
      url
    }
    liquidations {
      _id
      url
    }
    purchaseUse
    kind
    liquidIncome
    currentAccount
    bank
    status
    monthlySavingsCapacity
    outstandingDebts
    comments
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetClientByIdGQL extends Apollo.Query<GetClientByIdQuery, GetClientByIdQueryVariables> {
    document = GetClientByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateClientDocument = gql`
    mutation createClient($client: CreateClientInput!) {
  createClient(payload: $client) {
    _id
    name
    email
    rut
    phone
    nationality
    address
    profession
    civilStatus
    interests {
      name
      location {
        coordinates
      }
    }
    frontIdCard {
      _id
      url
    }
    backIdCard {
      _id
      url
    }
    socialSecurityQuotes {
      _id
      url
    }
    liquidations {
      _id
      url
    }
    purchaseUse
    kind
    liquidIncome
    currentAccount
    bank
    monthlySavingsCapacity
    outstandingDebts
    comments
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateClientGQL extends Apollo.Mutation<CreateClientMutation, CreateClientMutationVariables> {
    document = CreateClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateClientDocument = gql`
    mutation updateClient($client: UpdateClientInput!) {
  updateClient(payload: $client) {
    _id
    name
    email
    rut
    phone
    nationality
    address
    profession
    civilStatus
    interests {
      name
      location {
        coordinates
      }
    }
    frontIdCard {
      _id
      url
    }
    backIdCard {
      _id
      url
    }
    socialSecurityQuotes {
      _id
      url
    }
    liquidations {
      _id
      url
    }
    purchaseUse
    kind
    liquidIncome
    currentAccount
    bank
    monthlySavingsCapacity
    outstandingDebts
    comments
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateClientGQL extends Apollo.Mutation<UpdateClientMutation, UpdateClientMutationVariables> {
    document = UpdateClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaymentDocument = gql`
    query getPayment($id: String!) {
  payment(_id: $id) {
    _id
    buyOrder
    sessionId
    amount
    isDeferred
    status
    storeCode
    token
    start
    end
    timeline {
      client {
        _id
        name
        email
      }
      seller {
        _id
        name
        email
      }
    }
    project {
      _id
      name
      location {
        coordinates
      }
      company {
        _id
        name
      }
      address
      stage
      cover {
        url
      }
    }
    items {
      units
      paymentBonusTerm {
        percent
      }
      reservationAmount
      dayUF
    }
    transaction
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaymentGQL extends Apollo.Query<GetPaymentQuery, GetPaymentQueryVariables> {
    document = GetPaymentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentStatusChangedDocument = gql`
    subscription paymentStatusChanged($paymentToken: String!) {
  paymentStatusChanged(paymentToken: $paymentToken) {
    _id
    buyOrder
    sessionId
    amount
    status
    storeCode
    token
    start
    isDeferred
    transaction
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentStatusChangedGQL extends Apollo.Subscription<PaymentStatusChangedSubscription, PaymentStatusChangedSubscriptionVariables> {
    document = PaymentStatusChangedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBrokerSellerPaymentsDocument = gql`
    query getBrokerSellerPayments($companyId: String!, $projectId: String!) {
  brokerSellerPayments(companyId: $companyId, projectId: $projectId) {
    projectId
    projectName
    projectCoverURL
    projectCompanyName
    promisePaymentPercent
    deedPaymentPercent
    brokerFee
    totalProjectSales
    sellers {
      name
      avatar
      soldUnitsByStatus {
        status
        salesStatusValueUF
        salesStatusValueCLP
        statusTotal
        units {
          _id
          isDeleted
          status
          type
          available
          name
          sellPrice
          floor
        }
      }
      sellerTotalSales
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBrokerSellerPaymentsGQL extends Apollo.Query<GetBrokerSellerPaymentsQuery, GetBrokerSellerPaymentsQueryVariables> {
    document = GetBrokerSellerPaymentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserProfileDocument = gql`
    query getUserProfile($_id: String!) {
  user(_id: $_id) {
    name
    email
    rut
    phone
    avatar {
      _id
      url
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserProfileGQL extends Apollo.Query<GetUserProfileQuery, GetUserProfileQueryVariables> {
    document = GetUserProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectDocument = gql`
    query getProject($id: String!) {
  project(_id: $id) {
    _id
    name
    stage
    address
    location {
      coordinates
    }
    amenities {
      name
      icon
    }
    releaseDate
    builderCompany
    unitsNumber
    floorsNumber
    deedContact {
      name
      email
      phone
    }
    bankContact {
      name
      email
      phone
    }
    video
    virtualTour
    brochure {
      _id
      url
    }
    cover {
      _id
      url
    }
    billing {
      billingAddress
      billingEmail
      billingRut
      economicActivity
      socialReason
    }
    legal {
      companySocialReason
      companyRut
      representativeName
      representativeRut
      representativeEmail
      representativeAddress
      representativeAddressOther
    }
    media {
      _id
      url
    }
    units {
      _id
      name
      sellPrice
      variantCode
      available
      type
      floor
      model {
        _id
        name
        typology
        bedrooms
        bathrooms
        variants {
          code
          orientation
          terraceArea
          totalArea
          usefulArea
          gardenArea
        }
        image {
          url
        }
      }
      units {
        _id
        name
        type
      }
    }
    models {
      _id
      name
      typology
      variants {
        code
        orientation
        terraceArea
        totalArea
        usefulArea
        gardenArea
      }
      bathrooms
      bedrooms
      isStudio
      hasGarden
      image {
        _id
        url
      }
    }
    company {
      _id
      name
    }
    commercialInfo {
      commerceCode
      reservationFee
      promisePaymentPercent
      deedPaymentPercent
      realEstateFee
      brokerFee
      paymentBonusTerms {
        percent
      }
      paymentPieTerms {
        title
        dues
        endDate
        percent
        bigDue
        terms
      }
      reservationPolicy
    }
    technicalSpecifications {
      _id
      url
    }
    projectApproval {
      _id
      url
    }
    buildingPermit {
      _id
      url
    }
    documentation {
      reservationTemplate
      promiseTemplate
      deedTemplate
      offerTemplate
    }
    currentViewers {
      _id
      name
      position
      roles
      company
    }
    createdAt
    updatedAt
    isPublished
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectGQL extends Apollo.Query<GetProjectQuery, GetProjectQueryVariables> {
    document = GetProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectDocumentsDocument = gql`
    query getProjectDocuments($id: String!) {
  project(_id: $id) {
    _id
    name
    stage
    address
    builderCompany
    unitsNumber
    floorsNumber
    company {
      _id
      name
      logo {
        _id
        url
      }
    }
    technicalSpecifications {
      _id
      url
    }
    projectApproval {
      _id
      url
    }
    buildingPermit {
      _id
      url
    }
    documentation {
      reservationTemplate
      promiseTemplate
      deedTemplate
      offerTemplate
    }
    createdAt
    updatedAt
    isPublished
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectDocumentsGQL extends Apollo.Query<GetProjectDocumentsQuery, GetProjectDocumentsQueryVariables> {
    document = GetProjectDocumentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectsDocument = gql`
    query getProjects($filters: ListProjectsInput!) {
  projects(filters: $filters) {
    _id
    name
    stage
    address
    amenities {
      name
      icon
    }
    releaseDate
    builderCompany
    unitsNumber
    floorsNumber
    deedContact {
      name
      email
      phone
    }
    bankContact {
      name
      email
      phone
    }
    video
    virtualTour
    brochure {
      _id
      url
    }
    cover {
      _id
      url
    }
    billing {
      billingAddress
      billingEmail
      billingRut
      economicActivity
      socialReason
    }
    legal {
      companySocialReason
      companyRut
      representativeName
      representativeRut
      representativeEmail
      representativeAddress
    }
    media {
      _id
      url
    }
    units {
      _id
      name
      sellPrice
      variantCode
      available
      type
      floor
      model {
        _id
        name
        bedrooms
        bathrooms
        image {
          url
        }
      }
      units {
        _id
        name
        type
      }
    }
    models {
      _id
      name
      typology
      variants {
        code
        orientation
        terraceArea
        totalArea
        usefulArea
        gardenArea
      }
      bathrooms
      bedrooms
      isStudio
      hasGarden
      image {
        _id
        url
      }
    }
    commercialInfo {
      commerceCode
      reservationFee
      realEstateFee
      brokerFee
      promisePaymentPercent
      deedPaymentPercent
      paymentBonusTerms {
        percent
      }
      paymentPieTerms {
        title
        dues
        endDate
        percent
        bigDue
        terms
      }
      reservationPolicy
    }
    company {
      _id
      name
    }
    technicalSpecifications {
      _id
      url
    }
    projectApproval {
      _id
      url
    }
    buildingPermit {
      _id
      url
    }
    documentation {
      reservationTemplate
      promiseTemplate
      deedTemplate
      offerTemplate
    }
    currentViewers {
      _id
      name
      position
      roles
      company
    }
    createdAt
    updatedAt
    isPublished
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectsGQL extends Apollo.Query<GetProjectsQuery, GetProjectsQueryVariables> {
    document = GetProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetModelsDocument = gql`
    query getModels($filters: FilterModelsInput!) {
  unitModels(filters: $filters) {
    _id
    name
    typology
    image {
      _id
      url
    }
    variants {
      code
      orientation
      terraceArea
      totalArea
      usefulArea
      gardenArea
    }
    bathrooms
    bedrooms
    hasGarden
    isStudio
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetModelsGQL extends Apollo.Query<GetModelsQuery, GetModelsQueryVariables> {
    document = GetModelsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUnitByIdDocument = gql`
    query getUnitById($_id: String!) {
  unit(_id: $_id) {
    _id
    name
    sellPrice
    variantCode
    project
    company
    available
    type
    floor
    status
    model {
      _id
      name
      typology
      image {
        _id
        url
      }
      variants {
        code
        orientation
        terraceArea
        totalArea
        usefulArea
        gardenArea
      }
      bathrooms
      bedrooms
      hasGarden
      isStudio
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUnitByIdGQL extends Apollo.Query<GetUnitByIdQuery, GetUnitByIdQueryVariables> {
    document = GetUnitByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUnitsDocument = gql`
    query getUnits($filters: ListUnitsInput) {
  units(filters: $filters) {
    _id
    name
    sellPrice
    variantCode
    available
    type
    floor
    status
    model {
      _id
      name
      typology
      image {
        _id
        url
      }
      variants {
        code
        orientation
        terraceArea
        totalArea
        usefulArea
        gardenArea
      }
      bathrooms
      bedrooms
      hasGarden
      isStudio
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUnitsGQL extends Apollo.Query<GetUnitsQuery, GetUnitsQueryVariables> {
    document = GetUnitsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUnitsByFloorDocument = gql`
    query getUnitsByFloor($_id: String!, $type: String!) {
  unitsByFloorStats(_id: $_id, type: $type) {
    available
    reserved
    total
    floor
    units {
      _id
      name
      available
      sellPrice
      variantCode
      model {
        _id
        name
        typology
        project
        variants {
          code
          orientation
          terraceArea
          totalArea
          usefulArea
          gardenArea
        }
        bathrooms
        bedrooms
        hasGarden
        isStudio
      }
      client {
        _id
        name
        email
        rut
        phone
        status
        createdAt
        updatedAt
        isDeleted
      }
      image
      status
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUnitsByFloorGQL extends Apollo.Query<GetUnitsByFloorQuery, GetUnitsByFloorQueryVariables> {
    document = GetUnitsByFloorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProjectDocument = gql`
    mutation createProject($project: CreateProjectInput!) {
  createProject(payload: $project) {
    _id
    name
    stage
    address
    amenities {
      name
      icon
    }
    releaseDate
    builderCompany
    unitsNumber
    floorsNumber
    deedContact {
      name
      email
      phone
    }
    bankContact {
      name
      email
      phone
    }
    video
    virtualTour
    brochure {
      _id
      url
    }
    cover {
      _id
      url
    }
    billing {
      billingAddress
      billingEmail
      billingRut
      economicActivity
      socialReason
    }
    legal {
      companySocialReason
      companyRut
      representativeName
      representativeRut
      representativeEmail
      representativeAddress
    }
    media {
      _id
      url
    }
    units {
      _id
      name
      sellPrice
      variantCode
      available
      type
      floor
      variantCode
      model {
        _id
        name
      }
      units {
        _id
        name
        type
      }
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProjectGQL extends Apollo.Mutation<CreateProjectMutation, CreateProjectMutationVariables> {
    document = CreateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProjectDocument = gql`
    mutation updateProject($project: UpdateProjectInput!) {
  updateProject(payload: $project) {
    _id
    name
    stage
    address
    amenities {
      name
      icon
    }
    releaseDate
    builderCompany
    unitsNumber
    floorsNumber
    deedContact {
      name
      email
      phone
    }
    bankContact {
      name
      email
      phone
    }
    video
    virtualTour
    brochure {
      _id
      url
    }
    cover {
      _id
      url
    }
    billing {
      billingAddress
      billingEmail
      billingRut
      economicActivity
      socialReason
    }
    legal {
      companySocialReason
      companyRut
      representativeName
      representativeRut
      representativeEmail
      representativeAddress
    }
    media {
      _id
      url
    }
    units {
      _id
      name
      sellPrice
      variantCode
      available
      type
      floor
      variantCode
      model {
        _id
        name
        typology
        variants {
          code
          orientation
          terraceArea
          totalArea
          usefulArea
          gardenArea
        }
        bathrooms
        bedrooms
        isStudio
        hasGarden
        image {
          url
        }
      }
      units {
        _id
        name
        type
      }
    }
    commercialInfo {
      commerceCode
      reservationFee
      realEstateFee
      brokerFee
      paymentBonusTerms {
        percent
        rules {
          includeUnitTypes
          units {
            _id
            name
          }
          models {
            _id
            name
          }
        }
      }
      paymentPieTerms {
        title
        dues
        endDate
        percent
        bigDue
        terms
        rules {
          includeUnitTypes
          units {
            _id
            name
          }
          models {
            _id
            name
          }
        }
      }
      reservationPolicy
    }
    technicalSpecifications {
      _id
      url
    }
    projectApproval {
      _id
      url
    }
    buildingPermit {
      _id
      url
    }
    documentation {
      reservationTemplate
      promiseTemplate
      deedTemplate
      offerTemplate
    }
    location {
      coordinates
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectGQL extends Apollo.Mutation<UpdateProjectMutation, UpdateProjectMutationVariables> {
    document = UpdateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadStockDocument = gql`
    mutation uploadStock($payload: UploadStockInput!) {
  uploadStock(payload: $payload) {
    _id
    name
    sellPrice
    variantCode
    available
    type
    floor
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadStockGQL extends Apollo.Mutation<UploadStockMutation, UploadStockMutationVariables> {
    document = UploadStockDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateModelDocument = gql`
    mutation createModel($model: CreateModelInput!) {
  createUnitModel(payload: $model) {
    _id
    name
    typology
    image {
      _id
      url
    }
    variants {
      code
      orientation
      terraceArea
      totalArea
      usefulArea
      gardenArea
    }
    bathrooms
    bedrooms
    hasGarden
    isStudio
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateModelGQL extends Apollo.Mutation<CreateModelMutation, CreateModelMutationVariables> {
    document = CreateModelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateModelDocument = gql`
    mutation updateModel($model: UpdateModelInput!) {
  updateUnitModel(payload: $model) {
    _id
    name
    typology
    image {
      _id
      url
    }
    variants {
      code
      orientation
      terraceArea
      totalArea
      usefulArea
      gardenArea
    }
    bathrooms
    bedrooms
    hasGarden
    isStudio
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateModelGQL extends Apollo.Mutation<UpdateModelMutation, UpdateModelMutationVariables> {
    document = UpdateModelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUnitModelDocument = gql`
    mutation deleteUnitModel($id: String!) {
  deleteUnitModel(_id: $id) {
    _id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUnitModelGQL extends Apollo.Mutation<DeleteUnitModelMutation, DeleteUnitModelMutationVariables> {
    document = DeleteUnitModelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUnitDocument = gql`
    mutation createUnit($unit: CreateUnitInput!) {
  createUnit(payload: $unit) {
    _id
    name
    sellPrice
    variantCode
    available
    type
    floor
    variantCode
    model {
      _id
      name
      typology
      image {
        _id
        url
      }
      variants {
        code
        orientation
        terraceArea
        totalArea
        usefulArea
        gardenArea
      }
      bathrooms
      bedrooms
      hasGarden
      isStudio
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUnitGQL extends Apollo.Mutation<CreateUnitMutation, CreateUnitMutationVariables> {
    document = CreateUnitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUnitDocument = gql`
    mutation updateUnit($unit: UpdateUnitInput!) {
  updateUnit(payload: $unit) {
    _id
    name
    sellPrice
    variantCode
    available
    type
    floor
    variantCode
    model {
      _id
      name
      typology
      image {
        _id
        url
      }
      variants {
        code
        orientation
        terraceArea
        totalArea
        usefulArea
        gardenArea
      }
      bathrooms
      bedrooms
      hasGarden
      isStudio
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUnitGQL extends Apollo.Mutation<UpdateUnitMutation, UpdateUnitMutationVariables> {
    document = UpdateUnitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddViewerDocument = gql`
    mutation addViewer($payload: AddViewerInput!) {
  addViewer(payload: $payload) {
    _id
    name
    currentViewers {
      _id
      name
      roles
      company
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddViewerGQL extends Apollo.Mutation<AddViewerMutation, AddViewerMutationVariables> {
    document = AddViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveViewerDocument = gql`
    mutation removeViewer($payload: RemoveViewerInput!) {
  removeViewer(payload: $payload) {
    _id
    name
    currentViewers {
      _id
      name
      roles
      company
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveViewerGQL extends Apollo.Mutation<RemoveViewerMutation, RemoveViewerMutationVariables> {
    document = RemoveViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ViewerAddedDocument = gql`
    subscription viewerAdded($projectId: String!) {
  viewerAdded(projectId: $projectId) {
    _id
    name
    currentViewers {
      _id
      name
      roles
      company
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ViewerAddedGQL extends Apollo.Subscription<ViewerAddedSubscription, ViewerAddedSubscriptionVariables> {
    document = ViewerAddedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ViewerRemovedDocument = gql`
    subscription viewerRemoved($projectId: String!) {
  viewerRemoved(projectId: $projectId) {
    _id
    name
    currentViewers {
      _id
      name
      roles
      company
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ViewerRemovedGQL extends Apollo.Subscription<ViewerRemovedSubscription, ViewerRemovedSubscriptionVariables> {
    document = ViewerRemovedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRealEstatesDocument = gql`
    query getRealEstates {
  realEstates {
    _id
    name
    isFirstTime
    kind
    rut
    collaborators {
      _id
      name
      rut
      roles
      email
      phone
      position
      emailVerified
    }
    logo {
      _id
      url
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRealEstatesGQL extends Apollo.Query<GetRealEstatesQuery, GetRealEstatesQueryVariables> {
    document = GetRealEstatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRealEstateByIdDocument = gql`
    query getRealEstateById($id: String!) {
  realEstate(_id: $id) {
    _id
    name
    email
    phone
    address
    website
    primaryColor
    secondaryColor
    bank
    accountType
    accountNumber
    emailBank
    isFirstTime
    kind
    rut
    collaborators {
      _id
      name
      rut
      roles
      email
      phone
      position
      emailVerified
    }
    logo {
      _id
      url
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRealEstateByIdGQL extends Apollo.Query<GetRealEstateByIdQuery, GetRealEstateByIdQueryVariables> {
    document = GetRealEstateByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRealEstateStatsDocument = gql`
    query getRealEstateStats($_id: String!) {
  realEstateStats(_id: $_id) {
    company
    total
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRealEstateStatsGQL extends Apollo.Query<GetRealEstateStatsQuery, GetRealEstateStatsQueryVariables> {
    document = GetRealEstateStatsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateRealEstateDocument = gql`
    mutation createRealEstate($realEstate: CreateRealEstateInput!) {
  createRealEstateCompany(payload: $realEstate) {
    _id
    name
    email
    phone
    address
    website
    logo {
      _id
      url
    }
    primaryColor
    secondaryColor
    isFirstTime
    kind
    collaborators {
      _id
      name
      roles
      emailVerified
    }
    projects {
      _id
      name
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateRealEstateGQL extends Apollo.Mutation<CreateRealEstateMutation, CreateRealEstateMutationVariables> {
    document = CreateRealEstateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateRealEstateDocument = gql`
    mutation updateRealEstate($realEstate: UpdateRealEstateInput!) {
  updateRealEstateCompany(payload: $realEstate) {
    _id
    name
    email
    phone
    address
    website
    primaryColor
    secondaryColor
    bank
    accountType
    accountNumber
    emailBank
    isFirstTime
    kind
    logo {
      _id
      url
    }
    collaborators {
      _id
      name
      position
      emailVerified
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRealEstateGQL extends Apollo.Mutation<UpdateRealEstateMutation, UpdateRealEstateMutationVariables> {
    document = UpdateRealEstateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTimelineIdByUnitDocument = gql`
    query getTimelineIdByUnit($unitId: String!) {
  timelineByUnit(unitId: $unitId) {
    _id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTimelineIdByUnitGQL extends Apollo.Query<GetTimelineIdByUnitQuery, GetTimelineIdByUnitQueryVariables> {
    document = GetTimelineIdByUnitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSellerProgressProcessesDocument = gql`
    query getSellerProgressProcesses($sellerId: String!) {
  sellerProgressProcesses(sellerId: $sellerId) {
    timelineId
    projectName
    projectCompanyName
    projectCoverUrl
    units {
      unitName
      unitSellPrice
      unitModelImage
    }
    clientName
    clientEmail
    timelineStatus
    timelineStatusDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSellerProgressProcessesGQL extends Apollo.Query<GetSellerProgressProcessesQuery, GetSellerProgressProcessesQueryVariables> {
    document = GetSellerProgressProcessesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetScheduleEventsDocument = gql`
    query getScheduleEvents($filters: ListScheduleEventsInput!) {
  scheduleEvents(filters: $filters) {
    _id
    code
    kind
    description
    available
    status
    startsAt
    endsAt
    client {
      _id
      name
    }
    company {
      _id
      name
    }
    project {
      _id
      name
      code
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetScheduleEventsGQL extends Apollo.Query<GetScheduleEventsQuery, GetScheduleEventsQueryVariables> {
    document = GetScheduleEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetScheduleEventByCodeDocument = gql`
    query getScheduleEventByCode($code: String!) {
  scheduleEventByCode(code: $code) {
    _id
    code
    kind
    description
    available
    status
    startsAt
    endsAt
    client {
      _id
      name
    }
    company {
      _id
      name
    }
    project {
      _id
      name
      code
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetScheduleEventByCodeGQL extends Apollo.Query<GetScheduleEventByCodeQuery, GetScheduleEventByCodeQueryVariables> {
    document = GetScheduleEventByCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateScheduleEventDocument = gql`
    mutation createScheduleEvent($payload: CreateScheduleEventInput!) {
  createScheduleEvent(payload: $payload) {
    _id
    code
    kind
    description
    available
    status
    startsAt
    endsAt
    seller {
      _id
      name
    }
    company {
      _id
      name
    }
    client {
      _id
      name
    }
    project {
      _id
      code
      name
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateScheduleEventGQL extends Apollo.Mutation<CreateScheduleEventMutation, CreateScheduleEventMutationVariables> {
    document = CreateScheduleEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTimelineDocument = gql`
    query getTimeline($id: String!) {
  timeline(_id: $id) {
    _id
    isActive
    client {
      _id
      name
      email
      rut
      phone
      nationality
      address
      profession
      civilStatus
      interests {
        name
        location {
          type
          coordinates
        }
      }
      frontIdCard {
        _id
        url
      }
      backIdCard {
        _id
        url
      }
      socialSecurityQuotes {
        _id
        url
      }
      liquidations {
        _id
        url
      }
      purchaseUse
      kind
      liquidIncome
      currentAccount
      bank
      monthlySavingsCapacity
      outstandingDebts
      comments
      validToBuy
      createdAt
      updatedAt
      isDeleted
    }
    suggestedProjects {
      _id
      name
      stage
      address
      location {
        type
        coordinates
      }
      amenities {
        name
        icon
      }
      releaseDate
      builderCompany
      unitsNumber
      floorsNumber
      virtualTour
      video
      brochure {
        _id
        url
      }
      cover {
        _id
        url
      }
      media {
        _id
        url
      }
      company {
        _id
        name
        logo {
          url
        }
      }
      models {
        _id
        name
        typology
        variants {
          code
          orientation
          terraceArea
          totalArea
          usefulArea
          gardenArea
        }
        bathrooms
        bedrooms
        isStudio
        hasGarden
      }
      stats {
        _id
        minLP
        maxLP
        minSP
        maxSP
        minBP
        maxBP
        available
        reserved
        total
      }
    }
    project {
      _id
      name
      stage
      address
      location {
        type
        coordinates
      }
      amenities {
        name
        icon
      }
      documentation {
        reservationTemplate
        promiseTemplate
        deedTemplate
        offerTemplate
      }
      releaseDate
      builderCompany
      unitsNumber
      floorsNumber
      virtualTour
      video
      brochure {
        _id
        url
      }
      cover {
        _id
        url
      }
      media {
        _id
        url
      }
      company {
        _id
        name
        logo {
          url
        }
      }
      models {
        _id
        name
        typology
        variants {
          code
          orientation
          terraceArea
          totalArea
          usefulArea
          gardenArea
        }
        bathrooms
        bedrooms
        isStudio
        hasGarden
        image {
          url
        }
      }
    }
    company {
      _id
      name
    }
    seller {
      _id
      name
    }
    history {
      what
      when
      extra
    }
    payment {
      _id
      buyOrder
      sessionId
      amount
      status
      storeCode
      token
      start
      end
      items {
        units
        paymentBonusTerm {
          percent
        }
        paymentPieTerm {
          title
          dues
          endDate
          percent
          bigDue
          terms
        }
        reservationAmount
        dayUF
      }
      transaction
    }
    status
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTimelineGQL extends Apollo.Query<GetTimelineQuery, GetTimelineQueryVariables> {
    document = GetTimelineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTimelineByClientDocument = gql`
    query getTimelineByClient($clientId: String!) {
  timelineByClient(clientId: $clientId) {
    _id
    isActive
    client {
      _id
      name
      email
      rut
      phone
      nationality
      address
      profession
      civilStatus
      interests {
        name
        location {
          type
          coordinates
        }
      }
      frontIdCard {
        _id
        url
      }
      backIdCard {
        _id
        url
      }
      socialSecurityQuotes {
        _id
        url
      }
      liquidations {
        _id
        url
      }
      purchaseUse
      kind
      liquidIncome
      currentAccount
      bank
      monthlySavingsCapacity
      outstandingDebts
      comments
      validToBuy
      createdAt
      updatedAt
      isDeleted
    }
    suggestedProjects {
      _id
      name
      stage
      address
      models {
        _id
        name
        typology
        variants {
          code
          orientation
          terraceArea
          totalArea
          usefulArea
          gardenArea
        }
        bathrooms
        bedrooms
        isStudio
        hasGarden
        image {
          url
        }
      }
      location {
        type
        coordinates
      }
      amenities {
        name
        icon
      }
      releaseDate
      builderCompany
      unitsNumber
      floorsNumber
      virtualTour
      video
      brochure {
        _id
        url
      }
      cover {
        _id
        url
      }
      media {
        _id
        url
      }
      company {
        _id
        name
        logo {
          url
        }
      }
      stats {
        _id
        minLP
        maxLP
        minSP
        maxSP
        minBP
        maxBP
        available
        reserved
        total
      }
    }
    project {
      _id
      name
      stage
      address
      location {
        type
        coordinates
      }
      amenities {
        name
        icon
      }
      releaseDate
      builderCompany
      unitsNumber
      floorsNumber
      virtualTour
      video
      brochure {
        _id
        url
      }
      cover {
        _id
        url
      }
      media {
        _id
        url
      }
      company {
        _id
        name
        logo {
          url
        }
      }
      models {
        _id
        name
        typology
        variants {
          code
          orientation
          terraceArea
          totalArea
          usefulArea
          gardenArea
        }
        bathrooms
        bedrooms
        isStudio
        hasGarden
        image {
          url
        }
      }
    }
    units {
      _id
      name
      sellPrice
      available
      type
      floor
      variantCode
      model {
        _id
        name
        typology
        image {
          url
        }
        variants {
          code
          orientation
          terraceArea
          totalArea
          usefulArea
          gardenArea
        }
        bathrooms
        bedrooms
        hasGarden
        isStudio
      }
    }
    company {
      _id
      name
    }
    seller {
      _id
      name
    }
    history {
      what
      when
      extra
    }
    payment {
      _id
      buyOrder
      sessionId
      amount
      status
      storeCode
      token
      start
      end
      items {
        units
        paymentBonusTerm {
          percent
        }
        paymentPieTerm {
          title
          dues
          endDate
          percent
          bigDue
          terms
        }
        reservationAmount
        dayUF
      }
      transaction
      createdAt
      updatedAt
      isDeferred
      isDeleted
    }
    status
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTimelineByClientGQL extends Apollo.Query<GetTimelineByClientQuery, GetTimelineByClientQueryVariables> {
    document = GetTimelineByClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTimelinesBySellerDocument = gql`
    query getTimelinesBySeller($sellerId: String!) {
  timelinesBySeller(sellerId: $sellerId) {
    funnel
    count
    timelines {
      _id
      client {
        name
        email
        validToBuy
        kind
        phone
      }
      isDeleted
      status
      isActive
      company
      seller
      project
      createdAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTimelinesBySellerGQL extends Apollo.Query<GetTimelinesBySellerQuery, GetTimelinesBySellerQueryVariables> {
    document = GetTimelinesBySellerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBrokerFunnelDocument = gql`
    query getBrokerFunnel($brokerId: String!) {
  brokerFunnel(brokerId: $brokerId) {
    subLabels
    values
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBrokerFunnelGQL extends Apollo.Query<GetBrokerFunnelQuery, GetBrokerFunnelQueryVariables> {
    document = GetBrokerFunnelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBrokerProjectSalesDocument = gql`
    query getBrokerProjectSales($brokerId: String!) {
  brokerProjectSales(brokerId: $brokerId) {
    projectId
    projectName
    projectCompanyName
    projectCoverUrl
    totalBrokerSales
    unsoldProjectUnits
    totalProjectSales
    totalProjectUnits
    sellers {
      name
      avatar
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBrokerProjectSalesGQL extends Apollo.Query<GetBrokerProjectSalesQuery, GetBrokerProjectSalesQueryVariables> {
    document = GetBrokerProjectSalesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddSuggestedProjectDocument = gql`
    mutation addSuggestedProject($payload: AddSuggestedProjectInput!) {
  addSuggestedProject(payload: $payload) {
    _id
    suggestedProjects {
      _id
      name
      stage
      address
      features {
        bathrooms {
          min
          max
        }
        bedrooms {
          min
          max
        }
        usefulSurface {
          min
          max
        }
        totalSurface {
          min
          max
        }
      }
      releaseDate
      company {
        _id
        name
      }
      stats {
        _id
        minLP
        maxLP
        minSP
        maxSP
        minBP
        maxBP
        available
        reserved
        total
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddSuggestedProjectGQL extends Apollo.Mutation<AddSuggestedProjectMutation, AddSuggestedProjectMutationVariables> {
    document = AddSuggestedProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveSuggestedProjectDocument = gql`
    mutation removeSuggestedProject($payload: RemoveSuggestedProjectInput!) {
  removeSuggestedProject(payload: $payload) {
    _id
    suggestedProjects {
      _id
      name
      stage
      address
      features {
        bathrooms {
          min
          max
        }
        bedrooms {
          min
          max
        }
        usefulSurface {
          min
          max
        }
        totalSurface {
          min
          max
        }
      }
      releaseDate
      company {
        _id
        name
      }
      stats {
        _id
        minLP
        maxLP
        minSP
        maxSP
        minBP
        maxBP
        available
        reserved
        total
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveSuggestedProjectGQL extends Apollo.Mutation<RemoveSuggestedProjectMutation, RemoveSuggestedProjectMutationVariables> {
    document = RemoveSuggestedProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTimelineDocument = gql`
    mutation createTimeline($payload: CreateTimelineInput!) {
  createTimeline(payload: $payload) {
    _id
    isActive
    client {
      _id
      name
    }
    company {
      _id
      name
    }
    seller {
      _id
      name
    }
    history {
      what
      when
      extra
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTimelineGQL extends Apollo.Mutation<CreateTimelineMutation, CreateTimelineMutationVariables> {
    document = CreateTimelineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddTimelineHistoryDocument = gql`
    mutation addTimelineHistory($payload: AddHistoryInput!) {
  addTimelineHistory(payload: $payload) {
    _id
    isActive
    history {
      what
      when
      extra
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTimelineHistoryGQL extends Apollo.Mutation<AddTimelineHistoryMutation, AddTimelineHistoryMutationVariables> {
    document = AddTimelineHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }